import React, {
  useRef,
  useEffect,
  useMemo,
  useContext,
  createContext,
  useState,
} from "react";
import { useQueries } from "react-query";
import { cdelay, dec, getv, iso_format, jstr, nils } from "../utils/utils";
import { useAppContext } from "../App";
import {
  TP_Nav,
  TP_TokenTag,
  TrailsPointsWrapper,
  useTrailsPointsContext,
} from "./TrailsCards";
import { PageWrapper } from "../components/ShortComps";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { Card, Img, Tag, TokenIcon } from "../components/utilityComps";
import {
  polytxnidlink,
  polytxnlink,
  q_trails_cards_points_buy_asset,
  q_trails_cards_points_ledger,
  q_trails_cards_points_storelistings,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { prizebox_img } from "../utils/links";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { RVImg } from "../components/BikeImg";

export const TrailsPointsLedgerContext = createContext({});
export const useTrailsPointsLedgerContext = () =>
  useContext(TrailsPointsLedgerContext);

const LedgerRow = ({ idx, row: l }) => {
  const { items_ob = {} } = useTrailsPointsLedgerContext();
  const extinfo_jsx = useMemo(() => {
    if (l.type == "debit" && l.for == "purchase") {
      let { itemid, qty, minted, mintmap } = l.ext;
      let item = items_ob[itemid] ?? null;
      if (nils(item)) return null;

      let { item_type, title } = item;
      if (item_type == "prizebox") {
        return (
          <div class="fr-sc w-full resp-gap-1 resp-text--1">
            <div class="flex-1">
              <p className="resp-text-0">
                Bought {qty} x {item.title}
              </p>
            </div>
            <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
              {minted == true ? (
                <a
                  target="_blank"
                  href={polytxnidlink(getv(mintmap, "1.hash"))}
                >
                  <TokenIcon token={"MATIC"} size="s" />
                </a>
              ) : (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spin-anim text-acc0 resp-text-1"
                />
              )}
            </div>

            <a target="_blank" href={`https://dnaracing.run/prizebox`}>
              <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
                <Img img={prizebox_img} />
              </div>
            </a>

            {/*
              <span>Minted at {iso_format(getv(mintmap, "1.minted_at"))}</span> */}
          </div>
        );
      } else if (item_type == "skin") {
        let txns = _.chain(getv(l, "ext.mintmap"))
          .values()
          .map("hash")
          .flatten()
          .value();
        let skinids = _.chain(getv(l, "ext.mintmap"))
          .values()
          .map("skinid")
          .flatten()
          .value();
        const rvmode = getv(item, "extinfo.rvmode");

        return (
          <div class="fr-sc w-full resp-gap-1 resp-text--1">
            <div class="flex-1">
              <div class="fc-ss w-full">
                <p className="resp-text-0">
                  Bought {qty} x {item.title}
                </p>

                <p className="resp-text--1 text-acc0">
                  [skinid: {skinids.join(", ")}]
                </p>
              </div>
            </div>
            <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
              {minted !== true && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spin-anim text-acc0 resp-text-1"
                />
              )}
              {txns.map((txn) => {
                return (
                  <a key={txn} target="_blank" href={polytxnidlink(txn)}>
                    <TokenIcon token={"MATIC"} size="s" />
                  </a>
                );
              })}
            </div>

            <Link target="_self" to={`/vault/${l.vault}?tab=skins`}>
              <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
                <RVImg rvmode={rvmode} />
              </div>
            </Link>

            {/*
              <span>Minted at {iso_format(getv(mintmap, "1.minted_at"))}</span> */}
          </div>
        );
      } else if (item_type == "tpgiftcard") {
        let id = getv(item, "extinfo.id");
        return (
          <div class="fr-sc w-full resp-gap-1 resp-text--1">
            <div class="flex-1">
              <p className="resp-text-0">
                Bought {qty} x {item.title}
              </p>
            </div>
            <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
              {minted == true ? (
                <a
                  target="_blank"
                  href={polytxnidlink(getv(mintmap, "1.hash"))}
                >
                  <TokenIcon token={"MATIC"} size="s" />
                </a>
              ) : (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spin-anim text-acc0 resp-text-1"
                />
              )}
            </div>

            <a target="_blank" href={`https://dnaracing.run/tp_giftcard`}>
              <div class="xs:w-[2rem] lg:w-[3rem] fc-cc aspect-[1/1]">
                <Img
                  img={`https://cdn.dnaracing.run/imgs/tp_giftcard_${id}.png`}
                />
              </div>
            </a>

            {/* <span>Minted at {iso_format(getv(mintmap, "1.minted_at"))}</span> */}
          </div>
        );
      }
    }
    return null;
  }, [jstr(l), jstr(items_ob)]);

  return (
    <tr className="">
      <td>
        <div class="fc-ss resp-gap-1 resp-text-0">
          {nils(extinfo_jsx) && <p>{l.msg}</p>}
          {extinfo_jsx}
        </div>
      </td>

      <td>
        <span
          class={twMerge(
            "resp-text-1",
            l.type == "credit" ? "text-green-300" : "text-white",
          )}
        >
          <span>{l.type == "credit" ? "+" : "-"}</span>
          {l.amt}
        </span>
      </td>

      <td>
        <span
          class={twMerge(
            "resp-text-1",
            l.type == "credit" ? "text-green-300" : "text-white",
          )}
        >
          {l.type}
        </span>
      </td>

      <td>
        <span>{iso_format(l.date)}</span>
      </td>
    </tr>
  );
};

export const TrailsPointsLedgerPage = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const { psearch, upd_psearch } = appcon;

  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (!nils(v)) return v;
    return aucon.vault;
  }, [psearch.v, aucon.vault]);

  return (
    <TrailsPointsWrapper {...{ vault }}>
      <TrailsPointsLedgerPageInner {...{ vault }} />;
    </TrailsPointsWrapper>
  );
};

const TrailsPointsLedgerPageInner = ({ vault }) => {
  const tpcon = useTrailsPointsContext();

  const [page, set_page] = useState(0);
  const [limit, set_limit] = useState(20);

  const [qo_ledger, qo_items] = useQueries([
    q_trails_cards_points_ledger(
      { vault, page, limit },
      { enabled: !nils(vault) },
    ),
    q_trails_cards_points_storelistings({}),
  ]);
  const ledger = useMemo(() => {
    let ledger = getv(qo_ledger, "data.result.leds", []);
    return ledger;
  });
  const [items, items_ob] = useMemo(() => {
    let items = getv(qo_items, "data.result", []);
    let items_ob = _.keyBy(items, "id");
    return [items, items_ob];
  }, [jstr(qo_items)]);

  const tcon = {
    ledger,
    items,
    items_ob,
    ...tpcon,
  };

  return (
    <PageWrapper
      cont_cn={"lg:w-[80rem]"}
      page_title_fn={() => "Trails Points Ledger"}
    >
      <TrailsPointsLedgerContext.Provider value={tcon}>
        <TP_Nav />
        <div class="w-full">
          <Card className={"w-full"}>
            <table
              className={twMerge(tablecn.table, "thintdrowp2-table-r2lig")}
            >
              <thead>
                <tr>
                  <td>Message</td>
                  <td>Amt</td>
                  <td>Type</td>
                  <td>Date</td>
                </tr>
              </thead>
              <tbody>
                {ledger.map((l, i) => {
                  return <LedgerRow idx={i} row={l} />;
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </TrailsPointsLedgerContext.Provider>
    </PageWrapper>
  );
};

export const TrailsPointsStoreContext = createContext({});
export const useTrailsPointsStoreContext = () =>
  useContext(TrailsPointsStoreContext);

export const TrailsPointsStorePage = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const { psearch, upd_psearch } = appcon;

  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (!nils(v)) return v;
    return aucon.vault;
  }, [psearch.v, aucon.vault]);

  return (
    <TrailsPointsWrapper {...{ vault }}>
      <TrailsPointsStorePageInner {...{ vault }} />;
    </TrailsPointsWrapper>
  );
};

const TrailsPointsStorePageInner = ({ vault }) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const tpcon = useTrailsPointsContext();

  const [page, set_page] = useState(0);
  const [limit, set_limit] = useState(20);
  const [sel_itemid, set_sel_itemid] = useState(
    !nils(psearch?.itemid) ? psearch.itemid : null,
  );

  const [qo_items] = useQueries([q_trails_cards_points_storelistings({})]);
  const [items, items_ob, show_items] = useMemo(() => {
    let items = getv(qo_items, "data.result", []);
    let items_ob = _.keyBy(items, "id");
    let show_items = items.filter((e) => e.active == true);
    return [items, items_ob, show_items];
  }, [jstr(qo_items)]);

  const close_popup = () => {
    set_sel_itemid(null);
    upd_psearch({ itemid: null });
  };

  const tcon = {
    items,
    items_ob,
    ...tpcon,
    close_popup,
  };

  return (
    <PageWrapper
      cont_cn={"lg:w-[80rem]"}
      page_title_fn={() => "Trails Points Store"}
    >
      <TrailsPointsStoreContext.Provider value={tcon}>
        <TP_Nav />
        <div class="w-full">
          <p class="resp-text-2 font-digi text-white text-center ">
            Trail Points Store
          </p>
          <div class="h-[2rem]"></div>
          <PopUp
            openstate={!nils(sel_itemid)}
            onClose={close_popup}
            wrapcn={"xs:top-[1rem] lg:top-[5rem]"}
            innercn={"translate-y-[0%]"}
          >
            <PopupCloseBtn {...{ closepopup: close_popup }} />
            <Card
              className={twMerge(
                "xs:w-[95vw] lg:w-[60rem] bg-r2dark/40 backdrop-blur-md border border-acc4 p-4 overflow-auto h-[40rem]",
                "overflow-auto",
              )}
            >
              <ItemDetailsCard itemid={sel_itemid} />
            </Card>
          </PopUp>
          {_.isEmpty(show_items) && (
            <div class="resp-text-0 fc-cc resp-gap-2">
              <p class="resp-text-1">Nothing in Store currently</p>
              <p>Please visit later</p>
            </div>
          )}
          <div class="w-full grid gap-2 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {show_items.map((item) => {
              return (
                <Card
                  onClick={() => {
                    set_sel_itemid(item.id);
                    upd_psearch({ itemid: item.id });
                  }}
                  className={
                    "w-full h-full cursor-pointer fc-cc resp-gap-1 relative border border-acc4 bg-r2lig/20 rounded-lg"
                  }
                >
                  <div class="absolute top-[1rem] right-[1rem]">
                    <div class="bg-purple-500/40 fr-sc px-4 py-1 resp-gap-1 border border-purple-500 rounded-full">
                      <TokenIcon token={"tp"} size="xs" />
                      <span>{dec(item.cost, 0)}</span>
                    </div>
                  </div>
                  <div
                    onClick={() => {}}
                    class="w-full max-w-[15rem] mb-2 mx-auto aspect-[1/1] fc-cc "
                  >
                    {!nils(item.img) && (
                      <Img className={"w-full"} img={item.img} />
                    )}
                  </div>
                  <p className="resp-text-1 font-digi">{item.title}</p>
                  <p className="resp-text--1 font-digi">{item.desc}</p>
                </Card>
              );
            })}
          </div>
        </div>
      </TrailsPointsStoreContext.Provider>
    </PageWrapper>
  );
};

const ItemDetailsCard = ({ itemid }) => {
  const tpcon = useTrailsPointsContext();
  const { points, refresh_points } = tpcon;

  const tcon = useTrailsPointsStoreContext();
  const { items, items_ob, close_popup } = tcon;
  const item = useMemo(() => items_ob[itemid], [jstr(items_ob), itemid]);

  const [qty, set_qty] = useState(1);
  const totcost = parseFloat(item?.cost) * qty;

  const [resp, set_resp] = useState({});
  const validpurchase = useMemo(() => {
    if (totcost > points) {
      let o = {
        valid: false,
        msg: `Not Enough Points!!, TP Balance: ${points}`,
      };
      set_resp({
        loading: false,
        type: "info",
        msg: o.msg,
        for: "validpurchase",
      });
      return o;
    } else {
      if (resp?.for == "validpurchase") {
        set_resp({});
      }
    }
    return null;
  }, [totcost, points, jstr(item)]);

  const buy_item = async () => {
    try {
      set_resp({ loading: true, type: "loading", msg: "" });

      let resp = await q_trails_cards_points_buy_asset({
        itemid,
        qty,
      }).queryFn();
      await cdelay(1 * 1e3);
      if (!nils(resp.err)) throw new Error(resp.err);

      if (getv(resp, "result.done") == 1) {
        set_resp({
          loading: false,
          type: "success",
          msg: "Item Should be minted soon",
        });
      }

      await cdelay(1 * 1e3);

      setTimeout(() => {
        set_resp({});
        close_popup();
        tpcon.refresh_points();
      }, 2 * 1e3);
    } catch (err) {
      console.log(err);
      const errmsg =
        err.message.length > 100
          ? err.message.slice(0, 100) + "..."
          : err.message;
      set_resp({ loading: false, type: "err", msg: errmsg });
      setTimeout(() => {
        set_resp({});
      }, 2 * 1e3);
    }
  };

  if (nils(item)) return null;
  return (
    <>
      <div class="relative w-full h-full">
        <div class="grid grid-cols-5 gap-2">
          <div class="col-span-2">
            <Img img={item.img} />
          </div>
          <div class="col-span-3 fc-ss resp-gap-2 resp-p-2">
            <div class="h-[3rem]"></div>

            <div class="absolute top-[1rem] right-[1rem]">
              <div class="bg-purple-500/40 fr-sc px-4 py-1 resp-gap-1 border border-purple-500 rounded-full">
                <TokenIcon token={"tp"} size="xs" />
                <span>{dec(item.cost, 0)}</span>
              </div>
            </div>
            <p class="resp-text-2 font-digi">{item.title}</p>
            <p class="resp-text-0 font-digi">{item.desc}</p>
            <div class="flex flex-row justify-stretch px-2 h-full select-none items-center resp-gap-1 w-full ">
              <div class="flex-1"></div>
              <div class="border border-r2lig rounded-md resp-text-1 flex flex-row justify-center items-stretch">
                <FontAwesomeIcon
                  onClick={() => {
                    set_qty(Math.max(1, qty - 1));
                  }}
                  icon={faMinus}
                  className="p-1 cursor-pointer"
                />
                <span class="px-2 min-w-[3rem] text-center">{qty}</span>
                <FontAwesomeIcon
                  onClick={() => {
                    set_qty(qty + 1);
                  }}
                  icon={faPlus}
                  className="p-1 cursor-pointer"
                />
              </div>

              {resp.loading == true ? (
                <Tag
                  onClick={() => {}}
                  className="cursor-pointer border border-r2lig text-acc4 font-digi"
                >
                  <div class="fr-sc resp-gap-1 resp-text--1 font-digi">
                    <FontAwesomeIcon icon={faSpinner} className="spin-anim" />
                    <span>Buying...</span>
                  </div>
                </Tag>
              ) : (
                <Tag
                  onClick={() => {
                    if (getv(validpurchase, "valid") === false) return;
                    buy_item();
                  }}
                  className={twMerge(
                    "cursor-pointer border border-r2lig hover:bg-r2lig text-white font-digi",
                    getv(validpurchase, "valid") === false
                      ? "bg-slate-600 hover:bg-slate-500 border-none"
                      : "",
                  )}
                >
                  <div class="fr-sc resp-gap-1 resp-text--1 font-digi">
                    <span>{"Buy for "} </span>
                    <span>{totcost} TP</span>
                  </div>
                </Tag>
              )}
            </div>
            <div class="min-h-[4rem] w-full">
              {!nils(resp) && !nils(resp.msg) && (
                <p
                  class={twMerge(
                    "resp-px-2 resp-py-1 border font-digi rounded-md w-full",
                    resp.type == "err"
                      ? "text-red-400 border-red-400"
                      : resp.type == "info"
                        ? "text-yellow-400 border-yellow-400"
                        : resp.type == "success"
                          ? "text-green-400 border-green-400"
                          : "",
                  )}
                >
                  {resp.msg}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
