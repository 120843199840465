import React, { useMemo } from "react";
import { useAppContext } from "../App.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useQueries } from "react-query";
import {
  q_vault_trophies,
  q_vaultinfo,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { getv, iso_format, nils } from "../utils/utils.js";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { Loader01c } from "../components/anims.js";
import _ from "lodash";
import { Card, Img } from "../components/utilityComps.js";

const Timgmap = {
  minorpot: "https://i.imgur.com/vzJVPQf.png",
  majorpot: "https://i.imgur.com/vzJVPQf.png",
  maxpot: "https://i.imgur.com/5uaH0sv.png",
  vault_wins_100: "https://i.imgur.com/Cb4R2j8.png",
  vault_wins_200: "https://i.imgur.com/FhWTgmn.png",
  vault_wins_300: "https://i.imgur.com/FgHbshx.png",
  vault_wins_500: "https://i.imgur.com/7ap7Ob8.png",
  vault_wins_1000: "https://i.imgur.com/sB9kAh1.png",
  vault_wins_2500: "https://i.imgur.com/DTsD8cA.png",
  vault_wins_5000: "https://i.imgur.com/JEVtett.png",
  "vault_profit_0.10": "https://i.imgur.com/7xPNQvV.png",
  "vault_profit_0.25": "https://i.imgur.com/OgN6BvN.png",
  "vault_profit_0.50": "https://i.imgur.com/lyPkP8x.png",
  "vault_profit_1.00": "https://i.imgur.com/EjFdGeI.png",
  "vault_profit_2.00": "https://i.imgur.com/17cqUTH.png",
};

const TrophyCard = ({ t }) => {
  return (
    <Card className="bg-r2lig/20 backdrop-blur-md border border-acc4 shadow-sm shadow-acc4/40 w-full">
      <div className="mx-auto xs:w-[6rem] lg:w-[15rem]">
        <Img img={!nils(t.img) ? t.img : Timgmap[t.type]} className="w-full" />
      </div>
      <p className="my-2 font-digi -skew-x-12 resp-text--1">{t.title}</p>
      {!nils(t.date) && (
        <p className="my-2 text-white resp-text--2">{iso_format(t.date)}</p>
      )}
    </Card>
  );
};

export const TrophiesTab = ({ vault }) => {
  const [qo_vaulttrophies] = useQueries([
    q_vault_trophies({ vault }, { enabled: !nils(vault) }),
  ]);
  const trophies = useMemo(() => {
    let trophies = getv(qo_vaulttrophies, "data.result") || [];
    return trophies;
  });

  return (
    <>
      {q_vault_trophies.isLoading ? (
        <Loader01c />
      ) : qiserr(qo_vaulttrophies) ? (
        <p className="text-center text-red-300">{qiserr(qo_vaulttrophies)}</p>
      ) : qissuccesss(qo_vaulttrophies) && _.isEmpty(trophies) ? (
        <p className="text-center text-yellow-300">
          No Trophies Yet!... keep racing...
        </p>
      ) : qissuccesss(qo_vaulttrophies) && !_.isEmpty(trophies) ? (
        <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 resp-gap-4">
          {trophies.map((t, i) => {
            return <TrophyCard key={i} t={t} />;
          })}
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

function TrophiesPage() {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const params = useParams();
  const vault = useMemo(() => {
    let v = params.vault;
    if (!nils(v)) v = v.toLowerCase();
    return v;
  }, [params.vault]);
  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const pagetitle = useMemo(() => {
    if (nils(vdoc)) return `Trophies ${vault} | FBike DNA`;
    let rn = vdoc.vault_name;
    return `'${rn}' Trophies | FBike DNA`;
  }, [vdoc]);

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[2rem]"></div>
          {qissuccesss(qo_vaultinfo) && (
            <>
              <p className="text-acc0 text-center font-digi resp-text-1">
                {vdoc.vault_name}'s Trophies
              </p>
            </>
          )}
          <div className="h-[2rem]"></div>
          <TrophiesTab vault={vault} />
        </div>
      </div>
    </>
  );
}

export default TrophiesPage;
