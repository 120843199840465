import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Center, Html, Text, useAnimations, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";
import { forwardRef } from "react";
import { useControls } from "leva";
import _ from "lodash";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import { Trail } from "./Trails.js";
import { CarTrail } from "./trails/CarTrail";
import useGLTF_cloned from "./useGLTF_cloned.js";
import { useModelsStore } from "./ModelsStore.js";
import { getv, iso, jstr, nils } from "../utils/utils.js";

const CarBase = ({ nodes, materials }) => {
  return (
    <>
      <group dispose={null}>
        <group name="Scene">
          <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} />
          <group
            name="Armature019"
            position={[0.742, 0.17, -0.396]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <mesh
              name="TireF02001"
              castShadow
              receiveShadow
              geometry={nodes.TireF02001.geometry}
              material={materials["Chabik.001"]}
              position={[0.002, 0.006, 0.004]}
              rotation={[-Math.PI, 0, 0]}
            />
            <mesh
              name="TireF02002"
              castShadow
              receiveShadow
              geometry={nodes.TireF02002.geometry}
              material={materials["Rubber.001"]}
              position={[0.002, 0.006, 0.004]}
              rotation={[-Math.PI, 0, 0]}
            />
            <primitive object={nodes.Bone} />
          </group>
          <group
            name="Armature020"
            position={[0.747, 0.17, 0.464]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <mesh
              name="TireF01001"
              castShadow
              receiveShadow
              geometry={nodes.TireF01001.geometry}
              material={materials["Rubber.001"]}
              position={[0.001, 0.004, -0.004]}
            />
            <mesh
              name="TireF01002"
              castShadow
              receiveShadow
              geometry={nodes.TireF01002.geometry}
              material={materials["Chabik.001"]}
              position={[0.001, 0.004, -0.004]}
            />
            <primitive object={nodes.Bone_1} />
          </group>
          <group
            name="Armature021"
            position={[-0.639, 0.213, 0.523]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <mesh
              name="TireB01001"
              castShadow
              receiveShadow
              geometry={nodes.TireB01001.geometry}
              material={materials["Rubber.001"]}
              position={[0, 0.007, -0.005]}
            />
            <mesh
              name="TireB01002"
              castShadow
              receiveShadow
              geometry={nodes.TireB01002.geometry}
              material={materials["Chabik.001"]}
              position={[0, 0.007, -0.005]}
            />
            <primitive object={nodes.Bone_2} />
          </group>
          <group
            name="Armature022"
            position={[-0.644, 0.213, -0.444]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <mesh
              name="TireB02001"
              castShadow
              receiveShadow
              geometry={nodes.TireB02001.geometry}
              material={materials["Rubber.001"]}
              position={[0, 0.007, 0.005]}
              rotation={[-Math.PI, 0, 0]}
            />
            <mesh
              name="TireB02002"
              castShadow
              receiveShadow
              geometry={nodes.TireB02002.geometry}
              material={materials["Chabik.001"]}
              position={[0, 0.007, 0.005]}
              rotation={[-Math.PI, 0, 0]}
            />
            <primitive object={nodes.Bone_3} />
          </group>
          <group
            name="Armature023"
            position={[-0.215, 0.456, 0]}
            rotation={[-0.004, -0.013, -0.005]}
          >
            <group
              name="Armature013"
              position={[-1.014, -0.458, 0.031]}
              rotation={[1.572, 0.001, -0.03]}
            />
            <group
              name="Armature014"
              position={[0.945, -0.285, -0.425]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <group
              name="Armature015"
              position={[0.976, -0.286, 0.434]}
              rotation={[-3.141, -0.03, -0.001]}
            />
            <group
              name="Armature016"
              position={[-0.407, -0.245, 0.535]}
              rotation={[-3.141, -0.03, -0.001]}
            />
            <group
              name="Armature017"
              position={[-0.442, -0.244, -0.431]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <group name="Armature018" rotation={[1.567, -0.003, -0.018]} />
            <mesh
              name="BodyCar_low_Body_0001"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0001.geometry}
              material={materials["Body.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0002"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0002.geometry}
              material={materials["MetalicOldRusty.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0003"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0003.geometry}
              material={materials["Metalics02.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0004"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0004.geometry}
              material={materials["Heidlight.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0005"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0005.geometry}
              material={materials["First.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0006"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0006.geometry}
              material={materials["Rubber.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0007"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0007.geometry}
              material={materials["inside.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0008"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0008.geometry}
              material={materials["Chabik.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0009"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0009.geometry}
              material={materials["Suspension.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0010"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0010.geometry}
              material={materials["Tiyo.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0011"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0011.geometry}
              material={materials["OldIron.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <mesh
              name="BodyCar_low_Body_0012"
              castShadow
              receiveShadow
              geometry={nodes.BodyCar_low_Body_0012.geometry}
              material={materials["Ba9i.001"]}
              rotation={[1.567, -0.003, -0.018]}
            />
            <group
              name="Bone_4"
              position={[-1.014, -0.458, 0.031]}
              rotation={[-3.1, 1.54, -1.612]}
            />
            <group
              name="Bone001"
              position={[0.945, -0.285, -0.425]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <group
              name="Bone002"
              position={[0.976, -0.286, 0.434]}
              rotation={[-3.141, -0.03, -0.001]}
            />
            <group
              name="Bone003"
              position={[-0.407, -0.245, 0.535]}
              rotation={[-3.141, -0.03, -0.001]}
            />
            <group
              name="Bone004"
              position={[-0.442, -0.244, -0.431]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <group name="Bone005" rotation={[1.567, -0.003, -0.018]} />
            <group
              name="Object_2"
              position={[0.216, -0.456, -0.007]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <group
              name="Object_31"
              position={[0.216, -0.456, -0.007]}
              rotation={[-3.141, -0.03, -0.001]}
            />
            <group
              name="Road"
              position={[0.216, -0.456, -0.007]}
              rotation={[-1.57, -0.001, 0.03]}
            />
            <group
              name="RootNode"
              position={[0.216, -0.456, -0.007]}
              rotation={[0.001, 0.03, 0.001]}
            />
            <primitive object={nodes.Bone_5} />
          </group>
        </group>
      </group>
    </>
  );
};

const gets_skin_textures_baseurl = (skin) => {
  const baseURL =
    "https://cdn.dnaracing.run/car-skins/";
  // const baseURL = "/skins/";
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}/textures`;
  return newImageURL;
};

const textypes = {
  b: "BaseColor",
  d: "Displacement",
  e: "Emission",
  m: "Metallic",
  n: "Normal",
  r: "Roughness",
};
const textypes_to_matprop = {
  b: "map",
  d: "displacementMap",
  e: "emissiveMap",
  m: "metalnessMap",
  n: "normalMap",
  r: "roughnessMap",
};
const useTextureForSkinMaterials = (skin, texmats, basetexpath) => {
  const textureLoader = new THREE.TextureLoader();
  const resp = useMemo(() => {
    if (skin == "base")
      return {
        loaded: `${skin}-${iso()}`,
        textures: {},
      };
    let textures = {};
    for (let [matkey, matlink, textypekeys] of texmats) {
      textures[matkey] = {};
      textypekeys = textypekeys.split(",");
      for (let k of textypekeys) {
        let texkey = textypes[k];
        let texpath = `${basetexpath}/${matlink}_${texkey}.png`;
        let tex = textureLoader.load(texpath);
        // console.log(">", matkey, texkey, k);
        tex.flipY = false;
        // tex.encoding = THREE.sRGBEncoding;
        tex.colorSpace = THREE.SRGBColorSpace;
        textures[matkey][texkey] = tex;
      }
    }
    return {
      loaded: `${skin}-${iso()}`,
      textures,
    };
  }, [skin, jstr(texmats)]);

  useEffect(() => {
    // Cleanup function to dispose of textures
    /*
    return () => {
      for (const matkey in resp.textures) {
        for (const texkey in resp.textures[matkey]) {
          let tex = resp.textures[matkey][texkey];
          if (tex) tex.dispose();
        }
      }
    };
    */
  }, [resp]);

  return resp;
};

const useSkinMaterials = ({ minit, skin, neon, skinct }) => {
  const basetexpath = useMemo(() => gets_skin_textures_baseurl(skin), [skin]);

  // const basetexpath = useMemo(() => `/models/skinned/${skin}`, [skin]);

  const texmats = [
    ["inside.001", "inside", "b,m,n,r,e", {}],
    ["Tiyo.001", "Tiyo", "b,m,n,r,e", {}],
    ["Suspension.001", "Suspension", "b,m,n,r,e", {}],
    ["Rubber.001", "Rubber", "b,m,n,r,e", {}],
    ["OldIron.001", "OldIron", "b,m,n,r,e", {}],
    ["Metalics02.001", "Metalics02", "b,m,n,r,e", {}],
    ["MetalicOldRusty.001", "MetalicOldRusty", "b,m,n,r,e", {}],
    [
      "Heidlight.001",
      "Heidlight",
      "b,m,n,r,e",
      {
        emissive: new THREE.Color("#ffffff"),
        emissivIntesity: 60,
      },
    ],
    ["First.001", "First", "b,m,n,r,e", {}],
    [
      "Chabik.001",
      "Chabik",
      "b,m,n,r,e",
      {
        transparent: true,
      },
    ],
    ["Body.001", "Body", "b,m,n,r,e", {}],
    ["Bagi.001", "Bagi", "b,m,n,r,e", {}],
  ];
  const texob = useTextureForSkinMaterials(skin, texmats, basetexpath);

  const neon_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m.color = new THREE.Color(neon);
    m.emissive = new THREE.Color(neon);
    m.emissiveIntensity = 0.5;
    return m;
  }, [neon, skin]);

  const white_glow_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m.color = new THREE.Color("#ffffff");
    m.emissive = new THREE.Color("#ffffff");
    m.emissiveIntensity = 10;
    return m;
  }, [neon, skin]);

  const m2 = useMemo(() => {
    if (_.isEmpty(minit)) return {};
    if (skin == "base") return minit;

    const textures = texob.textures;
    // console.log("> textures", textures);
    const mskin = {};

    matcycle: for (let [matkey, matmodkey, textypekeys, extrapars] of texmats) {
      let m = new THREE.MeshStandardMaterial();

      if (skin !== "base") {
        if (_.isEmpty(textures[matkey]) || _.isEmpty(minit[matkey])) {
          mskin[matmodkey] = m;
          // m.needsUpdate = true;
          continue;
        }

        textypekeys = textypekeys.split(",") || [];
        teximagescycle: for (let k of textypekeys) {
          let texkey = textypes[k];
          let tex = textures[matkey][texkey];
          let matprop = textypes_to_matprop[k];
          if (tex) m[matprop] = tex;
          if (skinct[matprop] === false) m[matprop] = null;
        }
      } else {
        if (!_.isEmpty(minit[matkey])) m = m.copy(minit[matkey]);
      }

      if (!_.isEmpty(extrapars) && m) {
        for (let [mk, mv] of _.entries(extrapars)) {
          m[mk] = _.cloneDeep(mv);
        }
      }
      m.needsUpdate = true;
      mskin[matkey] = m;
    }
    let m2 = { ...minit, ...mskin };
    // console.log(">", m2);
    return m2;
  }, [texob.loaded, neon, jstr(skinct), _.keys(minit).join(",")]);

  return { ...m2, neon_mat, white_glow_mat };
};

export default forwardRef(function Car(
  {
    bike,
    curranim,
    modelcentered = false,
    overrideskin,
    overridetrail,
    ...props
  },
  ref,
) {
  const group = useRef();
  const models_store = useModelsStore();

  const model = models_store.get_model("car");
  const clonedmodel = useGLTF_cloned(model);
  const { nodes, materials: materials_init, animations = [] } = clonedmodel;

  // useEffect(() => { console.log("car:model", nodes, materials, animations); }, [clonedmodel]);

  const { hex_code, hid, name } = bike;
  const neon = useMemo(() => {
    return `#${hex_code}`;
  }, [hex_code]);

  const skinob = overrideskin || getv(bike, `skino.car`) || null;
  const skin = getv(skinob, "name") ?? "base";
  useEffect(() => {
    console.log("car:skin", hid, skin);
  }, [jstr(skin), hid]);

  const trail = overridetrail || getv(bike, `trail`) || null;
  const trailColor = nils(skinob) ? neon : getv(skinob, "acc");

  // const materials = materials_init;
  const materials = useSkinMaterials({
    minit: materials_init,
    skin,
    neon,
    skinct: {},
  });

  const prevanim = useRef(null);

  const anims = useAnimations(animations, group);
  const actions = anims.actions;
  const update_anim = (
    curr,
    crossfadetime = 0.5,
    crossfade = true,
    fromtime = 0,
  ) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      actions[curr].time = fromtime;
      if (crossfade && prev && actions[prev]) {
        actions[prev].crossFadeTo(actions[curr], crossfadetime);
        actions[curr].play();
      } else {
        action.play();
      }
      prevanim.current = curr;
    }
  };
  const update_anim2 = (curr, fromtime = 0) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      action.time = fromtime;
      action.play();
      prevanim.current = curr;
    }
  };
  useEffect(() => {
    if (group.current) {
      group.current.update_anim = update_anim;
      group.current.update_anim2 = update_anim2;
      group.current.prevanim = prevanim;
    }
  }, [group]);

  React.useImperativeHandle(ref, () => group.current);

  const trailShow = true;

  useEffect(() => {
    if (!_.isEmpty(anims?.names) && trailShow) {
      setTimeout(() => {
        update_anim2("Scene");
      }, Math.random() * 2000);
    }
  }, [jstr(anims?.names), trailShow]);

  const showname = useRef(false);

  if (_.isEmpty(model))
    return (
      <>
        <mesh>
          <boxGeometry args={[0.2, 0.2, 0.2]} />
        </mesh>
      </>
    );

  return (
    <group
      // ref={ref}
      ref={group}
      {...props}
      dispose={null}
    >
      {showname.current == true ? (
        <Text rotation-y={degToRad(90)} position-y={0.8} fontSize={0.18}>
          {bike.name}
        </Text>
      ) : (
        <></>
      )}

      <pointLight
        color={neon}
        position={[-0.8, 0.3, 0]}
        intensity={5}
        distance={1.2}
        decay={1}
      />

      <group scale={1} {...(modelcentered ? {} : { "position-x": -0.8 })}>
        {/*         <primitive object={clonedmodel.scene} /> */}
        <CarBase {...{ nodes, materials }} />
        {(trailShow || true) && (
          <group>{trail == "car_dirt" && <CarTrail />}</group>
        )}
      </group>
    </group>
  );
});
