import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Center,
  Html,
  Text,
  useAnimations,
  useGLTF,
  useHelper,
} from "@react-three/drei";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";
import { forwardRef } from "react";
import { useControls } from "leva";
import _ from "lodash";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import useGLTF_cloned from "./useGLTF_cloned.js";
import { getv, iso, jstr, nils } from "../utils/utils.js";
import { useModelsStore } from "./ModelsStore.js";
import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";

export default forwardRef(function Horse(
  {
    bike,
    curranim,
    modelcentered = false,
    overrideskin,
    overridetrail,
    ...props
  },
  ref,
) {
  const group = useRef();
  const models_store = useModelsStore();

  const model = models_store.get_model("horse");
  const clonedmodel = useGLTF_cloned(model);
  const { nodes, materials, animations = [] } = clonedmodel;

  const { hex_code, hid, name } = bike;
  const neon = useMemo(() => {
    return `#${hex_code}`;
  }, [hex_code]);

  const prevanim = useRef(null);

  const anims = useAnimations(animations, group);
  const actions = anims.actions;
  const update_anim = (
    curr,
    { crossfadetime = 0.5, crossfade = true, fromtime = 0 },
  ) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      actions[curr].time = fromtime;
      if (crossfade && prev && actions[prev]) {
        actions[prev].crossFadeTo(actions[curr], crossfadetime);
        actions[curr].play();
      } else {
        action.play();
      }
      prevanim.current = curr;
    }
  };
  const update_anim2 = (curr, fromtime = 0) => {
    let prev = prevanim.current;
    let action = actions[curr];
    // console.log("update_anim2", curr, "action", actions, actions[curr]);
    if (action) {
      action.time = fromtime;
      action.play();
      prevanim.current = curr;
    } else {
      // console.log("action is nil");
    }
  };
  useEffect(() => {
    if (group.current) {
      // group.current.update_anim = update_anim;
      // group.current.update_anim2 = update_anim2;
      // group.current.prevanim = prevanim;
    }
  }, [group]);

  React.useImperativeHandle(ref, () => group.current);

  const skinob = overrideskin || getv(bike, `skino.horse`) || null;
  const skin = getv(skinob, "name");

  const trail = overridetrail || getv(bike, `trail`) || null;
  const trailColor = nils(skinob) ? neon : getv(skinob, "acc");

  const { clock } = useThree();
  const is_running = false;
  const trailShow = is_running;

  const showname = useRef(false);

  const lightRef1 = useRef();

  const idleAnimations = [
    // "[Action Stash]",
    "[Action Stash].001",
    "HORSE IDLE 05",
    "HORSE IDLE 06",
    // "HORSE RUN 05"
  ];
  const [idleanim, set_idleanim] = useState(null);
  const select_randomidle = () => {
    console.log("select_randomidle");
    let randomIdle;
    do {
      randomIdle =
        idleAnimations[Math.floor(Math.random() * idleAnimations.length)];
    } while (randomIdle == idleanim);
    // if (bike?.hid == 10) console.log("select random", randomIdle);
    set_idleanim(randomIdle);
  };

  const idle_timer = useRef(null);
  useEffect(() => {
    if (idle_timer.current) clearTimeout(idle_timer.current);

    if (is_running) return;
    if (!idleanim) select_randomidle();

    return () => {
      if (idle_timer.current) clearTimeout(idle_timer.current);
    };
  }, [is_running, idleanim]);

  useEffect(() => {
    if (is_running) {
      update_anim("HORSE RUN 05", { fromtime: Math.random() * 2000 });
    } else {
      // let idleanim = "HORSE IDLE 06";
      if (idleanim) {
        update_anim(idleanim, { fromtime: Math.random() * 2000 });
      }
    }
    return () => {};
  }, [is_running, idleanim]);

  const neon_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m.color = new THREE.Color(neon);
    m.emissive = new THREE.Color(neon);
    m.emissiveIntensity = 0.5;
    return m;
  }, [neon]);

  if (_.isEmpty(model))
    return (
      <>
        <mesh>
          <boxGeometry args={[0.2, 0.2, 0.2]} />
        </mesh>
      </>
    );

  return (
    <group
      // ref={ref}
      ref={group}
      {...props}
      dispose={null}
    >
      <mesh
        castShadow={true}
        receiveShadow={true}
        rotation={[degToRad(90), 0, degToRad(90)]}
        position={[-0.8, 0.05, 0]}
      >
        <planeGeometry args={[4, 4]} />
        <meshStandardMaterial
          transparent={true}
          opacity={0}
          color={"#000000"}
          side={THREE.DoubleSide}
        />
      </mesh>
      <pointLight
        ref={lightRef1}
        color={neon}
        position={[-0.8, 0.3, 0]}
        intensity={10}
        distance={1.8}
        decay={0.5}
      />
      {showname.current == true ? (
        <Text rotation-y={degToRad(90)} position-y={0.8} fontSize={0.18}>
          {bike.name}
        </Text>
      ) : (
        <></>
      )}

      <group
        scale={20}
        rotation-y={Math.PI / 2}
        {...(modelcentered ? {} : { "position-x": -0.8 })}
      >
        <group name="Scene">
          <group name="rig003">
            <group name="HorseDNA_4">
              <skinnedMesh
                name="Cylinder003"
                geometry={nodes.Cylinder003.geometry}
                material={materials["DNA HORSE Black"]}
                skeleton={nodes.Cylinder003.skeleton}
              />
              <skinnedMesh
                name="Cylinder003_1"
                geometry={nodes.Cylinder003_1.geometry}
                material={neon_mat}
                skeleton={nodes.Cylinder003_1.skeleton}
              />
            </group>
            <primitive object={nodes.root} />
            <primitive object={nodes["MCH-torsoparent"]} />
            <primitive object={nodes["MCH-hind_foot_ikparentL"]} />
            <primitive object={nodes["MCH-thigh_ik_targetparentL"]} />
            <primitive object={nodes["MCH-hind_foot_ikparentR"]} />
            <primitive object={nodes["MCH-thigh_ik_targetparentR"]} />
            <primitive object={nodes["MCH-forefoot_ikparentL"]} />
            <primitive object={nodes["MCH-upper_arm_ik_targetparentL"]} />
            <primitive object={nodes["MCH-forefoot_ikparentR"]} />
            <primitive object={nodes["MCH-upper_arm_ik_targetparentR"]} />
          </group>
        </group>
      </group>
      <group rotation-y={degToRad(180)} position={[-0.8, -0.15, 0]}></group>
    </group>
  );
});
