import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Center, Html, Text, useAnimations, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";
import { forwardRef } from "react";
import _ from "lodash";
import { degToRad } from "three/src/math/MathUtils.js";
import { getv, iso, jstr, nils } from "../utils/utils.js";
import { useModelsStore } from "./ModelsStore.js";
import { useFrame } from "@react-three/fiber";
import useGLTF_cloned from "./useGLTF_cloned";
import { Trail } from "./Trails.js";

export default function TronBikeSkinned(
  {
    highlight_bike = false,
    bike,
    curranim,
    modelcentered = false,
    special_skin: spskin,
    ...props
  },
  ref,
) {
  const group = useRef();
  const models_store = useModelsStore();

  const model = useGLTF(spskin.link);
  const clonedmodel = useGLTF_cloned(model);
  const { animations = [] } = clonedmodel;

  const { hex_code, hid } = bike;
  const neon = useMemo(() => {
    return `#${hex_code}`;
  }, [hex_code]);

  // useEffect(() => {
  //   console.log("special_skin", spskin);
  // }, []);

  const prevanim = useRef(null);

  const anims = useAnimations(animations, group);
  // console.log("anims", );
  const actions = anims.actions;

  const update_anim = (
    curr,
    crossfadetime = 0.5,
    crossfade = true,
    fromtime = 0,
  ) => {
    let prev = prevanim.current;
    let action = actions[curr];
    // console.log("update_anim", curr, action);
    if (action) {
      actions[curr].time = fromtime;
      if (crossfade && prev && actions[prev]) {
        actions[prev].crossFadeTo(actions[curr], crossfadetime);
        actions[curr].play();
      } else {
        action.play();
      }
      prevanim.current = curr;
    }
  };

  const update_anim2 = (curr, fromtime = 0) => {
    let prev = prevanim.current;
    let action = actions[curr];
    // console.log("update_anim2", action);

    if (action) {
      action.time = fromtime;
      action.play();
      prevanim.current = curr;
    }
  };
  useEffect(() => {
    if (_.isEmpty(clonedmodel?.animations)) return;
    if (!_.isEmpty(anims?.names) && anims?.names.includes(spskin.animkey)) {
      update_anim2(spskin.animkey);
    }
  }, [clonedmodel, jstr(anims?.names)]);

  // useEffect(() => {
  //   if (group.current) {
  //     group.current.update_anim = update_anim;
  //     group.current.update_anim2 = update_anim2;
  //     group.current.prevanim = prevanim;
  //   }
  // }, [group]);
  // React.useImperativeHandle(ref, () => group.current);

  const trailShow = true;
  const showname = useRef(false);

  useEffect(() => {
    if (!clonedmodel) return;
    clonedmodel.scene.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;
        if (material && material.isMeshPhysicalMaterial) {
          // Check if different texture maps are present and update their color space
          const textureMaps = [
            "map", // base color texture
            "roughnessMap",
            "metalnessMap",
            "normalMap",
            "emissiveMap",
            "bumpMap",
            "aoMap", // ambient occlusion map
            "clearcoatNormalMap", // for clear coat materials
          ];

          textureMaps.forEach((mapType) => {
            if (material[mapType]) {
              material[mapType].colorSpace = THREE.SRGBColorSpace;
              material[mapType].needsUpdate = true;
            }
          });

          // Optionally, adjust emissive intensity if it looks wrong
          if (material.emissiveMap) {
            material.emissiveIntensity = 1; // adjust as needed
          }
        }
      }
    });
  }, [clonedmodel.scene]);

  if (_.isEmpty(model))
    return (
      <>
        <mesh>
          <boxGeometry args={[0.2, 0.2, 0.2]} />
        </mesh>
      </>
    );

  return (
    <group {...props} dispose={null}>
      {showname.current == true ? (
        <Text rotation-y={degToRad(90)} position-y={0.8} fontSize={0.18}>
          {bike.name}
        </Text>
      ) : (
        <></>
      )}

      <pointLight
        color={neon}
        position={[-0.8, 0.3, 0]}
        intensity={5}
        distance={1.2}
        decay={1}
      />

      <group
        ref={group}
        scale={10}
        rotation-y={Math.PI / 2}
        {...(modelcentered ? {} : { "position-x": -0.8 })}
      >
        <primitive object={clonedmodel.scene} />
      </group>

      <group rotation-y={degToRad(180)} position={[-0.8, -0.15, 0]}>
        <Trail
          {...{
            trail: getv(bike, `trail`),
            show: trailShow,
          }}
        />
      </group>
      <group rotation-y={degToRad(180)} position={[-0.8, -0.15, 0]}></group>
    </group>
  );
}
