import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { twMerge } from "tailwind-merge";
import { extract_inp, F_Input, F_OptionInput } from "../components/input";
import {
  Card,
  Img,
  PolyLink,
  Tag,
  TokenIcon,
  InpText,
} from "../components/utilityComps";
import {
  base64_to_json,
  cdelay,
  dec,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
} from "../utils/utils";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useAuthContext } from "../wrappers/AuthWrapper";
import {
  cb_txt,
  class_cn,
  class_text,
  get_payout_txt,
  tablecn,
} from "../utils/cn_map";
import _ from "lodash";
import { tokdecn, tokdecn2, useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import { RaceAutoFiller } from "../contracts/RaceAutoFiller/RaceAutoFiller";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import {
  btbk,
  polytxnidlink,
  qiserr,
  qissuccesss,
  q_open_vault_valid_hs,
  q_que_txn,
  q_raceautofiller_bulk_raceinfo,
  q_raceautofiller_bulk_vault_validity,
  q_raceautofiller_reqrefund,
  q_raceautofiller_txns,
  q_races_options,
} from "../queries/queries";
import { PopUp, PopupCloseBtn } from "../components/popup";
import {
  GenderTag,
  MiniElementTag,
  MiniGenderTag,
} from "../components/ShortComps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBattery,
  faBolt,
  faBox,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { polychainimg } from "../utils/links";
import { RaceBaseInfoCard } from "../utils/raceutils";
import { fpost } from "../queries/fetch";
import { IfInView } from "./FinishedRacesPage";
import { RaceCardR2_baseinfo } from "../utils/raceutils2";
import { useThirdWebLoginContext } from "./ThirdWebLogin";

const AutoFillerContext = createContext();
const useAutoFillerContext = () => useContext(AutoFillerContext);

const RequestRefund = ({ t }) => {
  const refunded = !nils(getv(t, "refund.hash"));
  const [requested, set_requested] = useState(t.init_refund === true);
  const [resp, set_resp] = useState(null);

  const exhausted = t.exhausted === true;

  const request_refund = async () => {
    try {
      set_resp({ loading: true });
      await cdelay(1000);
      // throw new Error("not implemented");
      let resp = await q_raceautofiller_reqrefund({ id: t.id }).queryFn();
      if (resp.err) throw new Error(resp.err);
      set_resp({ loading: false, msg: "Refund Requested" });
      if (getv(resp, "result.done") == 1) set_requested(true);
    } catch (err) {
      set_resp({ loading: false, err: err.message });
    }
  };

  const trim_err = (e, n = 30) => {
    if (nils(e)) return null;
    if (e.length > n) return e.slice(0, n) + "...";
    return e;
  };

  return (
    <div className="w-max">
      {refunded ? (
        <>
          <div class="fr-sc resp-gap-1">
            <PolyLink hash={getv(t, "refund.hash")} />
            <div class="fc-ss">
              <span className="text-green-400 fr-sc resp-gap-1">
                <span>Refunded</span>
                <span className="text-acc0">
                  {dec(
                    getv(t, "refund.refundamt"),
                    tokdecn(getv(t, "refund.token")),
                  )}

                  {getv(t, "refund.token")}
                </span>
              </span>
              <span>
                for {getv(t, "refund.rem")} <FontAwesomeIcon icon={faBolt} />
              </span>
              <span>at {iso_format(getv(t, "refund.date"))}</span>
            </div>
          </div>
        </>
      ) : requested ? (
        <span class="text-blue-400">Processing Refund...</span>
      ) : (
        <div class="fr-sc">
          {resp?.loading == true ? (
            <Loader01c size="s" />
          ) : (
            <div class="fc-ss">
              {!exhausted && (
                <Tag
                  onClick={() => {
                    request_refund();
                  }}
                  className="bg-acc0/40 -skew-x-12"
                >
                  Refund Rem
                </Tag>
              )}
              {!nils(resp?.err) && (
                <p className="text-red-300">{trim_err(resp?.err)}</p>
              )}
              {!nils(getv(t, "refund.err")) && (
                <p className="text-red-300">
                  {trim_err(getv(t, "refund.err"))}
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EnterSection = () => {
  const appcon = useAppContext();
  const { tokmap, psearch, tok_to_usd_val, usd_to_tok_val } = appcon;
  const { filt, set_filt, vault } = useAutoFillerContext();
  const { hids, bikesob } = useAccountContext();

  const { thirdweb_client, active_account } = useThirdWebLoginContext();
  const { aumode, auth } = useAuthContext();

  const bulkid = getv(psearch, "bulkid");
  const [qor] = useQueries([
    q_raceautofiller_bulk_raceinfo({ bulkid }, { enabled: !nils(bulkid) }),
  ]);
  const race = useMemo(() => {
    let r = getv(qor, "data.result");
    if (nils(r)) return {};
    r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
    return r;
  }, [qor.dataUpdatedAt]);
  const r = race;

  const [rver, token] = useMemo(() => {
    if (nils(race)) return [null, null];
    let rver = `v${race.version}`;
    let token = race.paytoken;
    return [rver, token];
  }, [jstr(race)]);

  const is_bulk_valid = useMemo(() => {
    if (!r) return false;
    if (!_.isEmpty(r.eventtags) && r.eventtags.includes("Trail Points"))
      return false;

    return [50, 60, 70, 80].includes(r.class) && [2, 3].includes(r.version);
  }, [jstr(r)]);

  const headcn = "resp-text--1 italic font-digi text-acc0";
  const inpargs = {
    nf: filt,
    set_nf: set_filt,
    nfkey: "autofilller",
  };

  const [resp, set_resp] = useState(null);
  const [valid, errmsg, postprocinfo] = useMemo(() => {
    if (is_bulk_valid === false)
      return [false, "", "Bulk races not supported on this"];
    let { races_n, hids } = filt;

    let feeusd = getv(race, "init_feeusd");
    let fee = parseFloat(usd_to_tok_val(feeusd, token));
    let post = { fee, feeusd, token };

    if (nils(races_n)) return [false, "", post];
    if (_.isEmpty(hids)) return [false, "", post];

    let totfeeusd = dec(feeusd * races_n, 6);
    let totfee = usd_to_tok_val(totfeeusd, token);
    post.totfee = totfee;

    return [true, null, post];
  }, [jstr(filt), jstr(tokmap), jstr(race), rver, token, is_bulk_valid]);

  const submit = async () => {
    try {
      set_resp({ loading: true });
      await cdelay(1000);

      set_resp({ loading: true, msg: "Checking Vault limit and validity" });
      let respvalid = await q_raceautofiller_bulk_vault_validity({
        bulkid,
        vault,
      }).queryFn();
      console.log({ respvalid });
      let valid = getv(respvalid, "result.valid");
      if (!valid) {
        throw new Error(getv(respvalid, "err"));
      }

      const con = await RaceAutoFiller.get_contract();
      const conaddr = con.contractAddress;

      let feeusd = getv(race, "init_feeusd");
      let { races_n } = filt;

      let remfilt = {
        bulkid,
        feeusd,
        races_n,
        hids: filt.hids,
      };
      let args = jstr(remfilt);

      // console.log("pars", rver, races_n, feeusd, args);
      // const token =
      //   filt.rver === "v2" ? "WETH" : filt.rver === "v3" ? "DEZ" : null;
      let fee = usd_to_tok_val(feeusd * 1, token);
      let totfee = usd_to_tok_val(feeusd * races_n, token);
      const paycon =
        aumode == "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);
      // console.log("totfee", totfee, token);

      let bal = await paycon.balanceOf(vault);
      bal = parseFloat(tofeth(bal));
      if (bal < totfee) {
        set_resp({ loading: true, msg: "Insufficient Balance" });
      }
      // console.log("bal", bal);

      set_resp({ loading: true, msg: "Fetching Allowance" });
      let alw = await paycon.allowance(vault, conaddr);
      alw = parseFloat(tofeth(alw)).toFixed(6);
      // console.log("alw", alw);

      if (parseFloat(alw) < parseFloat(totfee)) {
        let usealwval = Math.max(totfee, Math.max(bal * 0.9, totfee * 3));
        if (aumode == "thirdweb") {
          let r = await t3_contract_call(
            race.paytoken,
            "approve",
            [conaddr, toeth(dec(usealwval, 6))],
            "txn",
            true,
            { active_account: active_account },
          );
          set_resp({ loading: true, msg: "Allowance Updated" });
          await cdelay(5 * 1e3);
          set_resp({ loading: true, msg: "Sending Txn" });
        } else {
          set_resp({
            loading: true,
            msg: "Confirm Metamask to update allowance",
          });
          let r = await paycon.approve(conaddr, toeth(dec(usealwval, 6)));
          await r.wait();
          set_resp({ loading: true, msg: "Allowance Updated" });
          await cdelay(1000);
          set_resp({ loading: true, msg: "Confirm Metamask for AutoFill Txn" });
        }
      }

      const usefee = dec(fee, 6);
      // console.log("usefee", usefee, token);

      let resp = null;
      if (aumode == "thirdweb") {
        resp = await t3_contract_call(
          "raceautofiller",
          "add_filler",
          [rver, races_n, toeth(usefee), token, args],
          "txn",
          true,
          { active_account: active_account },
        );
      } else {
        resp = await con.add_filler(rver, races_n, toeth(usefee), token, args);
        resp = await resp.wait();
      }

      await q_que_txn({
        service: "raceautofiller",
        hash: resp.hash,
      }).queryFn();

      set_resp({ loading: false, msg: "Txn Sent", hash: resp.hash });
      set_filt({});
    } catch (err) {
      console.log(err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      if (errmsg.length > 100) errmsg = errmsg.slice(0, 100) + "...";
      set_resp({ loading: false, err: errmsg });
    }
  };

  const [bpop, set_bpop] = useState(false);
  const [selhids, set_selhids] = useState([]);
  const selhids_searchtxt = extract_inp("autofiller-hid-search");
  // console.log("selhids", selhids);

  const [qovalidhids] = useQueries([
    q_open_vault_valid_hs(
      { rid: race?.rid, check_for_bulk: true },
      { enabled: !nils(race.rid) },
    ),
  ]);
  const validhids = useMemo(() => {
    let hs = getv(qovalidhids, "data.result", []);
    hs = _.map(hs, 0);
    return hs;
  }, [qovalidhids.dataUpdatedAt]);

  const filthids = useMemo(() => {
    let searchtxt = selhids_searchtxt;
    if (nils(searchtxt)) return validhids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(validhids)
      .map((hid) => {
        let h = bikesob[hid];
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(hids), jstr(validhids), selhids_searchtxt]);

  const enterbtn = (
    <>
      <Link to={`/races`}>
        <Tag className="bg-orange-500/40 text-white font-digi resp-text--1 transform -skew-x-12">
          Enter Bulk Race
        </Tag>
      </Link>
    </>
  );

  if (
    nils(bulkid) ||
    qiserr(qor) ||
    (!qor.isLoading && _.isEmpty(race))  ||
    is_bulk_valid === false
  )
    return (
      <div className="w-[60rem] max-w-[95vw] mx-auto">
        <Card className="bg-r2lig/20 backdrop-blur-md backdrop-blur-md w-full">
          <div class="fc-cc resp-text--1 text-white resp-gap-2">
            {qiserr(qor) ? (
              <p className="text-red-200">Race Error: {qiserr(qor)}</p>
            ) : (
              <p className="text-red-200">
                Sorry couldnt find a valid race for the given filters
              </p>
            )}
            <p>Please visit the Lobby page again and</p>
            <div class="fr-sc">
              <span>and click this tag:</span>
              {enterbtn}
            </div>
          </div>
        </Card>
      </div>
    );

  if (qor.isLoading)
    return (
      <div className="w-[60rem] max-w-[95vw] mx-auto">
        <div class="fr-cc">
          <Loader01c size="s" />
        </div>
      </div>
    );

  const rv_txt = _.capitalize(race.rvmode);
  const rv_plural = _.capitalize(
    race.rvmode == "bike"
      ? "bikes"
      : race.rvmode == "horse"
        ? "horses"
        : race.rvmode == "car"
          ? "cars"
          : "",
  );

  return (
    <div className="w-[60rem] max-w-[95vw] mx-auto resp-text--2">
      <Card className="bg-r2lig/20 backdrop-blur-md border border-acc1 w-full">
        <RaceCardR2_baseinfo
          {...{
            race,
            force_rtstatus: "bulk",
            cont_props: {},
          }}
        />

        <div class="fc-ss">
          <PopUp
            wrapcn={"top-[6rem]"}
            innercn={"translate-y-[0%]"}
            overlayclose={false}
            openstate={bpop}
          >
            <Card className="card-dark-bg border border-acc4 max-w-[95vw] w-[40rem] relative">
              <PopupCloseBtn closepopup={() => set_bpop(false)} />
              <p class="text-center resp-text-1 font-digi ">
                Select {rv_plural}
              </p>
              <div class="my-2"></div>
              <div class="fr-sc">
                <div class="flex-1"></div>
                <Tag
                  onClick={() => {
                    set_selhids([]);
                  }}
                  className="bg-red-500/60"
                >
                  clear
                </Tag>
                <Tag
                  onClick={() => {
                    set_filt({ ...filt, hids: selhids });
                    set_bpop(false);
                  }}
                  className="bg-r2lig"
                >
                  save
                </Tag>
              </div>

              <div class="my-1"></div>

              <InpText
                {...{
                  id: "autofiller-hid-search",
                  setter: () => {},
                  contprops: {
                    className: "w-full bg-r2dark",
                  },
                  inpprops: {
                    className: "w-full",
                  },
                  placeholder: "Search by bikeid or Name",
                  autoComplete: "off",
                }}
              />

              <div class="my-1"></div>
              <Card className="bg-r2lig/20 backdrop-blur-md w-full h-[50vh] overflow-auto">
                {filthids.map((hid) => {
                  let h = getv(bikesob, hid) || {};
                  let sel = selhids.includes(hid);
                  return (
                    <div
                      onClick={() => {
                        let nhids = [];
                        if (sel) {
                          nhids = _.chain(selhids)
                            .filter((h) => h != hid)
                            .uniq()
                            .value();
                        } else {
                          nhids = _.chain([...selhids, hid])
                            .uniq()
                            .value();
                        }
                        set_selhids(nhids);
                      }}
                      className={twMerge(
                        "p-2 fr-sc resp-gap-4 cursor-pointer select-none border-b-2 border-r2lig/40",
                      )}
                    >
                      {sel ? (
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="text-r2lig"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSquare}
                          className="text-slate-600"
                        />
                      )}
                      <div class="fc-ss resp-gap-1 resp-text--2">
                        <div class="fr-sc resp-gap-1">
                          <span className="resp-text--1 font-digi text-r2lig">
                            {hid}
                          </span>
                          <span>-</span>
                          <span className="resp-text--1 font-digi text-white">
                            {h.name}
                          </span>
                        </div>
                        <div class="fr-sc resp-gap-1">
                          <span>{_.capitalize(h.type)}</span>
                          <span>/</span>
                          <span>F{h.fno}</span>
                          <span>/</span>
                          <MiniElementTag element={h.element} />
                          <span>/</span>
                          <MiniGenderTag gender={h.gender} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Card>
            </Card>
          </PopUp>
        </div>

        <Card className="card-dark-bg w-full p-2 m-2">
          <div class="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-2 m-1">
            {!_.isEmpty(filt?.hids) &&
              filt.hids.map((hid) => {
                let h = getv(bikesob, hid) || {};
                return (
                  <div>
                    {hid}-{h.name}
                  </div>
                );
              })}
          </div>
          <div class="fr-sc">
            <div class="flex-1">
              <p class="resp-text-1">Step 1: Select {rv_plural} to enter</p>
            </div>
            {qovalidhids.isLoading && <Loader01c size="s" />}
            {qissuccesss(qovalidhids) && (
              <span class="text-acc4">
                {validhids.length ?? 0} valid {rv_plural}
              </span>
            )}
            <Tag
              onClick={() => {
                set_bpop(true);
              }}
              className={twMerge("font-digi bg-r2lig/40")}
            >
              Select {rv_plural}
            </Tag>
          </div>
        </Card>

        <div class="fr-sc p-2 resp-gap-2">
          <div class="flex-1">
            <p class="resp-text-1">
              Step 2: Select Races total for all {rv_plural}
            </p>
          </div>
          <span className="resp-text--1 font-digi text-acc0 italic">
            #Races
          </span>
          <F_Input
            {...{
              ...inpargs,
              path: "races_n",
              type: "n",
              contprops: {
                className: "xs:w-[2.5rem] lg:w-[5rem]",
              },
            }}
          />
          <span>x</span>
          <div class="fr-sc resp-gap-1">
            <TokenIcon token={postprocinfo.token} />
            <span>{dec(postprocinfo.fee, tokdecn(postprocinfo.token))}</span>
          </div>
          <span>=</span>

          {valid && (
            <Tag
              onClick={() => {
                if (resp?.loading) return;
                submit();
              }}
              className={twMerge(
                "font-digi bg-acc0/40  fr-sc resp-gap-2",
                resp?.loading
                  ? "cursor-not-allowed bg-dark"
                  : postprocinfo.token === "WETH"
                    ? "bg-purple-500/40"
                    : "bg-acc0/40",
              )}
            >
              <span>Pay</span>
              <TokenIcon token={postprocinfo.token} />
              <span>
                {dec(postprocinfo.totfee, tokdecn(postprocinfo.token))}
              </span>
            </Tag>
          )}
        </div>

        <div class="fr-sc p-2 resp-gap-2">
          {resp?.loading && <Loader01c size="s" />}
          <div class="">
            {!nils(resp?.msg) && (
              <p class={twMerge("text-green-300")}>{resp.msg}</p>
            )}
            {!nils(resp?.err) && (
              <p class={twMerge("text-red-300")}>{resp.err}</p>
            )}
            {!nils(errmsg) && (
              <p class={twMerge("resp-text--2 text-red-300")}>{errmsg}</p>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

const TxRow = ({ t, rmap, hmap, bulkmap, globalcollapse }) => {
  const rem = t.n - (t.used ?? 0);
  const [expand, set_expand] = useState(false);

  useEffect(() => {
    if (globalcollapse === true) set_expand(false);
  }, [globalcollapse]);

  const bulkid = getv(t, "racefilt.bulkid");
  const b = getv(bulkmap, bulkid) || {};
  // console.log("bulkmap", bulkid, b);
  const rvmode = useMemo(() => {
    if (!bulkid) return null;
    let s = bulkid.split(":");
    return s[1];
  }, [bulkid]);
  return (
    <>
      <tr>
        <td>
          <PolyLink hash={t.hash} />
        </td>
        <td>
          <div
            className={twMerge(
              "w-max fc-ss resp-gap-1",
              t.type == "filler_added" ? "text-acc0" : "text-white",
            )}
          >
            <span>
              {_.chain(t.type).split("_").map(_.capitalize).join(" ").value()}
            </span>
            <span>id: {t.id}</span>
          </div>
        </td>
        <td>
          {t.valid == false ? (
            <div class="fc-ss">
              <span class="text-red-400">Invalid</span>
              <span class="text-red-400">{t.invalidate_msg}</span>
            </div>
          ) : (
            <div class="fc-ss">
              <div class="fr-sc resp-gap-1">
                <span className="text-acc0">
                  ${dec(getv(t, "racefilt.feeusd"), 2)}
                </span>
                <span>x</span>
                <span>{getv(t, "n")}</span>
                <span>Races</span>
              </div>
              <div class="fr-sc w-full resp-gap-1">
                <div class="flex-1"></div>
                <>
                  <span className="text-acc0">{rem} </span>
                  <span className="text-acc0">{"/"} </span>
                  <span className="text-acc0">{t.n} </span>
                  <FontAwesomeIcon className="text-acc0" icon={faBolt} />
                </>
              </div>
              {t.exhausted && <span className="text-red-400">Exhausted</span>}
            </div>
          )}
        </td>
        <td>
          <div class="fr-sc">
            <TokenIcon token={t.token} />
            <span>{dec(t.amt, tokdecn(t.token))}</span>
          </div>
        </td>
        <td>
          <div class="fc-cc w-max">
            <span>Auto Started</span>
            <span>{iso_format(t.date)}</span>
          </div>
        </td>
        <td>
          <div class="fr-sc resp-gap-1">
            <RequestRefund {...{ t }} />
          </div>
        </td>

        <td>
          <Tag
            onClick={() => {
              set_expand(!expand);
            }}
            className=""
          >
            <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} />
          </Tag>
        </td>
      </tr>
      {expand && (
        <>
          <tr>
            <td />
            <td colSpan={5}>
              <div class="grid grid-cols-2 gap-2">
                <Card className="card-dark-bg border border-acc4 w-full">
                  <p>Filters</p>
                  <p>Race Bulk ID: {b.bulkid}</p>
                  {!_.isEmpty(b) && (
                    <>
                      <div class="fr-sc resp-gap-1">
                        <span className={twMerge(`text-c${b.class} font-digi`)}>
                          {class_text(b.class, "T")}
                        </span>

                        <span className={twMerge(`text-acc0 font-digi`)}>
                          {cb_txt(b.cb, rvmode)}
                        </span>
                        <div class="flex-1"></div>
                        <span className={twMerge(`text-yellow-400 font-mon`)}>
                          ${dec(b.feeusd, 2)}
                        </span>
                      </div>

                      <div class="fr-sc resp-gap-1">
                        <span className={twMerge(`uppercase`)}>{b.format}</span>
                        <span className={twMerge(`text-purple-400 `)}>
                          {get_payout_txt(b.payout)}
                        </span>
                      </div>
                    </>
                  )}
                </Card>
                <Card className="card-dark-bg border border-acc4 w-full">
                  <p>Selected Cores</p>
                  {_.isEmpty(getv(t, "racefilt.hids")) ? (
                    <p class="text-yellow-300">
                      Selecting Vault Cores Randomly
                    </p>
                  ) : (
                    <div class="fc-ss w-full resp-gap-1">
                      {getv(t, "racefilt.hids", []).map((hid) => {
                        let h = getv(hmap, hid);
                        return (
                          <div class="fr-sc w-full resp-gap-1">
                            <span className="text-acc0">{hid}</span>
                            <span>-</span>
                            <span className="">{h.name}</span>
                            <div class="flex-1"></div>
                            <span className="uppercase">
                              {_.slice(h.type, 0, 3)}
                            </span>
                            <span>F{h.fno}</span>
                            <MiniElementTag element={h.element} />
                            <MiniGenderTag gender={h.gender} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Card>
              </div>
            </td>
          </tr>
          {!_.isEmpty(t.usedfills) ? (
            <tr>
              <td />
              <td colSpan={5}>
                <Card className="card-dark-bg border border-acc4 w-full overflow-auto w-full">
                  <table
                    className={twMerge(
                      tablecn.table,
                      "thintdrowp4-table ",
                      "w-full ",
                    )}
                  >
                    <tbody>
                      {t.usedfills.map((uf) => {
                        let r = getv(rmap, uf.rid);
                        let h = getv(hmap, uf.hid);
                        return (
                          <tr>
                            <td>
                              <Link target="_blank" to={`/race/${uf.rid}`}>
                                <div class="fc-ss resp-gap-1">
                                  <span>{uf.rid}</span>
                                  <span className="text-acc0 font-digi">
                                    {r.race_name}
                                  </span>
                                  <div class="fr-sc">
                                    <span className="uppercase">
                                      {r.format}
                                    </span>
                                    {r.format == "normal" && (
                                      <span>{get_payout_txt(r.payout)}</span>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td>
                              <div class="fr-sc w-full resp-gap-1">
                                <span className="text-acc0">{uf.hid}</span>
                                <span>-</span>
                                <span className="font-digi">{h.name}</span>
                              </div>
                              <div class="fr-sc w-full resp-gap-1">
                                <div class="flex-1"></div>
                                <span className="uppercase">
                                  {_.slice(h.type, 0, 3)}
                                </span>
                                <span>F{h.fno}</span>
                                <MiniElementTag element={h.element} />
                                <MiniGenderTag gender={h.gender} />
                              </div>
                            </td>
                            <td>
                              <div class="fc-ss">
                                <span>Entered At</span>
                                <span>{iso_format(uf.date)}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </td>
            </tr>
          ) : null}
        </>
      )}
    </>
  );
};

const Transactions = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const acon = useAutoFillerContext();
  const { vault } = acon;
  // const { qotxns } = acon;

  const [globalcollapse, set_globalcollapse] = useState(false);
  const [showopen, set_showopen] = useState(psearch?.showopen === "true");

  // const [qotxns] = useQueries([
  //   q_raceautofiller_txns(
  //     { vault, showopen },
  //     {
  //       staleTime: 1 * 60 * 1e3,
  //       refetchInterval: 1 * 60 * 1e3,
  //     },
  //   ),
  // ]);
  //

  const fetchpage = async ({ page = 0 }) => {
    if (page == -1) return null;
    // console.log("fetchpage", page);
    let resp = await fpost(`${btbk}/fbike/raceautofiller/txns`, {
      page,
      vault,
      showopen,
    });
    return resp;
  };
  const q = useInfiniteQuery({
    queryKey: ["autofiller:txns", jstr({ vault, showopen })],
    queryFn: (pars) => {
      return fetchpage(pars.pageParam || {});
    },
    getNextPageParam: (lastPage, allPages) => {
      let page = getv(lastPage, "result.next_page");
      return { page };
    },
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
  });

  const {
    txns = [],
    rmap = {},
    hmap = {},
    bulkmap = {},
  } = useMemo(() => {
    let resp = _.chain(q.data?.pages)
      .filter((e) => getv(e, "status") == "success")
      .value();
    let rmap = {};
    let hmap = {};
    let bulkmap = {};
    let txns = [];
    for (let r of resp) {
      txns = [...txns, ...getv(r, "result.txns", [])];
      rmap = { ...rmap, ...getv(r, "result.rmap", {}) };
      hmap = { ...hmap, ...getv(r, "result.hmap", {}) };
      bulkmap = { ...bulkmap, ...getv(r, "result.bulkmap", {}) };
    }
    txns = _.chain(txns)
      .sortBy((e) => -nano(e.date))
      .uniqBy((e) => e.id)
      .value();
    return { txns, rmap, hmap, bulkmap };
  }, [q.dataUpdatedAt]);

  const filttxns = useMemo(() => {
    if (!showopen) return txns;
    return _.filter(txns, (t) => !t.exhausted);
  }, [showopen, jstr(txns)]);

  useEffect(() => {
    setTimeout(() => {
      upd_psearch({ showopen });
    }, 1 * 1e3);
  }, [showopen]);

  return (
    <div class="w-[70rem] max-w-[98vw] p-2 mx-auto overflow-auto">
      <div class="fr-sc resp-gap-2 my-2">
        <Tag
          onClick={() => {
            set_showopen(!showopen);
          }}
          className="fr-sc resp-gap-2 select-none"
        >
          <FontAwesomeIcon
            className={showopen ? "text-acc0" : "text-slate-500"}
            icon={showopen ? faCheckSquare : faSquare}
          />
          <span>Show Only Open</span>
        </Tag>
        <div class="flex-1"></div>
        <Tag
          onClick={() => {
            set_globalcollapse(true);
            setTimeout(() => set_globalcollapse(false), 1000);
          }}
          className="fr-sc bg-acc0/40"
        >
          Collapse All
        </Tag>
      </div>
      <Card className="bg-r2lig/20 backdrop-blur-md border border-acc4 w-full overflow-auto">
        <table
          className={twMerge(
            tablecn.table,
            "thintdrowp4-table-base table-basic-border w-max resp-text--2",
          )}
        >
          <tbody>
            {filttxns.map((t) => {
              return (
                <TxRow
                  key={t.hash}
                  {...{
                    t,
                    rmap,
                    hmap,
                    bulkmap,
                    globalcollapse,
                  }}
                />
              );
            })}
          </tbody>
        </table>
        <IfInView runViewFn={q.isFetching == false} onView={q.fetchNextPage} />
        <div class="fr-cc">{q.isFetching && <Loader01c size="s" />}</div>
      </Card>
    </div>
  );
};

export const AutoFillerPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const { vault: auvault } = useAccountContext();
  const { auth } = useAuthContext();

  const vault = useMemo(() => {
    if (!nils(psearch?.vault)) return psearch.vault.toLowerCase();
    return auvault;
  }, [psearch?.vault, auvault]);

  const [tab, set_tab] = useState(psearch?.tab ?? "transactions");
  const [filt, set_filt] = useState(
    !nils(psearch?.f) ? base64_to_json(psearch.f) : {},
  );
  useEffect(() => {
    let f = json_to_base64(filt);
    upd_psearch({ f: f, tab });
  }, [jstr(filt), tab]);

  const acon = {
    vault,
    auth,

    tab,
    set_tab,
    filt,
    set_filt,

    // qotxns,
  };

  return (
    <div class="h-page">
      <div class="w-[80rem] max-w-[98vw] mx-auto">
        <AutoFillerContext.Provider value={acon}>
          <div class="h-[3rem]"></div>

          <div class="fr-cc resp-gap-2 my-2">
            {["enter", "transactions"].map((_t) => {
              return (
                <Tag
                  onClick={() => set_tab(_t)}
                  className={twMerge(
                    "font-digi uppercase",
                    _t == tab ? "bg-r2lig/40 -skew-x-12" : "bg-dark",
                  )}
                >
                  {_t}
                </Tag>
              );
            })}
          </div>
          <hr className="my-2 w-[30rem] max-w-[90vw] mx-auto" />
          {tab == "enter" && <EnterSection />}
          {tab == "transactions" && <Transactions />}
          <div class="h-[5rem]"></div>
        </AutoFillerContext.Provider>
      </div>
    </div>
  );
};
