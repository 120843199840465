import _, { parseInt } from "lodash";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  q_arcade_race_hstats,
  q_bike_mini_info,
  q_fqual_actives,
  q_fqual_entrants,
  q_fqual_info,
  q_fqual_leader,
  q_fqual_signup_enter,
  q_fqual_signup_hvalid,
  q_fqual_signup_vault_hvalids,
  q_hstats,
  q_logtxnqueue,
  qiserr,
  qissuccesss,
  useStepQuery,
  q_fqual_reset_score,
  q_vaultinfo,
  q_fqual_vault_scoreboard,
} from "../queries/queries.js";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  cdelay,
  copy_clip,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
  toeth,
  tofeth,
  emp,
  trim_n,
} from "../utils/utils.js";
import {
  BImg,
  Card,
  Img,
  Tag,
  TokenIcon,
  ToolTip,
} from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { createContext } from "react";
import { Link, useParams } from "react-router-dom";
import { elementmap, gendermap, pos_txt, tablecn } from "../utils/cn_map.js";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faChevronRight,
  faCircle,
  faFlag,
  faFlagCheckered,
  faToggleOff,
  faToggleOn,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext, useNowContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import RaceStakeV02 from "../contracts/RaceStakeV02/RaceStakeV02.js";
import RaceStakeV03_DEZ from "../contracts/RaceStakeV03_DEZ/RaceStakeV03_DEZ.js";
import {
  mm_asset_signer,
  que_con_fn_runner,
} from "../contracts/contract_funcs.js";
import { contractAddress_list } from "../contracts/constants.js";
import { faction_img, polychainimg } from "../utils/links";
import { FqualTrainerInfo } from "./ClaimTrainerPage.js";
import { MiniElementTag, MiniGenderTag } from "../components/ShortComps.js";
import { SortHead, sort_fn, sort_list } from "../utils/filt.js";
import { CapsuleSwitch } from "../components/input.js";
import { SkewBtn } from "../utils/raceutils2.js";
import { MoVariants } from "../utils/motion_helper.js";
import { motion } from "framer-motion";
import { RacingDashBackLink } from "./RacingDashboard.js";

const ScoreboardContext = createContext({});
const useScoreboardContext = () => useContext(ScoreboardContext);

export const Scoreboard = () => {
  const { psearch, upd_psearch } = useAppContext();

  const accon = useAccountContext();
  const aucon = useAuthContext();

  const vault = useMemo(() => {
    if (!nils(psearch?.vault)) return psearch.vault.toLowerCase();
    else return aucon.vault;
  }, [psearch.vault, aucon.vault]);

  const enabled = !nils(vault);
  const [qov, qosc] = useQueries([
    q_vaultinfo({ vault }, { enabled }),
    q_fqual_vault_scoreboard({ vault }, { enabled }),
  ]);

  const vdoc = useMemo(() => {
    return qissuccesss(qov) ? getv(qov, "data.result") : null;
  }, [qov.dataUpdatedAt]);

  const scodoc = useMemo(() => {
    return qissuccesss(qosc) ? getv(qosc, "data.result") : null;
  }, [qosc.dataUpdatedAt]);

  const qualrows = getv(scodoc, "qualrows");
  const rows = useMemo(() => {
    if (_.isEmpty(qualrows)) return [];
    return _.chain(qualrows)
      .map((r) => {
        let f = getv(scodoc, `fmap.${r.fid}`);
        f.fqual_name = getv(f, "name");
        let h = getv(scodoc, `hmap.${r.hid}`);
        return { ...r, ...f, ...h };
      })
      .value();
  }, [jstr(scodoc)]);

  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    rank: { disp: "Rank", fn: (i1) => sort_fn(i1, "rank", "n") },
    fqual: { disp: "Fqual", fn: (i1) => sort_fn(i1, "fqual_name", "txt") },
    hid: { disp: "HID", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    races_n: { disp: "Races", fn: (i1) => sort_fn(i1, "sel_races_n", "n") },
    score: { disp: "Score", fn: (i1) => sort_fn(i1, "score", "n") },
    min_races_n: {
      disp: "MinRaces",
      fn: (i1) => sort_fn(i1, "qualifying_ranklim", "n"),
    },
    qual_score: {
      disp: "QualScore",
      fn: (i1) => sort_fn(i1, "cutoff", "n"),
    },
    qual: {
      disp: "Qualified",
      fn: (i1) => sort_fn(i1, "qualified", "n"),
    },
    races_needed: {
      disp: "Races Needed",
      fn: (i1) => sort_fn(i1, "races_needed", "n"),
    },
    ending: {
      disp: "Ending in",
      fn: (i1) => sort_fn(i1, "qualifying_times.ed", "txt"),
    },
    qualified: {
      disp: "Qualified",
      fn: (i1) =>
        sort_fn(i1, "qualified", "fn_n", (r) => {
          if (r.qualified == true) return 1;
          else return 0;
        }),
    },
  };
  const sortargs = { sorts, set_sorts, sort_ob };
  const sorted_rows = useMemo(() => {
    if (_.isEmpty(rows)) return [];
    let ar = sort_list({
      list: rows,
      sorts,
      sort_ob,
      id: "id",
    });
    return ar;
  }, [jstr(rows), jstr(sorts), jstr(sort_ob)]);

  const scon = {
    qosc,
    vdoc,
    scodoc,
  };

  return (
    <ScoreboardContext.Provider value={scon}>
      <div className="h-page w-full">
        <div className="max-w-[98vw] w-[70rem] overflow-auto mx-auto">
          <div className="h-[2rem]"></div>
          <RacingDashBackLink />

          <p className="resp-text-2 my-2 font-digi italic text-center">
            Scoreboard
          </p>
          <Link to={`/vault/${vault}`}>
            <div className="fc-cc">
              <span className="text-acc0 resp-text--2">{vault}</span>
              {qov.isLoading ? (
                <Loader01c />
              ) : qissuccesss(qov) ? (
                <span className="text-acc0 resp-text-1 font-digi">
                  {getv(vdoc, "vault_name")}
                </span>
              ) : null}
            </div>
          </Link>

          <div class="h-[1rem]"></div>
          <Card className="w-full bg-r2lig/20 backdrop-blur-md h-[80vh] overflow-auto border border-acc4 w-full">
            {qosc.isLoading ? (
              <Loader01c />
            ) : _.isEmpty(qualrows) ? (
              <div className="resp-text-1 my-2 font-digi italic text-center">
                No bikes in Leaderboard from this vault
              </div>
            ) : !_.isEmpty(qualrows) ? (
              <table className={twMerge(tablecn.table, "w-max mx-auto")}>
                <thead>
                  <tr class="thintdrow font-digi text-acc4">
                    <td>
                      {/* <span>Fqual</span> */}
                      <SortHead {...{ ...sortargs, k: "fqual" }} />
                    </td>
                    <td>
                      {/* <span>Ending in</span> */}
                      <SortHead {...{ ...sortargs, k: "ending" }} />
                    </td>
                    <td>
                      {/* <span>Qualified</span> */}
                      <SortHead {...{ ...sortargs, k: "qualified" }} />
                    </td>
                    <td>
                      {/* <span>Name</span> */}
                      <SortHead {...{ ...sortargs, k: "name" }} />
                    </td>
                    <td>
                      {/* <span>Races</span> */}
                      <SortHead {...{ ...sortargs, k: "races_n" }} />
                    </td>
                    <td>
                      {/* <span>Score</span> */}
                      <SortHead {...{ ...sortargs, k: "score" }} />
                    </td>
                    <td>
                      {/* <span>MinRaces</span> */}
                      <SortHead {...{ ...sortargs, k: "min_races_n" }} />
                    </td>
                    <td>
                      {/* <span>QualScore</span> */}
                      <SortHead {...{ ...sortargs, k: "qual_score" }} />
                    </td>
                    <td>
                      {/* <span>RacesNeeded</span> */}
                      <SortHead {...{ ...sortargs, k: "races_needed" }} />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {sorted_rows.map((r) => {
                    return (
                      <tr className="thintdrow resp-text--1">
                        <td>
                          <Link to={`/fqual/${r.fid}`}>
                            <span className="text-acc4">{r.fqual_name}</span>
                          </Link>
                        </td>
                        <td className="w-[10rem]">
                          <span>
                            {from_time_mini(getv(r, "qualifying_times.ed"))}
                          </span>
                        </td>
                        <td>
                          <div
                            class={twMerge(
                              "fr-sc resp-gap-2",
                              r.qualified == true
                                ? "text-green-300"
                                : "text-red-300",
                            )}
                          >
                            <span>{r.qualified == true ? "YES" : "NO"}</span>
                          </div>
                        </td>
                        <td>
                          <Link to={`/bike/${r.hid}`}>
                            <div class="fc-ss">
                              <span className="text-acc4">{r.name}</span>
                              <div class="fr-sc gap-1">
                                <FontAwesomeIcon
                                  className={elementmap[r.element]?.text}
                                  icon={elementmap[r.element]?.icon}
                                />
                                <span>F{r.fno}</span>
                                <span>{r.type}</span>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <div class="fr-sc resp-gap-2">
                            <FontAwesomeIcon icon={faFlag} />
                            <span>{dec(getv(r, "sel_races_n"), 0)}</span>
                          </div>
                        </td>
                        <td>
                          <div class="fr-sc resp-gap-2">
                            <span>{dec(getv(r, "score"), 2)}</span>
                          </div>
                        </td>
                        <td>
                          <div class="fr-sc resp-gap-2">
                            <FontAwesomeIcon icon={faFlag} />
                            <span>{dec(getv(r, "qualifying_ranklim"), 0)}</span>
                          </div>
                        </td>
                        <td>
                          <div class="fr-sc resp-gap-2">
                            <span>{dec(getv(r, "cutoff"), 2)}</span>
                          </div>
                        </td>

                        <td>
                          <div class="fr-sc resp-gap-2">
                            <FontAwesomeIcon icon={faFlag} />
                            <span>{dec(getv(r, "races_needed"), 0)}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </Card>
        </div>
      </div>
    </ScoreboardContext.Provider>
  );
};
