import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { getv, iso, nils } from "../../utils/utils";
import { PageWrapper } from "../../components/ShortComps";
import { Img } from "../../components/utilityComps";
import { Link } from "react-router-dom";

// https://webbuggsunity.github.io/FightSim_WebGL/

// const FightContext = createContext();
// const useFightContext = () => useContext(FightContext);

const imgs_ad_dna_base = "https://cdn.dnaracing.run/ad-dna";
const imgs_ad_dna = {
  0: `${imgs_ad_dna_base}/ad-dna-0.gif`,
  1: `${imgs_ad_dna_base}/ad-dna-1.gif`,
  2: `${imgs_ad_dna_base}/ad-dna-2.gif`,
  3: `${imgs_ad_dna_base}/ad-stimmy-banner.png`,
  4: `${imgs_ad_dna_base}/ad-dna-3.png`,
};

export const FactionsFighting = () => {
  const page_title_fn = () => "Factions Fighting";

  const idxmap = [2, 4];
  const [i, set_i] = useState(idxmap[0]);
  useEffect(() => {
    const interval = setInterval(() => {
      set_i((i + idxmap.length + 1) % idxmap.length);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [i]);

  return (
    <PageWrapper page_title_fn={page_title_fn} cont_cn="xs:w-full lg:w-[80rem]">
      <div class="fc-cc w-full">
        <p class="resp-text-2 text-center font-digi my-1">Factions Fighting</p>
        <div class="w-full xs:scale-[0.9] lg:scale-[1.3]">
          <iframe
            title="FightSim_WebGL"
            src="https://webbuggsunity.github.io/FightSim_WebGL"
            className="w-full aspect-[1.5/1]"
            frameborder="0"
            allowFullScreen={true}
            allowTransparency={true}
          ></iframe>
        </div>

        <div className="flex flex-1 fr-cc w-full">
          <Link to={"/claim-trainer?ref=factions_fighting"} className="w-full">
            <div class="w-full my-2 mx-auto">
              <Img className={"w-[100%] h-auto"} img={imgs_ad_dna[idxmap[i]]} />
            </div>
          </Link>
        </div>
      </div>
    </PageWrapper>
  );
};
