import { useEffect, useMemo, useState } from "react";
import { Logo } from "../components/Layout.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { dec, getv, jstr, nils } from "../utils/utils.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import {
  qiserr,
  qissuccesss,
  q_bikesearch,
  q_bike_advsearch,
} from "../queries/queries.js";
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { ElementTag, GenderTag } from "../components/ShortComps.js";
import {
  InpAutoWrap,
  InpDropdown,
  set_state_ob,
  set_val_inp,
} from "../components/input.js";
import { elementmap, gendermap, rvmode_s, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
  SortHead,
  sort_fn,
  sort_list,
} from "../utils/filt.js";
import { racecn } from "./Races.js";
import { faUsd } from "@fortawesome/free-solid-svg-icons";
import { RVImg } from "../components/BikeImg.js";

export const BikesSearch = () => {
  const [searchtxt, set_searchtxt] = useState(null);
  const [qohs] = useQueries([q_bikesearch({ name: searchtxt, limit: 5 })]);
  const bikes = useMemo(() => {
    return qohs.data?.result || [];
  }, [qohs.dataUpdatedAt]);

  return (
    <div className="xs:max-w-[90vw] lg:max-w-[30rem] w-full mx-auto relative">
      <div className="fr-sc">
        <div className="flex-1">
          <InpText
            {...{
              id: `search-fbike-core`,
              placeholder: "Search Core",
              inpprops: {
                className:
                  "resp-text-1 font-digi bg-r2dark/30 text-acc4 w-full",
              },
              contprops: {
                className:
                  "resp-text-1 font-digi bg-r2dark/30 text-acc4 w-full",
              },
              setter: (v) => {
                console.log("setter", v);
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
        </div>
        {qohs.isLoading && <Loader01c size="s" />}
        {!_.isEmpty(bikes) && (
          <>
            <div className="absolute top-[3rem] left-0 w-full max-h-[40vh] overflow-auto resp-p-1 bg-r2dark/60 rounded-lg">
              {bikes.map((h) => {
                return (
                  <Link
                    onClick={() => {
                      set_searchtxt("");
                      set_val_inp(`search-fbike-core`, "");
                    }}
                    to={`/bike/${h.hid}`}
                  >
                    <div className="resp-p-2 bg-r2lig/20 backdrop-blur-md rounded-md resp-my-2 cursor-pointer">
                      <div className="fr-sc resp-gap-2">
                        <div
                          className={twMerge(
                            `bg-${h.color} rounded-sm`,
                            "xs:w-[1.2rem] xs:h-[1.2rem] lg:w-[2.2rem] lg:h-[2.2rem]",
                          )}
                        ></div>
                        <div className="fc-ss font-digi flex-1">
                          <span className="resp-text--3">{h.hid}</span>
                          <span className="resp-text--2">{h.name}</span>
                        </div>
                        <div className="fc-ss font-digi resp-gap-2">
                          <span className="resp-text--3">
                            {`F${h.fno} / ${_.capitalize(h.type)}`}
                          </span>
                          <ElementTag
                            element={h.element}
                            className={"resp-text--3"}
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mainfiltbtn = racecn.mainfiltbtn;

const CoreFiltSection = ({ basefilt = {}, submit }) => {
  const asset_type = "core";

  const [filt, set_filt] = useState({
    rvmode: {
      type: "options-only",
      path: "type",
      cfilter: true,
      label: "rvmode",
      show_label: true,
      options: rvmode_s,
      filter_exception: [],
      vals: _.compact(basefilt?.type),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      label: "Element",
      show_label: true,
      options: _.keys(elementmap),
      filter_exception: [],
      vals: _.compact(basefilt?.element),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${elementmap[op]?.bg} -skew-x-12`;
      },
    },
    gender: {
      type: "options",
      path: "gender",
      cfilter: true,
      label: "Gender",
      show_label: true,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${gendermap[op]?.bg} -skew-x-12`;
      },
    },
    type: {
      type: "options",
      path: "type",
      cfilter: true,
      label: "Type",
      show_label: true,
      options: ["genesis", "morphed", "freak", "xclass"],
      filter_exception: [],
      vals: _.compact(basefilt?.type),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    fno: {
      type: "range",
      path: "fno",
      cfilter: true,
      label: "#F.No",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.fno ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    sort_by: {
      type: "options-only",
      path: "sort_by",
      cfilter: true,
      label: "Sort By",
      show_label: true,
      base_cn: `${mainfiltbtn} `,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
      options: [
        "-races_n",
        "+races_n",
        "-win_p",
        "+win_p",
        "-profit",
        "+profit",
        "-speed",
        "+speed",
        "-speed_avg",
        "+speed_avg",
      ],
      vals: basefilt?.sort_by,
    },
    races_n: {
      type: "range",
      path: "races_n",
      cfilter: false,
      label: "#Races",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.races_n ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    win_p: {
      type: "range",
      path: "win_p",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.win_p ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    profit: {
      type: "range",
      path: "profit",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.profit ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    speed: {
      type: "range",
      path: "speed",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.speed ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    speed_avg: {
      type: "range",
      path: "speed_avg",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.speed_avg ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    // if (asset_type == "core") {
    // set_main_valfilt(valfilt);
    // }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  // useEffect(() => { console.log("filt", filt); }, [jstr(filt)]);

  const inpargs = { fkey: "market-core", filters: filt, set_filters: set_filt };
  const cbinpargs = (k) => {
    const text_fn = (o) => (nils(o) ? "--" : o == "all" ? "All" : `CB${o}`);
    const val = getv(filt, `${k}.vals.cb`);
    return {
      options: ["all", 10, 12, 14, 16, 18, 20, 22, 24],
      option_cn: "p-1",
      txt: text_fn(val),
      text_fn,
      selection_cn: "bg-r2dark/20 border border-acc4",
      setter: (v) => {
        set_state_ob(filt, set_filt, `${k}.vals.cb`, v);
      },
    };
  };

  return (
    <Card
      className={
        "fc-ss resp-gap-1 border border-acc4 max-w-[95vw] w-[65rem] mx-auto"
      }
    >
      <div class="fr-cc w-full resp-gap-2 my-2">
        {rvmode_s.map((rvmode) => {
          let path = "rvmode.vals";
          let active = _.includes(getv(filt, path), rvmode);
          return (
            <div
              onClick={() => {
                let nrvs = _.cloneDeep(getv(filt, path)) || [];
                // if (active) nrvs = _.without(nrvs, rvmode);
                // else nrvs.push(rvmode);
                if (active) nrvs = null;
                else nrvs = rvmode;
                set_state_ob(filt, set_filt, path, nrvs);
              }}
              className={twMerge(
                "cursor-pointer resp-p-2 h-full rounded-md",
                active ? "bg-r2dark/40 shadow-acc4 shadow-md" : "bg-r2dark/40",
              )}
            >
              <RVImg {...{ rvmode }} hex_code={active ? "09D3FF" : "FFFFFF"} />
            </div>
          );
        })}
      </div>

      {/*       <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "rvmode" }} />
      </div> */}
      <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "fno" }} />
      </div>
      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem] resp-text--1">
          #Races
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("races_n")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "races_n" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem] resp-text--1">
          Win%
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("win_p")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "win_p" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem] resp-text--1">
          Profit[usd]
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("profit")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "profit" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem] resp-text--1">
          Max.Speed{" "}
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("speed")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "speed" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem] resp-text--1">
          Avg.Speed{" "}
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("speed_avg")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "speed_avg" }} />
        </div>
      </div>

      <div class="fr-sc">
        <InpAutoWrap {...{ ...inpargs, idd: "sort_by" }} />
      </div>

      <div className="fr-sc w-full">
        <Tag
          onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
        <div class="flex-1"></div>
        <Tag
          onClick={() => {
            submit(valfilt);
          }}
          className="resp-text-1 text-white -skew-x-12 bg-acc0/40"
        >
          {"Search"}
        </Tag>
      </div>
    </Card>
  );
};

const CoreTableRow = ({ h }) => {
  return (
    <tr>
      <td>
        <Link
          to={`/bike/${h.hid}?subtab=summary&tab=races`}
          className=""
          target="_blank"
        >
          <div class="fr-sc">
            <div class="xs:w-[2rem] lg:w-[4rem]">
              <RVImg {...{ rvmode: h.rvmode }} hex_code={"FFFFFF"} />
            </div>
            <div class="fc-ss">
              <p className="resp-text--1 text-acc4 font-digi xs:min-w-[5rem] lg:min-w-[10rem] ">
                {`#${h.hid} - ${h.name}`}
              </p>

              <p className="resp-text--2 text-white font-digi xs:min-w-[5rem] lg:min-w-[10rem] ">
                {h.vault_name}
              </p>
            </div>
          </div>
        </Link>
      </td>
      <td>
        <span className="resp-text--3">{_.capitalize(h.type)}</span>
      </td>
      <td>
        <div class={twMerge("fr-sc resp-gap-1", elementmap[h.element]?.text)}>
          <FontAwesomeIcon icon={elementmap[h.element]?.icon} />
          <span className="resp-text--3">{_.capitalize(h.element)}</span>
        </div>
      </td>
      <td>
        <div class={twMerge("fr-sc resp-gap-1", gendermap[h.gender]?.text)}>
          <FontAwesomeIcon icon={gendermap[h.gender]?.icon} />
          <span className="resp-text--3">{_.capitalize(h.gender)}</span>
        </div>
      </td>
      <td>
        <span className="resp-text--1 text-acc4 font-digi italic">{`F${h.fno}`}</span>
      </td>
      <td>
        <span className="resp-text--1 text-acc4 font-digi">{`${h.races_n}`}</span>
      </td>
      <td>
        <span className="resp-text--1 text-acc4 font-digi">{`${dec(h.win_p * 100, 2)}%`}</span>
      </td>
      <td>
        <div
          className={twMerge(
            "fr-sc resp-gap-1 w-full px-[0.5rem]",
            h.profit > 0 ? "text-green-400" : "text-red-400",
          )}
        >
          <div class="flex-1"></div>
          <span className="text-right resp-text--2 w-[3rem] font-digi">{`${dec(h.profit, 0)}`}</span>
          <FontAwesomeIcon icon={faUsd} />
        </div>
      </td>
      <td className={twMerge("text-green-200")}>
        <span className="resp-text--2 font-digi">{`${dec(h.speed, 2)}`}</span>
      </td>

      <td className={twMerge("text-green-200")}>
        <span className="resp-text--2 font-digi">{`${dec(h.speed_avg, 2)}`}</span>
      </td>
    </tr>
  );
};

const CoreTable = ({ searchdata: s, qosearch }) => {
  const {
    hs = [],
    vmap = {},
    headmap = {},
    rvmode,
  } = useMemo(() => s, [jstr(s)]);

  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    hid: { disp: "HID", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    element: {
      disp: "Element",
      fn: (i1) => sort_fn(i1, "element", "txt"),
    },
    type: { disp: "Type", fn: (i1) => sort_fn(i1, "type", "txt") },
    gender: { disp: "Gender", fn: (i1) => sort_fn(i1, "gender", "txt") },
    fno: { disp: "F.No", fn: (i1) => sort_fn(i1, "fno", "n") },
    races_n: { disp: "#Race", fn: (i1) => sort_fn(i1, "races_n", "n") },
    win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "win_p", "n") },
    profit: { disp: "Profit", fn: (i1) => sort_fn(i1, "profit", "n") },
    speed: { disp: "Max.Speed", fn: (i1) => sort_fn(i1, "speed", "n") },
    speed_avg: {
      disp: "Avg.Speed",
      fn: (i1) => sort_fn(i1, "speed_avg", "n"),
    },

    dna_price: { disp: "DNA Price", fn: (i1) => sort_fn(i1, "dna.0.amt", "n") },
    os_price: { disp: "OS Price", fn: (i1) => sort_fn(i1, "os.0.amt", "n") },
  };
  const sortargs = { sorts, set_sorts, sort_ob };

  const sorted_hs = useMemo(() => {
    console.log("sorted_hs:init", hs.length);
    if (_.isEmpty(hs)) return [];
    let ar = sort_list({
      list: hs,
      sorts,
      sort_ob,
      id: "hid",
    });
    console.log("sorted_hs:end", ar.length);
    return ar;
  }, [jstr(hs), jstr(sorts), jstr(sort_ob)]);

  return (
    <Card
      className={twMerge(
        "bg-r2lig/20 backdrop-blur-md fc-ss resp-gap-1 border border-acc4 max-w-[95vw] w-[65rem] mx-auto",
        "h-[60vh] overflow-auto relative",
      )}
    >
      <table className={twMerge(tablecn.table, "thintdrowp2-table-r2lig")}>
        <thead className="">
          <tr>
            <td>
              {/* <span className="resp-text--1">Name</span> */}
              <div class="fr-sc">
                <SortHead {...{ ...sortargs, k: "hid" }} />
                <SortHead {...{ ...sortargs, k: "name" }} />
              </div>
            </td>
            <td>
              {/* <span className="resp-text--1">Type</span> */}
              <SortHead {...{ ...sortargs, k: "type" }} />
            </td>
            <td>
              {/* <span className="resp-text--1">Element</span> */}
              <SortHead {...{ ...sortargs, k: "element" }} />
            </td>
            <td>
              {/* <span className="resp-text--1">Gender</span>  */}
              <SortHead {...{ ...sortargs, k: "gender" }} />
            </td>
            <td>
              {/* <span className="resp-text--1">F.No</span> */}
              <SortHead {...{ ...sortargs, k: "fno" }} />
            </td>
            <td>
              <div class="fc-cc">
                <SortHead {...{ ...sortargs, k: "races_n" }} />
                <span className="text-yellow-300 resp-text--3">
                  {headmap?.races_n}
                </span>
              </div>
            </td>
            <td>
              <div class="fc-cc">
                <SortHead {...{ ...sortargs, k: "win_p" }} />
                <span className="text-yellow-300 resp-text--3">
                  {headmap?.win_p}
                </span>
              </div>
            </td>
            <td>
              <div class="fc-cc">
                <SortHead {...{ ...sortargs, k: "profit" }} />
                <span className="text-yellow-300 resp-text--3">
                  {headmap?.profit}
                </span>
              </div>
            </td>
            <td>
              <div class="fc-cc">
                <SortHead {...{ ...sortargs, k: "speed" }} />
                <span className="text-yellow-300 resp-text--3">
                  {headmap?.speed}
                </span>
              </div>
            </td>

            <td>
              <div class="fc-cc">
                <SortHead {...{ ...sortargs, k: "speed_avg" }} />
                <span className="text-yellow-300 resp-text--3">
                  {headmap?.speed_avg}
                </span>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {sorted_hs.map((h) => {
            h.vault_name = vmap[h.vault];
            h.rvmode = rvmode;
            return <CoreTableRow {...{ h }} />;
          })}
        </tbody>
      </table>
    </Card>
  );
};

export const BikesPage = () => {
  const [basevalfilt, set_basevalfilt] = useState({});

  const [qosearch] = useQueries([
    q_bike_advsearch(
      {
        filt: basevalfilt,
      },
      {
        enabled: !nils(basevalfilt),
      },
    ),
  ]);

  const searchdata = useMemo(() => {
    return getv(qosearch, "data.result", { hs: [] });
  }, [qosearch.dataUpdatedAt]);

  const submit = (valfilt) => {
    console.log("submit:valfilt", valfilt);
    set_basevalfilt(valfilt);
  };

  return (
    <div className="page-wrapper">
      <div className="h-page">
        <div className="max-w-[98vw] w-[70rem] mx-auto">
          <div className="h-[5rem]">
            <div className="fc-cc">
              <Logo title2={"Cores"} />
              <div className="h-[2rem]"></div>
              <BikesSearch />
              <div className="h-1 my-[2rem] mx-auto max-w-[90vw] w-[30rem] bg-white/40 rounded-md" />
              <CoreFiltSection
                {...{
                  submit,
                  basefilt: {},
                  set_basevalfilt,
                }}
              />
              {qosearch.isLoading ? (
                <Loader01c />
              ) : qissuccesss(qosearch) ? (
                <>
                  {_.isEmpty(getv(qosearch, "data.result.hs")) ? (
                    <p className="text-center text-yellow-300">
                      No Bikes found
                    </p>
                  ) : (
                    <CoreTable {...{ searchdata, qosearch }} />
                  )}
                </>
              ) : qiserr(qosearch) ? (
                <p className="text-center text-red-400">{qiserr(qosearch)}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
