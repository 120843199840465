import React, { createContext, useContext, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { q_ranks, qiserr, qissuccesss } from "../queries/queries.js";
import {
  base64_to_json,
  dec,
  getv,
  json_to_base64,
  jstr,
  nils,
} from "../utils/utils.js";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
} from "../utils/filt.js";
import { InpAutoWrap, set_state_ob } from "../components/input.js";
import _ from "lodash";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { tokdecn, useAppContext } from "../App.js";
import { elementmap, gendermap, rvmode_s, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import { Card, Tag, TokenIcon } from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import { faFlag, faPlus, faUsd } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { active_cn_map, FiltCard_forLegacy } from "../utils/raceutils2.js";
import { RacingDashBackLink } from "./RacingDashboard.js";
import { RVImg } from "../components/BikeImg.js";

const RanksContext = createContext({});
const useRanksContext = () => useContext(RanksContext);

const FiltSection = () => {
  const rcon = useRanksContext();
  const { filt, set_filt, clear_filt } = rcon;

  const inpargs = {
    fkey: "open",
    filters: filt,
    set_filters: set_filt,
  };

  return (
    <>
      <div className="w-reg w-[98%] mx-auto max-w-[65rem] mb-[1rem]">
        <FiltCard_forLegacy
          {...{
            ...inpargs,
            order: "sort,zone,cb,element,gender,type".split(","),
            top_jsx: (
              <div class="fr-cc resp-gap-2">
                {rvmode_s.map((rvmode) => {
                  let path = `rvmode.vals`;
                  let active = _.includes(getv(filt, path), rvmode);
                  return (
                    <div
                      onClick={() => {
                        let nrvs = _.cloneDeep(getv(filt, path)) || null;
                        // if (active) nrvs = _.without(nrvs, rvmode);
                        // else nrvs.push(rvmode);
                        if (active) nrvs = null;
                        else nrvs = rvmode;
                        set_state_ob(filt, set_filt, path, nrvs);
                      }}
                      className={twMerge(
                        "cursor-pointer resp-p-2 h-full rounded-md",
                        active
                          ? "bg-r2dark/40 shadow-acc4 shadow-md"
                          : "bg-r2dark/40",
                      )}
                    >
                      <RVImg
                        {...{ rvmode }}
                        hex_code={active ? "09D3FF" : "FFFFFF"}
                        className={"lg:h-[4rem]"}
                      />
                    </div>
                  );
                })}
              </div>
            ),
            bottom_jsx: (
              <>
                <div className="fr-sc mt-2">
                  {" "}
                  <div className="flex-1"></div>
                  <InpAutoWrap {...{ ...inpargs, idd: "in_arena" }} />
                  <InpAutoWrap {...{ ...inpargs, idd: "is_listed" }} />
                </div>
              </>
            ),
          }}
        />
      </div>
      {/*
      <Card
        className={
          "bg-r2lig/20 backdrop-blur-md w-reg w-[98%] mx-auto max-w-[65rem] resp-px-4"
        }
      >
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "sort" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "zone" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
        </div>
        <div className="fr-sc">
          {" "}
          <div className="flex-1"></div>
          <InpAutoWrap {...{ ...inpargs, idd: "in_arena" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "is_listed" }} />
        </div>
        <div className="grid sm:grid-cols-2 md:sm:grid-cols-4"></div>
        <div className="fr-sc">
          <Tag
            onClick={clear_filt}
            className="resp-text--3 text-red-400 border border-red-400"
          >
            {"Clear Filters"}
          </Tag>
        </div>
      </Card>
      */}
    </>
  );
};

const RanksTable = () => {
  const rcon = useRanksContext();
  const { hs, valfilt = {}, show_ranks } = rcon;

  return (
    <Card className={"w-full bg-r2lig/20 backdrop-blur-md overflow-auto"}>
      <div className=" min-w-[20rem] w-full">
        <table className={twMerge(tablecn.table, "table-basic-border")}>
          <thead>
            <tr className={twMerge("thintdrow text-r2lig resp-text--1")}>
              {show_ranks !== false && <td>Rank</td>}
              <td>Bike</td>
              <td>Details</td>
              <td>
                {valfilt.sort == "win_p" && <>Win%</>}
                {valfilt.sort == "profit" && <>Profit</>}
                {valfilt.sort == "time_mi" && <>Time</>}
                {valfilt.sort == "speed_mx" && <>Speed</>}
              </td>
              <td>IN Arena</td>
              <td colSpan={2}>Cost</td>
            </tr>
          </thead>
          <tbody>
            {hs.map((h, i) => {
              let rank = i + 1;
              console.log(h.hid, h.in_stud);
              return (
                <tr key={h.hid} className={"thintdrow"}>
                  {show_ranks !== false && (
                    <td>
                      <span className="resp-text--1">#{rank}</span>
                    </td>
                  )}
                  <td>
                    <div className="fc-ss">
                      <Link
                        to={`/bike/${h.hid}?subtab=summary&tab=races`}
                        target="_blank"
                        className="w-full"
                      >
                        <div className="fr-sc">
                          <span className="resp-text--2 text-r2lig italic">
                            {h.hid}
                          </span>
                          <span className="resp-text--2 text-r2lig italic">
                            -
                          </span>
                          <span className="resp-text-0">{h.name}</span>
                        </div>
                      </Link>
                      <Link to={`/vault/${h.vault}`} target="_blank">
                        <div className="fr-sc text-r2lig">
                          <span className="resp-text--2">{h.vault_name}</span>
                        </div>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="grid xs:grid-cols-1 lg:grid-cols-2 ">
                      <Tag
                        className={twMerge(
                          "bg-r2dark/80 fr-cc resp-gap-1",
                          elementmap[h.element]?.text,
                        )}
                      >
                        <FontAwesomeIcon icon={elementmap[h.element]?.icon} />
                        <span>{_.upperCase(h.element)}</span>
                      </Tag>
                      <Tag
                        className={twMerge(
                          "bg-r2dark/80 fr-cc resp-gap-1",
                          gendermap[h.gender]?.text,
                        )}
                      >
                        <FontAwesomeIcon icon={gendermap[h.gender]?.icon} />
                        <span>{_.upperCase(h.gender)}</span>
                      </Tag>
                      <Tag className="bg-r2dark/80">{_.capitalize(h.type)}</Tag>
                      <Tag className="bg-r2dark/80 font-digi resp-text-0 italic text-center text-acc0">
                        F{h.fno}
                      </Tag>
                      <Tag className="bg-r2dark/80 resp-text-0 resp-gap-1 fr-cc">
                        <FontAwesomeIcon icon={faFlag} />
                        <span>{h.races_n}</span>
                      </Tag>
                    </div>
                  </td>
                  <td>
                    <div className=" w-full text-right font-digi resp-text-0 flex flex-row justify-end items-center">
                      {valfilt.sort == "win_p" && <>{dec(h.val * 100) + "%"}</>}
                      {valfilt.sort == "profit" && (
                        <div className="fc-cc">
                          <div className="fr-sc resp-gap-1 resp-text--1">
                            <FontAwesomeIcon icon={faUsd} />
                            <span className="resp-text-0">{dec(h.val, 2)}</span>
                          </div>
                          <div className="fr-sc resp-gap-1 resp-text--2">
                            <TokenIcon token={"WETH"} />
                            <span className="">{dec(h.profit, 2)}</span>
                            <FontAwesomeIcon icon={faPlus} className="px-2" />
                            <TokenIcon token={"DEZ"} />
                            <span className="">{dec(h.profit_dez, 0)}</span>
                          </div>
                        </div>
                      )}
                      {valfilt.sort == "time_mi" && (
                        <>{dec(h.val, 3) + "sec"}</>
                      )}
                      {valfilt.sort == "speed_mx" && (
                        <>{dec(h.val, 2) + "m/s"}</>
                      )}
                    </div>
                  </td>
                  <td>
                    {h.in_stud === true ? (
                      <p className="text-center text-green-400">IN</p>
                    ) : (
                      <p className="text-center text-red-400">--</p>
                    )}
                  </td>
                  <td>
                    {_.isEmpty(h.dna) ? (
                      "--"
                    ) : (
                      <Tag
                        redirect={`https://market.dnaracing.run/asset/core/${h.hid}`}
                        className={twMerge(
                          "fc-ss resp-gap-1",
                          "border border-acc0 text-acc0 ",
                        )}
                      >
                        <span>
                          {dec(
                            getv(h, "dna.amt"),
                            tokdecn(getv(h, "dna.token")),
                          )}
                        </span>
                        <span>{getv(h, "dna.token")}</span>
                      </Tag>
                    )}
                  </td>
                  <td>
                    {_.isEmpty(h.os) ? (
                      "--"
                    ) : (
                      <Tag
                        redirect={`https://opensea.io/assets/matic/0xcd0783c0e2b0a68a64ba7c5e0f99097945397cf3/${h.hid}`}
                        className={twMerge(
                          "fc-ss resp-gap-1",
                          "border border-purple-400 text-purple-400 ",
                        )}
                      >
                        <span>
                          {dec(getv(h, "os.amt"), tokdecn(getv(h, "os.token")))}
                        </span>
                        <span>{getv(h, "os.token")}</span>
                      </Tag>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

function Ranks() {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("open_races.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);

  const [filt, set_filt] = useState({
    sort: {
      type: "options-only",
      path: "sort",
      cfilter: true,
      options: ["win_p", "profit", "time_mi", "speed_mx"],
      vals: basefilt?.sort ?? null,
      txt_fn: (o) => {
        if (o == "win_p") return "Highest Win%";
        if (o == "profit") return "Best Earnings";
        if (o == "time_mi") return "Fastest Time";
        if (o == "speed_mx") return "Fastest Speed";
      },
      label: "Sort",
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.default,
    },
    cb: {
      type: "options-only",
      label: "Distance",
      path: "cb",
      cfilter: true,
      options: [
        "all",
        ..._.sortBy(
          [
            ...[9, 11, 13, 16, 19, 21, 23],
            ...[10, 12, 14, 18, 20, 22],
            ...[15, 17],
          ],
          (o) => o,
        ),
      ],
      filter_exception: [],
      vals: !nils(parseInt(basefilt?.cb)) ? parseInt(basefilt?.cb) : "all",
      txt_fn: (o) => {
        if (o == "all") return "All";
        return `CB${o}00`;
      },
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.default,
    },
    zone: {
      type: "options-only",
      path: "zone",
      cfilter: true,
      label: "Zone",
      show_label: false,
      options: ["overall", "paid"],
      filter_exception: [],
      vals: basefilt?.zone,
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.default,
    },
    element: {
      type: "options-only-ar",
      path: "element",
      cfilter: true,
      label: "Element",
      show_label: false,
      options: _.keys(elementmap),
      filter_exception: [],
      vals: _.compact(basefilt?.element),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.element,
    },
    gender: {
      type: "options-only-ar",
      path: "gender",
      cfilter: true,
      label: "Gender",
      show_label: false,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.gender,
    },
    type: {
      type: "options-only-ar",
      path: "type",
      cfilter: true,
      label: "Type",
      show_label: false,
      options: ["genesis", "morphed", "freak", "xclass"],
      filter_exception: [],
      vals: _.compact(basefilt?.type),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.default,
    },
    in_arena: {
      type: "switch",
      path: "gender",
      cfilter: false,
      label: "In Arena?",
      show_label: true,
      vals: basefilt?.in_arena ?? null,
      path: "in_arena",
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
    },
    is_listed: {
      type: "switch",
      path: "gender",
      cfilter: false,
      label: "Is Listed?",
      show_label: true,
      vals: basefilt?.is_listed ?? null,
      path: "is_listed",
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
    },
  });

  const valfilt = useMemo(() => {
    return gen_valob_from_filters(filt);
  }, [jstr(filt)]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };
  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt)]);

  const [q] = useQueries([q_ranks({ filter: valfilt })]);
  const hs = useMemo(() => {
    if (qissuccesss(q)) return getv(q, "data.result", []);
    else return [];
  }, [q.dataUpdatedAt]);

  const show_ranks = !(valfilt?.in_arean == true || valfilt?.is_listed == true);

  const rcon = {
    filt,
    set_filt,
    valfilt,
    clear_filt,
    q,
    hs,
    show_ranks,
  };

  return (
    <RanksContext.Provider value={rcon}>
      <Helmet>
        <title>{"Ranks"}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[2rem]"></div>

          <RacingDashBackLink />
          <p className="text-center font-digi resp-text-1">Ranks</p>
          <FiltSection />

          {q.isLoading ? (
            <Loader01c />
          ) : qiserr(q) ? (
            <p className="text-red-300 text-center">{qiserr(q)}</p>
          ) : qissuccesss(q) && _.isEmpty(hs) ? (
            <p className="text-center text-yellow-400">No Bikes</p>
          ) : qissuccesss(q) && !_.isEmpty(hs) ? (
            <>
              <RanksTable />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </RanksContext.Provider>
  );
}

export default Ranks;
