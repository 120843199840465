import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  createContext,
} from "react";
import { twMerge } from "tailwind-merge";
import { useAppContext, tokdecn } from "../../App";
import { PopUp, PopupCloseBtn } from "../../components/popup";
import { Card, InpText, Tag } from "../../components/utilityComps";
import {
  qissuccesss,
  q_hstats,
  useStepQuery,
  q_quest_create,
  polytxnlink,
  q_logtxnqueue,
  q_quest_mark_enter,
} from "../../queries/queries";
import {
  get_racestake_contract,
  get_racestake_key,
  RaceR2_HRow,
  season_cbs,
  SkewBtn,
  SkewWrap2,
  StatType_Switches,
} from "../../utils/raceutils2";
import {
  cdelay,
  dec,
  getv,
  iso,
  jstr,
  nils,
  toeth,
  tofeth,
} from "../../utils/utils";
import { useAccountContext } from "../../wrappers/AccountWrapper";
import _ from "lodash";
import { Loader01c } from "../../components/anims";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../../contracts/contract_funcs";
import { extract_inp } from "../../components/input";
import { useThirdWebLoginContext } from "../ThirdWebLogin";
import { useAuthContext } from "../../wrappers/AuthWrapper";
import { cb_txt } from "../../utils/cn_map";

const allowed_fees = [1, 2.5, 5, 10];
const valid_vaults = (vault) => {
  return [
    "0xaf1320faa9a484a4702ec16ffec18260cc42c3c2",
    "0xa0d9665e163f498082cd73048da17e7d69fd9224",
  ].includes(vault);
};

export const QuestSelect = ({
  custom_txt_fns = {},
  override_info = {},
  post_enterrace,
  blocked_options = [],
  block_url_update = false,
  init_stage = 0,
  card_cn = "bg-r2lig/20 backdrop-blur-md border border-acc4",
}) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const stage_opsmap = {
    0: "rvmode",
    1: "cb",
    2: "feeusd",
    3: "hid",
    4: "quest_type",
  };
  const aucon = useAuthContext();
  const { auth, aumode } = aucon;

  const { active_account, thirdweb_client } = useThirdWebLoginContext();

  const mxstage = 5;
  const get_prev_allowed_stage = (stage) => {
    if (stage <= init_stage) return init_stage;
    let mx = stage - 1;
    do {
      let is_blocked = blocked_options.includes(stage_opsmap[mx]);
      if (is_blocked) mx--;
      else break;
    } while (true);
    return mx;
  };
  const get_next_allowed_stage = (stage) => {
    if (stage >= mxstage) return mxstage;
    let nx = stage + 1;
    do {
      let is_blocked = blocked_options.includes(stage_opsmap[nx]);
      if (is_blocked) nx++;
      else break;
    } while (true);
    return nx;
  };

  const [stage, set_stage] = useState(init_stage);
  const [data, set_data] = useState({
    ...(!_.isEmpty(override_info) ? override_info : {}),
  });
  const rem = { stage };
  useEffect(() => {
    if (block_url_update) return;
    upd_psearch(rem);
  }, [jstr(rem)]);

  // useEffect(() => {
  //   console.log("stage", stage, data);
  // }, [stage, jstr(data)]);

  const go_prev = () => {
    set_stage(get_prev_allowed_stage(stage));
  };
  const go_next = () => {
    set_stage(get_next_allowed_stage(stage));
  };

  const headcn =
    "w-full my-[2rem] text-center font-digi resp-text-2 text-white";

  useEffect(() => {
    if (!nils(override_info?.k) && override_info.skipstages.includes(stage)) {
      go_next();
    }
  }, [stage, jstr(override_info)]);

  const validate_next = useMemo(() => {
    if (stage == 0) {
      if (nils(data.rvmode)) return false;
    } else if (stage == 1) {
      if (nils(data.cb)) return false;
    } else if (stage == 2) {
      if (nils(data.feeusd)) {
        return false;
      }
    }
    if (stage == 3) {
      if (nils(data.hid)) {
        if (override_info?.k == "trainer") {
          let hid = getv(override_info, "hid");
          let h = getv(override_info, "h");
          set_data((o) => ({ ...o, hid, h }));
          go_next();
        }
        return false;
      }
    }
    if (stage == 4) {
      if (nils(data.quest_type)) return false;
    }
    if (stage >= mxstage) return false;
    return true;
  }, [stage, jstr(data)]);

  const get_active_cn = (k, op) => {
    const val = getv(data, k);
    const selected = op == val;
    const cn = nils(val) || selected ? "opacity-100" : "opacity-50";

    const text_cn = selected ? "italic text-acc0" : "";
    return [cn, text_cn];
  };

  const payout_fac = 5.12;
  const gen_payout = (fee) => {
    const payout = parseFloat(getv(data, "feeusd")) * payout_fac;
    return dec(payout, 2);
  };

  const { hids, vault, bikesob } = useAccountContext();
  const qshs = useStepQuery({
    q_: q_hstats,
    par_ar: (hids || []).map((hid) => [
      { hid, cb: data.cb, rvmode: data.rvmode, class: 90 },
    ]),
    lim: 3,
    options: { enabled: !nils(data.cb) },
  });

  const [st_type, set_st_type] = useState("cb");
  const [st_paid, set_st_paid] = useState("paid");
  const [hs, hsob] = useMemo(() => {
    // console.log("hsstats:memo", hids, qshs);
    let hs = [];
    for (let q of qshs.qs) {
      if (!qissuccesss(q)) continue;
      let h = getv(q, "data.result");
      hs.push(h);
    }
    // console.log(r.rid, "hstats", hs);
    return [hs, _.keyBy(hs, "hid")];
  }, [jstr(_.map(qshs.qs, "dataUpdatedAt"))]);

  const [respdata, set_respdata] = useState({});
  useEffect(() => {
    console.log("respdata", respdata);
  }, [jstr(respdata)]);
  const pay_quest = async (r) => {
    try {
      console.log("pay_quest", vault, r);
      let { rid, version, paytoken, fee } = r;
      let rcon = await get_racestake_contract({ version });
      if (nils(rcon)) throw new Error("racestake contract not found");

      set_respdata({ status: "loading", msg: "Checking Balance..." });

      await cdelay(1 * 1e3);
      let paycon =
        aumode == "thirdweb"
          ? await t3_asset_signer(paytoken)
          : await mm_asset_signer(paytoken);

      let bal = await paycon.balanceOf(vault);
      bal = parseFloat(tofeth(bal));
      if (bal < fee) throw new Error("insufficient balance");
      await cdelay(1 * 1e3);

      set_respdata({ status: "loading", msg: "Checking Allowance..." });
      let alw = await paycon.allowance(vault, rcon.contractAddress);
      alw = parseFloat(tofeth(alw));
      if (alw < fee) {
        // throw new Error("insufficient allowance");
        set_respdata({ status: "info", msg: "insufficient allowance" });
        await cdelay(1 * 1e3);
        let amt = fee * 1.05;
        amt = parseFloat(dec(amt, tokdecn(paytoken)));
        console.log("allowance amt", amt);

        if (aumode == "thirdweb") {
          set_respdata({ status: "success", msg: `Approving Allowance...` });
          let resp = await t3_contract_call(
            paytoken,
            "approve",
            [rcon.contractAddress, toeth(amt)],
            "txn",
            true,
            { active_account },
          );
        } else {
          let resp = await paycon.approve(rcon.contractAddress, toeth(amt));
          set_respdata({ status: "success", msg: `Approving Allowance...` });
          resp = await resp.wait();
          set_respdata({ status: "success", msg: `ALlowance Approved` });
        }
      }
      await cdelay(1 * 1e3);

      set_respdata({ status: "loading", msg: "Entering Quest..." });
      let hid = getv(data, "hid");
      console.log("entering race", { rid, hid });
      let resp = null;
      if (aumode == "thirdweb") {
        let racekey = get_racestake_key({ version: r.version });
        console.log("racekey", racekey);
        resp = await t3_contract_call(
          racekey,
          "enterRace",
          [rid, hid],
          "txn",
          true,
          { active_account },
        );
      } else {
        resp = await rcon.enterRace(rid, hid);
        resp = await resp.wait();
      }

      if (post_enterrace)
        await post_enterrace({ rid, hid, quest_type: data.quest_type });

      let mark = await q_quest_mark_enter({
        rid,
        hid,
        vault,
      }).queryFn();
      console.log("mark enter", mark);
      set_respdata({ status: "success", msg: `Txn Sent` });

      await q_logtxnqueue({
        data: {
          type: "after_cont",
          rid,
          hid,
          vault,
          time: iso(),
          hash: resp.hash,
        },
      }).queryFn();
      window.open(`/race/${rid}`, "_self");
      // window.open(polytxnlink(resp.hash));
    } catch (err) {
      console.log(err);

      let msg = !nils(err.reason) ? err.reason : err.message;
      if (msg.length > 100) msg = msg.substring(0, 100) + "...";
      set_respdata({ status: "error", msg });

      setTimeout(() => {
        set_respdata({
          status: "action",
          msg: `Retry Paying Fee:${dec(r.fee, tokdecn[r.paytoken])} ${r.paytoken}`,
          action: () => pay_quest(r),
        });
      }, 4 * 1e3);
      return;
    }
  };
  const enterquest = async () => {
    try {
      let inp = {
        hid: getv(data, "hid"),
        cb: getv(data, "cb"),
        feeusd: getv(data, "feeusd"),
        quest_type: getv(data, "quest_type"),
        rvmode: getv(data, "rvmode"),
      };
      if (!nils(override_info?.k)) {
        inp.override_k = override_info.k;
      }
      set_respdata({ status: "loading", msg: "Creating Quest..." });
      let test = false;
      let resp = !test ? await q_quest_create(inp).queryFn() : {};
      console.log("enterquest resp", resp);
      let rid = getv(resp, "result.rid");
      if (nils(rid)) throw new Error("Quest not created");
      set_respdata({ status: "success", msg: `Quest Created` });
      await cdelay(2 * 1e3);
      let r = getv(resp, "result");

      if (r.need_topay === false) {
        let rid = getv(r, "rid");
        set_respdata({
          status: "success",
          msg: `taking you to race`,
        });
        await cdelay(2 * 1e3);
        window.open(`/race/${rid}`, "_self");
      } else {
        set_respdata({
          status: "success",
          msg: `need to pay fee: ${dec(r.fee, tokdecn[r.paytoken])} ${r.paytoken}`,
        });
        await cdelay(2 * 1e3);
        await pay_quest(r);
      }
    } catch (err) {
      let msg = !nils(err.reason) ? err.reason : err.message;
      if (msg.length > 100) msg = msg.substring(0, 100) + "...";
      set_respdata({ status: "error", msg });

      if (err.message == "Quest not created") {
        setTimeout(() => {
          set_respdata({
            status: "action",
            action: enterquest,
            msg: "Retry Entering Quest",
          });
        }, 2 * 1e3);
      }
    }
  };

  const selhids_searchtxt = extract_inp("autofiller-hid-search");
  const validhids = useMemo(() => {
    return _.map(hs, "hid");
  }, [jstr(hs)]);
  const filthids = useMemo(() => {
    let searchtxt = selhids_searchtxt;
    if (nils(searchtxt)) return validhids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(validhids)
      .map((hid) => {
        let h = bikesob[hid];
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(hids), jstr(validhids), selhids_searchtxt]);

  return (
    <div>
      <p className={headcn}>{"Enter a Quest"}</p>
      <Card className={twMerge("xs:w-[95vw] lg:w-[60rem]  p-4", card_cn)}>
        <div class="p-2 w-full h-[30rem] fc-sc">
          {/* <p class="resp-text-1">{jstr(data)}</p> */}
          <div class="w-full fr-sc gap-1">
            {!nils(data.rvmode) && (
              <>
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    Mode: {data.rvmode}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.cb) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {!nils(custom_txt_fns.cb)
                      ? custom_txt_fns.cb(data.cb, data.rvmode)
                      : cb_txt(data.cb, data.rvmode)}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.feeusd) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {dec(data.feeusd, 2)}
                  </span>
                  <span className="resp-text--1 font-digi">USD</span>
                </Tag>
              </>
            )}

            {!nils(data.hid) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>
                    {`Core: #${data.hid} ${getv(data, "h.name")}`}
                  </p>
                </Tag>
              </>
            )}

            {!nils(data.quest_type) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>{data.quest_type}</p>
                </Tag>
              </>
            )}
          </div>

          {stage == 0 ? (
            <>
              <p className={headcn}>Choose Racing Vehicle Mode</p>
              <div class="fr-cc w-full flex-wrap gap-4">
                {["car", "bike", "horse"].map((rvmode) => {
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, rvmode }));
                        go_next();
                      }}
                      class={twMerge("cursor-pointer xs:w-[8rem] lg:w-[16rem]")}
                    >
                      <SkewWrap2>
                        <span className={twMerge("resp-text-2 font-digi")}>
                          {_.capitalize(rvmode)}
                        </span>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </>
          ) : stage == 1 ? (
            <>
              <p className={headcn}>Choose a Distance</p>
              <div class="fr-cc w-full flex-wrap gap-4">
                {(data.rvmode == "bike" ? [11, 15, 19, 23] : season_cbs).map(
                  (cb) => {
                    const [btn_cn, text_cn] = get_active_cn("cb", cb);
                    return (
                      <div
                        onClick={() => {
                          set_data((o) => ({ ...o, cb }));
                          go_next();
                        }}
                        class={twMerge(
                          "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                          btn_cn,
                        )}
                      >
                        <SkewWrap2>
                          <span
                            className={twMerge(
                              "resp-text-2 font-digi",
                              text_cn,
                            )}
                          >
                            {!nils(custom_txt_fns.cb)
                              ? custom_txt_fns.cb(cb, data.rvmode)
                              : cb_txt(cb, data.rvmode)}
                          </span>
                        </SkewWrap2>
                      </div>
                    );
                  },
                )}
              </div>
            </>
          ) : stage == 2 ? (
            <>
              <p className={headcn}>Choose Fee Amt</p>
              <div class="grid grid-cols-2 gap-4">
                {allowed_fees.map((op) => {
                  const [btn_cn, text_cn] = get_active_cn("feeusd", op);
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, feeusd: op }));
                        go_next();
                      }}
                      class={twMerge(
                        "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                        btn_cn,
                      )}
                    >
                      <SkewWrap2>
                        <div
                          className={twMerge(
                            " fr-sc resp-text-2 font-digi",
                            text_cn,
                          )}
                        >
                          <FontAwesomeIcon icon={faUsd} />
                          <span>{dec(op, 2)}</span>
                        </div>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </>
          ) : stage == 5 ? (
            <>
              <div class="fc-cc h-full gap-2 font-digi resp-text-1">
                {override_info?.k == "trainer" ? (
                  <p className={" text-center"}>
                    Earn points in each round of your quest to climb the
                    tournament leaderboard.
                    <br />
                    Complete your quest by finishing
                    <span className="text-acc4">{` top 3 in all 3 rounds `}</span>
                    to earn
                    <span className="text-acc4">{` bonus points.`}</span>
                  </p>
                ) : (
                  <>
                    <p
                      className={
                        " resp-text-1 text-center mx-auto max-w-[30rem]"
                      }
                    >
                      {`If you complete this quest you will get `}
                      <span className="text-acc4">${gen_payout(data.fee)}</span>
                    </p>

                    {data.quest_type == "progressive" ? (
                      <div className="fc-cc mx-auto xs:w-full lg:w-[60%]">
                        <p class="">
                          <span className="text-acc4">Finish Top3</span>
                          {` to win each round`}
                        </p>
                        <p>this is a progressive quest</p>
                        <p>
                          <span>{`after each winning round you will have a `}</span>
                          <span className="text-acc4">
                            choice to cash out or continue on to win more!
                          </span>
                        </p>
                      </div>
                    ) : data.quest_type == "3-rounds" ? (
                      <p class="">
                        <span className="text-acc4">Finish Top3</span>
                        {` in 3 Rounds to complete the quest`}
                      </p>
                    ) : null}
                  </>
                )}
                {_.isEmpty(respdata) ? (
                  <Tag
                    onClick={() => {
                      enterquest();
                    }}
                    className={"bg-acc4/40 -skew-x-12"}
                  >
                    <p class="resp-text--1">Start Quest Now</p>
                  </Tag>
                ) : respdata.status == "action" ? (
                  <Tag
                    onClick={() => {
                      let action = respdata?.action;
                      if (action) action();
                    }}
                    className={"bg-orange-400/40 -skew-x-12"}
                  >
                    <p class="resp-text--1">{respdata?.msg}</p>
                  </Tag>
                ) : ["loading", "info", "success", "error"].includes(
                    respdata?.status,
                  ) ? (
                  <div
                    class={twMerge(
                      "fr-sc p-2 rounded-md border max-w-full w-[30rem] border",
                      respdata?.status == "error"
                        ? "border-red-400 text-red-400 "
                        : respdata?.status == "info" ||
                            respdata?.status == "loading"
                          ? "border-acc4 text-acc4 "
                          : respdata?.status == "success"
                            ? "border-green-400 text-green-400 "
                            : "",
                    )}
                  >
                    {respdata?.status == "loading" && <Loader01c size="s" />}
                    <p>{respdata?.msg}</p>
                  </div>
                ) : null}
              </div>
            </>
          ) : stage == 3 ? (
            <>
              <div class="h-full relative w-full overflow-auto">
                <div class="fr-sc h-[4rem] w-full gap-2">
                  <p className="font-digi resp-text-2 flex-1">
                    Select A {_.capitalize(data.rvmode)}
                  </p>

                  <StatType_Switches
                    {...{
                      st_type,
                      set_st_type,
                      st_paid,
                      set_st_paid,
                      cb: data.cb,
                    }}
                  />
                </div>

                <div class="my-2 xs:w-[95%] w-[90%] mx-auto">
                  <InpText
                    {...{
                      id: "autofiller-hid-search",
                      setter: () => {},
                      contprops: {
                        className: "w-full bg-r2dark",
                      },
                      inpprops: {
                        className: "w-full",
                      },
                      placeholder: "Search by coreid or Name",
                      autoComplete: "off",
                    }}
                  />
                </div>

                <div
                  style={{
                    height: "calc(100% - 4rem)",
                  }}
                  className="fc-ss overflow-auto  w-full"
                >
                  {filthids.map((hid) => {
                    let h = getv(hsob, hid);
                    if (nils(h)) return <></>;

                    // let { hid } = h;
                    // console.log(h);
                    if (h.type == "trainer") return <></>;
                    if (nils(h.name) || h.name === "Unnamed DNA Splice")
                      return <></>;

                    const selected = data?.hid == hid;

                    const entertbtn = (
                      <Tag
                        onClick={() => {
                          set_data((o) => ({ ...o, hid, h }));
                          setTimeout(() => {
                            go_next();
                          }, 0.5 * 1e3);
                        }}
                        className={twMerge(
                          "bike-enter-btn",
                          "resp-px-4 fr-cc resp-gap-2",
                          "bg-r2reg text-white",
                          selected ? "bg-r2dark text-acc4" : "",
                        )}
                      >
                        <span>
                          {selected
                            ? "Selected"
                            : `Select ${_.capitalize(data?.rvmode ?? "bike")}`}
                        </span>
                      </Tag>
                    );

                    return (
                      <div
                        class={twMerge(
                          "w-full",
                          selected
                            ? "bg-r2dark/40 shadow-sm shadow-acc4 border-r2lig"
                            : "",
                        )}
                      >
                        <RaceR2_HRow
                          {...{
                            r: {
                              rvmode: data.rvmode,
                            },
                            hid,
                            h,
                            st_type,
                            st_paid,
                            right_flex_jsx: entertbtn,
                            cont_props: {
                              className: "bg-tranparent resp-text--2",
                              style: {
                                borderLeft: undefined,
                                borderRight: undefined,
                              },
                            },
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : stage == 4 ? (
            <div class="h-full relative w-full overflow-auto">
              <div class="fr-cc h-[4rem] w-full gap-2">
                <p className="font-digi resp-text-2 text-center flex-1">
                  Select Quest Type
                </p>
              </div>
              <div class="fr-cc w-full gap-2">
                {[
                  "3-rounds",
                  ...(override_info?.k !== "trainer" ? ["progressive"] : []),
                ].map((op) => {
                  const k = "quest_type";
                  const [btn_cn, text_cn] = get_active_cn(k, op);
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, [k]: op }));
                        go_next();
                      }}
                      class={twMerge(
                        "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                        btn_cn,
                      )}
                    >
                      <SkewWrap2>
                        <span
                          className={twMerge("resp-text-2 font-digi", text_cn)}
                        >
                          {op}
                        </span>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        <div class="cursor-pointer fr-sc w-full gap-2">
          <div
            onClick={() => {
              go_prev();
            }}
            class="cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]"
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowLeftLong}
            />
          </div>

          <div class="flex-1"></div>
          {[...new Array(mxstage)].map((e, i) => {
            return (
              <div
                class={twMerge(
                  "xs:w-[0.5rem] lg:w-[1rem] aspect-[1/1] rounded-full bg-white",
                  i < stage ? "" : "opacity-20",
                )}
              ></div>
            );
          })}
          <div class="flex-1"></div>

          <div
            onClick={() => {
              if (!validate_next) return;
              go_next();
            }}
            class={twMerge(
              "cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]",
              validate_next ? "" : "opacity-0",
            )}
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowRightLong}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

const QuestSelectPage = () => {
  return (
    <div class="h-page">
      <div class="xs:h-[2rem] lg:h-[4rem]"></div>
      <QuestSelect />
      <div class="h-[4rem]"></div>
    </div>
  );
};

export default QuestSelectPage;
