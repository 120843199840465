import {
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { Loader01c } from "../../components/anims";
import { Tag } from "../../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_factions_agents_owns,
  q_factions_missions_agredo,
  q_factions_missions_open_agent,
  q_factions_missions_reroll,
  useStepQuery,
} from "../../queries/queries";
import { faction_img } from "../../utils/links";
import {
  cdelay,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
} from "../../utils/utils";
import { useAuthContext } from "../../wrappers/AuthWrapper";
import { useFactionContext } from "./Factions";
import { AgentCard_Assign, ScoreCard } from "./FactionsAgents";

const MissionCard = ({
  mission,
  a,
  idx,
  reroll_allowed = true,
  q_ms = null,
}) => {
  const [resp, set_resp] = useState({});
  const reroll_fn = async ({ agid, missid }) => {
    try {
      set_resp({ loading: true });
      let resp = await q_factions_missions_reroll({
        agid,
        missid,
      }).queryFn();

      if (nils(resp)) throw new Error("No response from server");
      if (!nils(resp?.err)) throw new Error(resp.err);

      await cdelay(1e3);
      let done = getv(resp, "result.done") ?? 0;
      if (done == 1)
        set_resp({
          loading: false,
          msg_type: "success",
          msg: "Reroll successful",
        });
      else
        set_resp({
          loading: false,
          msg_type: "error",
          msg: "Reroll failed",
        });
      await cdelay(1e3);
      if (q_ms) q_ms.refetch();
      setTimeout(() => set_resp({}), 3 * 1e3);
    } catch (e) {
      set_resp({
        loading: false,
        msg_type: "error",
        msg: e.message,
      });
      setTimeout(() => set_resp({}), 3 * 1e3);
    }
  };
  const m = mission;

  const e = useMemo(() => {
    if (nils(m)) return [null, null];
    let l = _.isEmpty(m.view_progress)
      ? [null, null]
      : m.view_progress[m.view_progress.length - 1];
    let e = getv(l, "end_calc") ?? {};
    return e;
  }, [jstr(m)]);

  return (
    <div class="w-full resp-text--1 my-2 border-b border-white ">
      <div class="fr-sc resp-gap-2 my-2">
        <p className="resp-text--1">Mission {idx} </p>
        <div class="flex-1"></div>
        <span className="uppercase">{m.difficulty}</span>

        <span className="text-acc4">
          <span className="font-digi resp-text--1">{m.points}</span>
          Pts
        </span>
      </div>

      <div class="fr-sc resp-gap-2 my-2">
        <div class="fc-ss resp-gap-1">
          <p className="resp-text-0 font-digi text-acc4">{m.title} </p>
          {!nils(m.desc) && (
            <p className="resp-text--2 ">Description: {m.desc} </p>
          )}
        </div>
        <div class="flex-1"></div>
        {(m.progress == m.repeat_count || m.status == "completed") && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="resp-text-2 text-green-400"
          />
        )}
        <span>
          <span className="text-acc4 font-digi resp-text-1">
            {m.progress}/{m.repeat_count}
          </span>
        </span>
      </div>

      <div class="fr-sc my-2 resp-gap-2">
        <div class="flex-1"></div>
        {!_.isEmpty(m.view_progress) ? (
          <>
            {_.filter(m.view_progress, (v) => v.type == "bar").map((v, idx) => {
              return (
                <span className="resp-text--2 text-acc4">{`${v.curr}m / ${v.fin}m`}</span>
              );
            })}

            {e.needsconsec === true ? (
              <>
                <span className="text-acc0 resp-text--2">{` Consec ${e.last_match} / ${e.sumn}`}</span>
                {_.range(0, e.sumn).map((a, idx) => {
                  return (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={twMerge(
                        "resp-text--2 ",
                        a < e.last_match ? "text-green-400" : "text-acc4/20",
                      )}
                    />
                  );
                })}
              </>
            ) : (
              _.filter(m.view_progress, (v) => v.type == "bubble").map(
                (v, idx) => {
                  return (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={twMerge(
                        "resp-text--2 ",
                        v.perc == 1 ? "text-green-400" : "text-acc4/20",
                      )}
                    />
                  );
                },
              )
            )}
          </>
        ) : null}
      </div>

      <div class="fr-sc resp-gap-1">
        {m.status == "expired" && <span class="text-red-400">EXPIRED</span>}
        {m.status == "completed" && (
          <span class="text-green-400">COMPLETED</span>
        )}
        {m.status == "open" && (
          <>
            {m.rerolled == true && <span class="text-red-400">REROLLED</span>}
            {_.isEmpty(resp) ? (
              <>
                {reroll_allowed && (
                  <Tag
                    onClick={() =>
                      reroll_fn({ agid: a.agid, missid: m.missid })
                    }
                    className={twMerge("-skew-x-12 bg-acc4/40")}
                  >
                    Reroll
                  </Tag>
                )}
              </>
            ) : (
              <>
                {resp.loading && <Loader01c size="s" />}
                <span
                  class={twMerge(
                    "px-1",
                    resp.msg_type == "error" ? "text-red-400" : "text-acc4",
                  )}
                >
                  {resp.msg}
                </span>
              </>
            )}

            <div class="flex-1"></div>
            <span className="resp-text--3">
              Ends in {from_time_mini(m.ends)}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export const Side_AgentMissionsCard = ({
  a,
  qa,
  qm,
  reroll_allowed,
  o_agredo,
  request_agredo,
  scoresec,
  missions,
  today,
  set_day_st,
  day_st,
}) => {
  const open_missions = useMemo(() => {
    return _.filter(missions, (m) => m.status == "open");
  }, [jstr(missions)]);

  if (_.isEmpty(open_missions)) return <></>;

  return (
    <>
      <div class="fr-sc">
        <div class="h-[10rem] aspect-[1200/2400]">
          <img className="" src={getv(a, "img")} />
        </div>

        <div class="fc-ss resp-text--1 p-2">
          <div class=" font-digi my-2">{a.name}</div>
          <span>#{getv(a.agid)}</span>
          <span className="text-acc4 uppercase">{getv(a, "faction")}</span>
        </div>

        {scoresec}
      </div>

      <hr />
      {nils(qm) ? null : qm.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(qm) ? (
        <p class="text-red-400">{qiserr(qm)}</p>
      ) : qissuccesss(qm) ? (
        <div class="w-full resp-p-2">
          {(open_missions || []).map((m, idx) => {
            return (
              <MissionCard
                {...{
                  idx: idx + 1,
                  key: m.misid,
                  mission: m,
                  a,
                  q_ms: qm,
                  reroll_allowed,
                }}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export const Base_AgentMissionsCard = ({
  a,
  qa,
  qm,
  reroll_allowed,
  o_agredo,
  request_agredo,
  scoresec,
  missions,
  today,
  set_day_st,
  day_st,
}) => {
  return (
    <div class="grid grid-cols-6 resp-gap-2">
      <div class="col-span-3 grid grid-cols-3">
        <div class="col-span-1 aspect-[1200/2400]">
          <img className="" src={getv(a, "img")} />
        </div>
        <div class="col-span-2 h-full">
          <div class="resp-text-1 w-full text-center font-digi my-2">
            {a.name}
          </div>
          <div class="p-2 rounded-md card-dark-bg my-2 border border-acc4">
            <div class="fr-sc resp-gap-1 my-2">
              <span>ID:</span>
              <span>{getv(a.agid)}</span>
            </div>

            <div class="fr-sc resp-gap-1 my-2">
              <span>Faction:</span>
              <span className="text-acc4 uppercase">{getv(a, "faction")}</span>
            </div>
          </div>

          <div class="p-2 rounded-md card-dark-bg my-2 border border-acc4">
            <span>Assigned Core</span>
            <div class="fr-sc resp-gap-1">
              {nils(a.ashid) ? (
                <span className="text-red-400">--</span>
              ) : (
                <span className="text-acc4">
                  #{a.ashid} - {getv(a, "assigned.name")}
                </span>
              )}
            </div>
            <AgentCard_Assign
              {...{
                a,
                q: qa,
              }}
            />
          </div>

          <div class="xs:hidden lg:block">{scoresec}</div>
        </div>
        <div class="col-span-3 xs:block lg:hidden">{scoresec}</div>
      </div>
      <div class="col-span-3">
        <div class="fr-sc">
          <p class="text-right resp-text-1 font-digi">Missions</p>

          {_.isEmpty(o_agredo) ? (
            <Tag
              onClick={() => {
                request_agredo();
              }}
            >
              <FontAwesomeIcon icon={faRefresh} className="text-acc4" />
            </Tag>
          ) : o_agredo.loading ? (
            <Tag
              onClick={() => {
                request_agredo();
              }}
            >
              <FontAwesomeIcon
                icon={faRefresh}
                className="text-acc4 spin-anim"
              />
            </Tag>
          ) : o_agredo.done == 1 ? (
            <Tag className="text-green-400 fr-sc resp-gap-2 resp-text--1">
              <FontAwesomeIcon icon={faCheck} className="text-" />
              <span>Updating in a bit</span>
            </Tag>
          ) : null}

          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              let ndate = moment
                .utc(day_st ?? today)
                .subtract(1, "day")
                .toISOString();
              set_day_st(ndate);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-acc4" />
          </Tag>
          <span className="font-digi resp-text--1 w-[8rem] text-center">
            {!nils(day_st) ? iso_format(day_st, "DD MMM 'YY") : "Today"}
          </span>

          <Tag
            onClick={() => {
              let ndate = moment
                .utc(day_st ?? today)
                .add(1, "day")
                .toISOString();
              if (
                ndate > iso() ||
                ndate == moment.utc().startOf("day").toISOString()
              )
                return set_day_st(null);
              else set_day_st(ndate);
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-acc4" />
          </Tag>
        </div>
        {nils(qm) ? null : qm.isLoading ? (
          <Loader01c size="s" />
        ) : qiserr(qm) ? (
          <p class="text-red-400">{qiserr(qm)}</p>
        ) : qissuccesss(qm) ? (
          <div class="w-full resp-p-2">
            {missions.map((m, idx) => {
              return (
                <MissionCard
                  {...{
                    idx: idx + 1,
                    key: m.misid,
                    mission: m,
                    a,
                    q_ms: qm,
                    reroll_allowed,
                  }}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const AgentMissionsCard = ({ a, qa, qm: dayqm, side_view }) => {
  const aucon = useAuthContext();
  const [day_st, set_day_st] = useState(null);
  const today = moment.utc().startOf("day").toISOString();

  const [historicqm] = useQueries([
    q_factions_missions_open_agent(
      { agid: a.agid, day_st },
      {
        enabled: !nils(a.agid) && !nils(day_st),
        staleTime: 60 * 1e3,
        refetchInterval: 60 * 1e3,
      },
    ),
  ]);

  const qm = day_st == null || day_st == today ? dayqm : historicqm;
  const missions = useMemo(() => {
    return getv(qm, "data.result") || [];
  }, [day_st, jstr(qm), qm.dataUpdatedAt, historicqm.dataUpdatedAt]);

  const scoresec = (
    <div class="w-full p-2">
      <ScoreCard a={a} k="daily" txt="Daily" />
      <ScoreCard a={a} k="weekly" txt="Weekly" show_date={true} />
      <ScoreCard a={a} k="season" txt="Season" />
    </div>
  );
  const reroll_allowed = useMemo(() => {
    if (aucon.auth !== true) return false, aucon.aute;
    let n = (_.filter(missions, (m) => m.rerolled === true) || []).length;
    // console.log("reroll_allowed", a?.agid, "n", n);
    return !(n >= 1);
  }, [jstr(a), jstr(missions), aucon.auth]);

  const [o_agredo, set_o_agredo] = useState({});
  const request_agredo = async () => {
    try {
      set_o_agredo({ loading: true });
      let resp = await q_factions_missions_agredo({ agid: a.agid }).queryFn();
      console.log("request_agredo", resp);
      await cdelay(5 * 1e3);
      set_o_agredo({ loading: false, done: 1 });
      setTimeout(() => {
        set_o_agredo({});
      }, 3 * 1e3);
      setTimeout(() => {
        dayqm.refetch();
      }, 10 * 1e3);
    } catch (e) {
      console.log("request_agredo:err", e);
    }
  };

  const comp_props = {
    a,
    qa,
    qm,
    reroll_allowed,
    o_agredo,
    request_agredo,
    scoresec,
    missions,
    today,
    set_day_st,
    day_st,
  };

  return (
    <div
      class={twMerge(
        "my-2",
        "border-b border-acc4",
        "p-2 resp-text--1 rounded-md bg-r2lig/20 backdrop-blur-md border border-acc4",
      )}
    >
      {side_view == true ? (
        <Side_AgentMissionsCard {...comp_props} />
      ) : (
        <Base_AgentMissionsCard {...comp_props} />
      )}
    </div>
  );
};

export const FactionMissions = () => {
  const fcon = useFactionContext();
  const { agids, agentsob, vault, qsagmis, missionsob } = fcon;

  return (
    <div class="">
      {agids.map((agid) => {
        const a = agentsob[agid];
        const m = missionsob[agid]?.missions;
        const qa = getv(a, "q");
        const qm = getv(missionsob[agid], "q");

        if (nils(a)) return;
        return (
          <AgentMissionsCard
            {...{
              key: agid,
              a,
              missions: m,
              qa,
              qm,
            }}
          />
        );
      })}
    </div>
  );
};
