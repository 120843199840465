const res = "https://cdn.dnaracing.run/trails-resources";

// bolt.png
// fire.png
// glass_shards_1.png
// glass_shards_2.png
// glass_shards_3.png
// glass_shards_4.png
// grad-particle.png
// pinkdust.png
//
export const Trails_ResourceMap = {
  fire: `${res}/fire.png`,
  glass_shards_1: `${res}/glass_shards_1.png`,
  glass_shards_2: `${res}/glass_shards_2.png`,
  glass_shards_3: `${res}/glass_shards_3.png`,
  glass_shards_4: `${res}/glass_shards_4.png`,
  grad_particle: `${res}/grad-particle.png`,
  pinkdust: `${res}/pinkdust.png`,
  bolt: `${res}/bolt.png`,
  dirt: `${res}/dirt.jpg`,
};

const prew = "https://cdn.dnaracing.run/trails-preview";

// "crystal_red",
// "crystal_cyan",
// "crystal_green",
// "crystal_yellow",
// "crystal_rainbow1",
// "crystal_rainbow2",
//
// "glass_red",
// "glass_cyan",
// "glass_green",
// "glass_yellow",
// "glass_rainbow1",
// "glass_rainbow2",
//
// "fire2",
// "smoke",
// "smoke_rainbow1",
// "bolts",

export const Trails_PreviewMap = {
  car_dirt: [`Car Dirt Trail`, `${prew}/smoke.jpg`, "car"],

  // electric: [`Electric`, `${prew}/electric.png`, "bike"],
  electric_red: [`Electric Red`, `${prew}/electric_red.png`, "bike"],
  electric_cyan: [`Electric Cyan`, `${prew}/electric_cyan.png`, "bike"],
  electric_green: [`Electric Green`, `${prew}/electric_green.png`, "bike"],
  electric_yellow: [`Electric Yellow`, `${prew}/electric_yellow.png`, "bike"],
  electric_rainbow: [
    `electric Rainbow`,
    `${prew}/electric_rainbow.png`,
    "bike",
  ],

  crystal_red: [`Crystal Red`, `${prew}/crystal_red.jpg`, "bike"],
  crystal_cyan: [`Crystal Cyan`, `${prew}/crystal_cyan.jpg`, "bike"],
  crystal_green: [`Crystal Green`, `${prew}/crystal_green.jpg`, "bike"],
  crystal_yellow: [`Crystal Yellow`, `${prew}/crystal_yellow.jpg`, "bike"],
  crystal_rainbow1: [
    `Crystal Rainbow1`,
    `${prew}/crystal_rainbow1.jpg`,
    "bike",
  ],
  crystal_rainbow2: [
    `Crystal Rainbow2`,
    `${prew}/crystal_rainbow2.jpg`,
    "bike",
  ],

  glass_red: [`Glass Red`, `${prew}/glass_red.jpg`, "bike"],
  glass_cyan: [`Glass Cyan`, `${prew}/glass_cyan.jpg`, "bike"],
  glass_green: [`Glass Green`, `${prew}/glass_green.jpg`, "bike"],
  glass_yellow: [`Glass Yellow`, `${prew}/glass_yellow.jpg`, "bike"],
  glass_rainbow1: [`Glass Rainbow1`, `${prew}/glass_rainbow1.jpg`, "bike"],
  glass_rainbow2: [`Glass Rainbow2`, `${prew}/glass_rainbow2.jpg`, "bike"],

  fire2: [`Fire`, `${prew}/fire2.jpg`, "bike"],
  smoke: [`Smoke`, `${prew}/smoke.jpg`, "bike"],
  smoke_rainbow1: [`Smoke Rainbow1`, `${prew}/smoke_rainbow1.jpg`, "bike"],
  bolts: [`Bolts`, `${prew}/bolts.jpg`, "bike"],
};
