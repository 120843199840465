import { createContext, useContext, useEffect, useMemo } from "react";
import { tokdecn2, useAppContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { dec, getv, iso_format, nils } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  faChevronDown,
  faCircle,
  faLink,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  polytxnlink,
  q_ledger,
  qiserr,
  qissuccess,
} from "../queries/queries.js";
import { Card, Img, Tag, TokenIcon } from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { class_cn, class_text, tablecn } from "../utils/cn_map.js";
import _, { reduce } from "lodash";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { Helmet } from "react-helmet-async";
import { ErrorBoundary } from "../utils/errbou.js";
import { polychainimg, prizebox_img, wethimg } from "../utils/links.js";
import { BY_Star, ColorCircle, PosTag } from "../utils/raceutils.js";
import { BikeImg } from "../components/BikeImg.js";

const LedgerContext = createContext();
export const useLedgerContext = () => useContext(LedgerContext);

const LedgerRow = ({ tx }) => {
  const lcon = useLedgerContext();
  const { rs_map = {}, hs_map = {}, rhs_map = {} } = lcon;

  const [expand, set_expand] = useState(false);

  const date = useMemo(() => {
    if (moment().diff(tx.date, "seconds") < 5 * 60)
      return moment(tx.date).fromNow();
    else return iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  }, [tx]);

  const tdcn = twMerge(tablecn.td, expand ? "border-transparent" : "");

  const rinf = useMemo(() => {
    if (tx.service !== "races") return {};
    let { rid, hid, rhid } = tx.connects;
    const r = rs_map[rid] || {};
    const h = hs_map[hid] || {};
    const rh = rhs_map[rhid] || {};
    return { r, h, rh };
  }, [tx.service]);

  const redirect =
    (tx.service == "races" && `/race/${tx.connects.rid}`) || null;

  const rowcn = "";
  return (
    <>
      <tr className={twMerge(rowcn, "resp-text--1")}>
        <td className={tdcn}>
          <div className="fr-sc resp-gap-1">
            <Tag
              redirect={polytxnlink(tx.hash)}
              className="xs:w-[1.2rem] lg:w-[2.5rem]"
            >
              <Img img={polychainimg} />
            </Tag>
            {!nils(getv(tx, "pwin_txid")) && (
              <>
                <Tag
                  redirect={polytxnidlink(getv(tx, "pwin_txid"))}
                  className="xs:w-[1rem] lg:w-[3rem]"
                >
                  <Img img={prizebox_img} />
                </Tag>
              </>
            )}
          </div>
        </td>
        <td
          onClick={() => {
            if (redirect) window.open(redirect);
          }}
          className={twMerge(tdcn, redirect ? "cursor-pointer" : "")}
        >
          <div className="fr-sc resp-gap-2">
            {redirect && (
              <span className="resp-text--1">
                <FontAwesomeIcon className="text-slate-400" icon={faLink} />
              </span>
            )}
            {/* <span>{tx.service}</span> */}
            <span className="resp-text--1">{tx.type}</span>
          </div>
        </td>
        <td className={tdcn}>
          <Tag className="text-center w-[2rem]">
            {(["race_payout", "race_refund"].includes(tx.type) && (
              <span className={twMerge("text-green-400")}>IN</span>
            )) ||
              (["race_entry"].includes(tx.type) && (
                <span className={twMerge("text-red-400")}>OUT</span>
              )) || <span></span>}
          </Tag>
        </td>
        <td className={twMerge(tdcn)}>
          <div className="fr-sc resp-gap-1">
            <div className="flex-1"></div>
            {getv(tx, "connects.token") == "DEZ" ? (
              <>
                <TokenIcon token={"DEZ"} size="xs" />
                <span>{dec(getv(tx, "connects.amt"), 0)}</span>
              </>
            ) : (
              <>
                <TokenIcon token={"WETH"} size="xs" />
                <span>{dec(getv(tx, "connects.amt"), 6)}</span>
              </>
            )}
          </div>
        </td>
        <td className={tdcn}>{date}</td>
        <td>
          <Tag
            onClick={() => {
              set_expand(!expand);
            }}
            className={twMerge(
              "transition-all duration-500 ease-in-out",
              expand ? "rotate-180" : "",
            )}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </Tag>
        </td>
      </tr>
      {expand && (
        <tr className={twMerge("border-b border-acc0/40", rowcn)}>
          {tx.service == "races" && (
            <td
              colSpan={6}
              className="bg-r2dark/80 backdrop-blur-md resp-text--2"
            >
              <div className="fr-sc resp-gap-2 resp-p-2 w-full">
                <Tag className="text-acc0 border border-acc0">Race</Tag>
                <Tag redirect={redirect} className="font-reg">
                  {" "}
                  {rinf.r.race_name} - ({tx.connects.rid})
                </Tag>
                <div className="flex-1"></div>
                <span className="font-digi">CB{rinf.r.cb}00</span>
                <span
                  className={twMerge(
                    "font-digi resp-p-2 rounded-md",
                    `text-r2lig`,
                  )}
                >
                  {class_text(rinf.r.class, "t")}
                </span>
                <Tag className="font-reg">
                  <span>Status:</span>
                  <span
                    className={twMerge(
                      rinf.r.status == "finished"
                        ? "text-green-400"
                        : "text-yellow-400",
                    )}
                  >
                    {_.capitalize(rinf.r.status)}
                  </span>
                </Tag>
              </div>
              <div className="fr-sc resp-gap-2 resp-p-2 w-full">
                {rinf.r.status == "finished" && (
                  <>
                    <span>Pos:</span>
                    <PosTag pos={rinf.rh.pos} />
                  </>
                )}
                <div class="xs:w-[1rem] lg:w-[2rem]">
                  <BikeImg hex_code={getv(rinf, "h.hex_code")} />
                </div>
                <span className="italic resp-pr-2">{rinf.h.name}</span>
                <span>
                  <BY_Star star={rinf.rh.star} />
                </span>
                <div className="flex-1"></div>
                {rinf.r.status == "finished" && (
                  <>
                    <span className="text-green-400 font-digi">Time:</span>
                    <span className="text-green-400 font-digi">
                      {dec(rinf.rh.time, 3)}
                    </span>
                  </>
                )}
              </div>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export const LedgerInner = ({ vault }) => {
  const [page, set_page] = useState(1);
  const [limit, set_limit] = useState(10);

  const [qo_ledger] = useQueries([
    q_ledger({ vault, page, limit }, { staleTime: 2 * 60 }),
  ]);
  const { txns, rs_map, hs_map, rhs_map } = useMemo(() => {
    if (!qissuccess(qo_ledger))
      return {
        txns: [],
      };
    return getv(qo_ledger, "data.result");
  }, [qo_ledger.dataUpdatedAt]);

  const prevpage = () => set_page(Math.min(page, 1));
  const nextpage = () => set_page(page + 1);

  const lcon = {
    txns,
    qo_ledger,
    rs_map,
    hs_map,
    rhs_map,

    page,
    set_page,
    limit,
    set_limit,
  };

  return (
    <div className="w-full">
      <LedgerContext.Provider value={lcon}>
        <div className="">
          <p className="font-digi resp-text-2 text-center text-white my-2">
            Ledger
          </p>
          <Card
            className={
              twMerge(
                "bg-r2lig/20 backdrop-blur-md max-w-[95vw] w-full overflow-auto",
              )
              // "bg-r2lig/20 backdrop-blur-md border border-acc4 w-[60rem] max-w-[90vw] ",
              // "xs:resp-px-0 xs:resp-y-0",
              // "lg:resp-px-0 lg:resp-y-0",
            }
          >
            <div className="fr-cc resp-p-2 rounded-md bg-r2dark/40 resp-gap-2">
              {[["prev", prevpage, faAngleLeft]].map(([k, fn, icon]) => {
                return (
                  <Tag
                    onClick={fn}
                    className={twMerge("resp-text-2 text-white")}
                  >
                    <FontAwesomeIcon icon={icon} />{" "}
                  </Tag>
                );
              })}
              <span className="flex-1 text-center resp-px-2 resp-text-1">
                Page - {page}
              </span>
              {[["next", nextpage, faAngleRight]].map(([k, fn, icon]) => {
                return (
                  <Tag
                    onClick={fn}
                    className={twMerge("resp-text-2 text-white")}
                  >
                    <FontAwesomeIcon icon={icon} />{" "}
                  </Tag>
                );
              })}
            </div>
            {qo_ledger.isLoading ? (
              <Loader01c />
            ) : qiserr(qo_ledger) ? (
              <p className="text-center text-red-400 resp-text-1">
                {qiserr(qo_ledger) ?? "Err"}
              </p>
            ) : _.isEmpty(txns) ? (
              <p className="text-center text-yellow-400 resp-text-1">
                {"No Transactions"}
              </p>
            ) : (
              <>
                <table
                  className={twMerge(
                    tablecn.table,
                    "w-full table-basic-border",
                  )}
                >
                  <tbody>
                    {txns.map((tx) => {
                      // return <></>;
                      return <LedgerRow {...{ tx, key: tx.txn_id }} />;
                    })}
                  </tbody>
                </table>
              </>
            )}
          </Card>
        </div>
      </LedgerContext.Provider>
    </div>
  );
};

const Ledger = () => {
  const { vault } = useAccountContext();

  const pagetitle = useMemo(() => {
    return `Ledger`;
  }, []);

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page relative">
        <LedgerInner vault={vault} />
      </div>
    </>
  );
};
export default Ledger;

export const AccountBalanceTag = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;

  const acc = useAccountContext() || {};
  const { s_acc_config, g_acc_config = () => {} } = acc;

  const baleth = useMemo(() => {
    return acc?.balance;
  }, [acc]);
  const baldez = useMemo(() => {
    return acc?.dezbalance;
  }, [acc]);

  const viewbalance = g_acc_config("viewbalance", true) ?? false;
  const balmap = {
    WETH: baleth,
    DEZ: baldez,
  };

  return (
    <div
      onClick={() => {
        s_acc_config("viewbalance", !viewbalance);
      }}
      className="cursor-pointer rounded-lg border border-acc0 my-1"
    >
      <div class="fc-ss">
        {["WETH", "DEZ"].map((tk, i) => {
          let usdval = tok_to_usd_val(balmap[tk], tk);
          return (
            <div
              class={twMerge(
                "fr-sc resp-text--2 resp-gap-1 px-2",
                i !== 0 ? "border-t border-acc0" : "",
              )}
            >
              <TokenIcon token={tk} size="xs" />
              {!viewbalance ? (
                <span>****</span>
              ) : (
                <span>{dec(balmap[tk], tokdecn2(tk))}</span>
              )}
              <div class="xs:w-[3.5rem] lg:w-[7rem] text-right">
                {!viewbalance ? (
                  <span>****</span>
                ) : (
                  <span>${dec(usdval, tokdecn2("USD"))}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
