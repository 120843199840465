const abi = require("./abi.json");
const ethers = require("ethers");
const _ = require("lodash");
const { contractAddress_list } = require("../constants.js");
const { nils, toeth, dec, tokdecn } = require("../../utils/utils.js");
const { getgasinfo_m } = require("../contract_funcs.js");

const getgasinfo = () => ({});

const name = "splicingv02";

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  // function request_splice( address tovault, uint fid, uint mid, string memory encoded) public {
  async request_splice(tovault, fid, mid, encoded, info) {
    // const gasinfo = await getgasinfo_m(4);
    const gasinfo = {};
    let ext = { ...gasinfo, ...info };
    let tx = await this.contract.request_splice(
      tovault,
      fid,
      mid,
      encoded,
      ext,
    );
    console.log(`>requestSplice`, tx.hash);
    let resp = await tx.wait();
    return resp;
  }

  convert_token_amts(pay_amts, pay_tokens) {
    for (let i = 0; i < pay_amts.length; i++) {
      if (["DEZ", "BGC"].includes(pay_tokens[i])) {
        pay_amts[i] = toeth(
          parseFloat(dec(pay_amts[i], tokdecn(pay_tokens[i]))),
        );
      }
    }
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event SpliceRequested( string reqid, uint fid, uint mid, address payingvault, address tovault, string encoded);
      // event SplicePayment( address pay_from, address pay_to, uint pay_amt, string pay_token);
      // event SpliceCreated(string reqid, address tovault, uint hid);

      if (name == "SpliceRequested") {
        let a = par.args;
        o.args = {
          reqid: a[0],
          fid: Number(a[1]),
          mid: Number(a[2]),
          payingvault: a[3].toLowerCase(),
          tovault: a[4].toLowerCase(),
          encoded: a[5],
        };
      } else if (name == "SpliceCreated") {
        let a = par.args;
        o.args = {
          reqid: a[0],
          tovault: a[1].toLowerCase(),
          hid: Number(a[2]),
        };
      } else if (name == "SplicePayment") {
        let a = par.args;
        o.args = {
          pay_from: a[0].toLowerCase(),
          pay_to: a[1].toLowerCase(),
          pay_amt: Number(a[2]),
          pay_token: a[3],
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  async getParsedLogsByTxHash(hash) {
    const provider = this.provider;
    const receipt = await provider.getTransactionReceipt(hash);
    if (!receipt) {
      console.log("Transaction not found or still pending");
      return [];
    }
    return this.getParsedLogs(receipt.logs);
  }
}

const chain_mode = "main";
const k = "splicingv02";

const get_contract_address = () => {
  const contractAddress = contractAddress_list[name];
  return contractAddress;
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

const Splicing_V02 = {
  k,
  get_contract,
  get_contract_address,
};
module.exports = Splicing_V02;
