import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import { Mo } from "../utils/motion_helper.js";
import { twMerge } from "tailwind-merge";
import { useEffect, useState } from "react";
import { Tag } from "./utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const PopUp = ({
  onOpen,
  onClose,
  openstate = false,
  overlayclose = true,
  ...props
}) => {
  const [show, set_show] = useState(openstate);
  useEffect(() => {
    set_show(openstate);
  }, [openstate]);

  const open = () => {
    if (set_show) set_show(true);
    if (onOpen) onOpen();
  };
  const close = () => {
    if (set_show) set_show(false);
    if (onClose) onClose();
  };
  if (!show) return;

  return ReactDOM.createPortal(
    <div className="text-white">
      <motion.div
        {...Mo.slide({})}
        onClick={() => {
          if (overlayclose) close();
        }}
        className={twMerge(
          "fixed top-0 left-0 overlay z-[1995] w-[100vw] h-[100vh] backdrop-overlay",
          props.custom_z,
        )}
      ></motion.div>
      <motion.div
        {...Mo.slide({ y: [200, 0, -200] })}
        className={twMerge("fixed top-[25%] left-[50%] z-[2000]", props.wrapcn)}
      >
        <div
          className={twMerge(
            " translate-x-[-50%] translate-y-[-50%]",
            props.innercn,
          )}
        >
          {props.children}
        </div>
      </motion.div>
    </div>,
    document.getElementById("popup-root"),
  );
};

export const PopupCloseBtn = ({ closepopup }) => {
  return (
    <div className="absolute z-[10] top-[-8px] right-[-8px]">
      <Tag
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (closepopup) closepopup();
        }}
        className="fc-cc xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem] bg-red-500 rounded-[2rem]"
      >
        <FontAwesomeIcon icon={faClose} fontSize={"resp-text-2"} />
      </Tag>
    </div>
  );
};
