import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router";
import {
  q_fqual_info,
  q_fqual_leader,
  q_skinteams,
  q_skinteams_doc,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useMask } from "@react-three/drei";
import { dec, getv, jstr, nils } from "../utils/utils.js";
import { Helmet } from "react-helmet";
import { Loader01c } from "../components/anims.js";
import _, { set } from "lodash";
import { Card, Tag } from "../components/utilityComps.js";
import { haloweenbackdrop } from "../utils/links.js";
import { Link } from "react-router-dom";
import { useAppContext } from "../App.js";
import { twMerge } from "tailwind-merge";
import { elementmap, pos_txt, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import {
  faChevronDown,
  faFlagCheckered,
  faToggleOff,
  faToggleOn,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { MiniSkinCard } from "./Vault3D.js";
import { SkinCard } from "../components/CardComps.js";
import { EntryCriRow } from "./FQual.js";

const Backdrop = ({ backdropimg, ...props }) => {
  return (
    <div className="relative">
      <div
        style={{
          background: `url(${backdropimg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backdropFilter: "blur(10px)",
        }}
        className="absolute z-[5] h-page w-[100vw] overflow-hidden"
      ></div>
      <div className="absolute z-[10] bg-black/60 h-page w-[100vw] top-0 left-0"></div>
      <div className="absolute z-[15] h-page w-[100vw] overflow-auto">
        {props.children}
      </div>
    </div>
  );
};

export const HalloweenSkinTeamsListPage = () => {
  const [qso] = useQueries([q_skinteams()]);
  const skinteams = useMemo(() => {
    if (qissuccesss(qso)) return getv(qso, "data.result");
    else return [];
  }, [qso.dataUpdatedAt]);
  const pagetitle = "Halloween Skin Teams | FBike";
  return (
    <div>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <Backdrop backdropimg={haloweenbackdrop}>
        <div>
          <div className="max-w-[98vw] w-[60rem] mx-auto">
            <div className="h-[5rem]"></div>
            {qso.isLoading ? (
              <Loader01c />
            ) : qiserr(qso) ? (
              <p className="text-center text-red-400">{qiserr(qso)}</p>
            ) : _.isEmpty(skinteams) ? (
              <p className="text-center text-red-400">
                {"No Actives Currently"}
              </p>
            ) : (
              <div className="w-full fc-cc resp-gap-2">
                {skinteams.map((s) => {
                  return (
                    <Link
                      to={`/halloween-skinteams/${s.fstid}`}
                      className="w-full"
                    >
                      <Card className="selection-none bg-dark transition-shadow duration-200 hover:shadow-md hover:shadow-orange-400  fr-sc w-full bg-reg/40">
                        <Tag className="border-2 border-orange-400 text-orange-400">
                          {s.fstid}
                        </Tag>
                        <Tag className=" text-orange-400 font-digi text-left resp-text-1">
                          {s.name}
                        </Tag>
                      </Card>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

const HalloweenSkinTeamContext = createContext({});
export const useHalloweenSkinTeamContext = () =>
  useContext(HalloweenSkinTeamContext);

const gen_points = (h, pointschart) => {
  for (let [k, v] of pointschart) {
    if (_.isNumber(k)) {
      if (k == h.rank) return v;
    } else if (_.isArray(k)) {
      if (_.inRange(h.rank, k[0], k[1] + 0.1)) return v;
    }
  }
  return 0;
};

const FQualLeaderboard = ({}) => {
  const { vault } = useAccountContext();
  const fstcon = useHalloweenSkinTeamContext();
  const { f, fl, my, set_my, fstdoc, valid_skinteam } = fstcon;

  const cris = f.scoring.criteria;
  const by = getv(f, "scoring.scoring_by") ?? "pos";

  return (
    <Card className={"bg-dark/50 w-full max-w-[98vw] overflow-auto"}>
      {!_.isEmpty(f.entry_filters) && (
        <div className="flex flex-row justify-start items-start">
          {/* <div className="p-1">
            <Tag className="text-acc0">Entry Criteria</Tag>
          </div> */}
          <div className="fr-sc resp-gap-2 flex-1">
            {f.entry_filters.map((ef) => {
              return <EntryCriRow ef={ef} />;
            })}
          </div>
        </div>
      )}
      <>
        <div className="fr-cc">
          <div className="fc-ss">
            <div className="fr-sc resp-gap-2">
              <span>Qualifying Score:</span>
              {by == "prize_eth" ? (
                <>
                  <FontAwesomeIcon icon={faEthereum} className="text-acc0" />
                  <span className="font-digi italic text-acc0 resp-text--2">
                    {dec(f.qualifying_score, 4)}
                  </span>
                </>
              ) : (
                <span className="font-digi italic text-acc0 resp-text-1">
                  {dec(f.qualifying_score, 2)}
                </span>
              )}
            </div>
            <div className="fr-sc resp-gap-2">
              <span>using till</span>
              <span className="font-digi italic text-acc0 resp-text-1">
                TOP_{dec(f.qualifiers_n, 0)}
              </span>
            </div>
          </div>
          <div className="flex-1"></div>
        </div>

        {true && (
          <div className="w-full">
            {cris.map((c) => {
              const r = c.race;
              const sc = c.posscores;
              return (
                <div className="w-full py-1 my-1 border-b border-acc0">
                  <div className="fr-sc resp-gap-2">
                    <span>On Race</span>
                    {!nils(r.is_chain) && (
                      <Tag className="bg-acc0/40 font-digi transform -skew-x-12">
                        {r.is_chain == true && "PAID"}
                        {r.is_chain == false && "FREE"}
                      </Tag>
                    )}
                    {!_.isEmpty(r.rgate) && (
                      <Tag className="bg-purple-500 font-digi transform -skew-x-12">
                        {r.rgate.join(", ")} Gates
                      </Tag>
                    )}
                    {!_.isEmpty(r.cb) &&
                      r.cb.map((e) => (
                        <Tag className="bg-acc0/40 font-digi transform -skew-x-12">
                          CB{e}00
                        </Tag>
                      ))}
                    {!_.isEmpty(r.format) &&
                      "normal,reduced,spin_n_go" !== r.format.join(",") &&
                      r.format.map((e) => (
                        <Tag className="bg-acc0/40 font-digi transform -skew-x-12">
                          {_.upperCase(e)}
                        </Tag>
                      ))}
                  </div>
                  <table
                    className={twMerge(
                      tablecn.table,
                      "w-full max-w-full bg-dark"
                    )}
                  >
                    <tbody>
                      <tr className={"thintdrow"}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => (
                          <td>
                            <span
                              className={twMerge(
                                "resp-text-0",
                                e <= 3 ? "text-acc0" : ""
                              )}
                            >
                              {pos_txt(e)}
                            </span>
                          </td>
                        ))}
                      </tr>
                      <tr className={"thintdrow"}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, idx) => (
                          <td>
                            <span className="resp-text-0">{sc[idx]}</span>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        )}
      </>

      <div className="h-[1rem]"></div>

      <div className="fr-sc">
        <Tag className="fr-sc resp-gap-1" onClick={() => set_my(!my)}>
          <FontAwesomeIcon
            className="resp-text-2"
            icon={my ? faToggleOn : faToggleOff}
          />
          <span className="resp-text-0">{"My"} Bikes</span>
        </Tag>
      </div>
      <table className={twMerge(tablecn.table, "w-full")}>
        <thead>
          <tr className={twMerge(tablecn.tr, "text-white font-bold bg-dark")}>
            <td>
              <span className="resp-text-0">Rank</span>
            </td>
            <td>
              <span className="resp-text-0">Bike</span>
            </td>
            <td>
              <span className="resp-text-0">#Tot.Races</span>
            </td>
            <td>
              <span className="resp-text-0">#Sel.Races</span>
            </td>
            <td>
              <span className="resp-text-0">Score</span>
            </td>
            <td>
              <span className="resp-text-0">SkinTeam</span>
            </td>
            <td>
              <span className="resp-text-0">Points</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {(fl?.qualifiers || []).map((qh) => {
            let hdets = fl.hsmap[qh.hid];
            let h = { ...qh, ...hdets };
            h.vault_name = fl.vsmap[hdets.vault];
            const show = my == true ? h.vault == vault : true;
            h.points = gen_points(h, fstdoc.pointschart);
            if (!show) return <></>;
            return (
              <tr
                key={h.hid}
                className={twMerge(
                  tablecn.tr,
                  h.qualified == true ? "bg-orange-400/20" : ""
                )}
              >
                <td className={tablecn.td}>
                  <span className="resp-text-0">#{h.rank}</span>
                </td>
                <td className={tablecn.td}>
                  <div className="flex flex-col justify-start items-start">
                    <Link to={`/bike/${h.hid}`}>
                      <div className="fr-sc w-full resp-gap-1">
                        <span className="text-orange-400 resp-text--1">
                          {h.hid}
                        </span>
                        <span>-</span>
                        <span className="font-digi resp-text-1">{h.name}</span>
                      </div>
                    </Link>
                    <Link to={`/vault/${h.vault}`}>
                      <span className="text-orange-400 resp-text-0">
                        {h.vault_name}
                      </span>
                    </Link>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "resp-text-1 text-right")}>
                  <div className="fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faFlagCheckered} />
                    <span className="resp-text-1">{dec(h.races_n, 0)}</span>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "resp-text-1 text-right")}>
                  <div className="fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faFlagCheckered} />
                    <span className="resp-text-1">{dec(h.sel_races_n, 0)}</span>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  <div className="fr-sc resp-gap-1">
                    {by == "prize_eth" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faEthereum}
                          className="text-orange-400"
                        />
                        <span className="font-digi text-orange-400 resp-text--2">
                          {dec(h.score, 4)}
                        </span>
                      </>
                    ) : (
                      <span className="resp-text-1 ">{dec(h.score, 2)}</span>
                    )}
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  <div className="fc-ss">
                    {nils(h.skinteam) ? (
                      <span className="text-slate-400">--</span>
                    ) : (
                      <>
                        <span
                          className={twMerge(
                            valid_skinteam(h.skinteam)
                              ? "text-orange-400"
                              : "text-slate-400",
                            " resp-text--1"
                          )}
                        >
                          {h.skinteam}
                        </span>
                        {h.skinteam !== h.currskin && (
                          <div className="fr-sc resp-gap-2 text-red-300">
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span className="">{h.currskin ?? "--"}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  {!(h.points > 0) ? (
                    <span className="text-slate-400">--</span>
                  ) : (
                    <span
                      className={twMerge(
                        "resp-text-1",
                        !valid_skinteam(h.skinteam)
                          ? "text-slate-400 line-through	"
                          : "text-orange-400"
                      )}
                    >
                      {h.points}
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const SkinTeamBreakdown = ({ skinteam }) => {
  const fstcon = useHalloweenSkinTeamContext();
  const { fstdoc } = fstcon;
  let ar = getv(fstdoc, `team_breakdowns.${skinteam}`) || [];

  return (
    <Card
      className={"bg-dark/40 border border-orange-500 w-full max-w-[98vw] my-2"}
    >
      <p className="text-orange font-digi text-orange-400 my-2 text-center resp-text-0">
        {skinteam} Breakdown
      </p>
      <table className={twMerge(tablecn.table, "w-full")}>
        <thead>
          <tr className={twMerge(tablecn.tr, "text-white font-bold bg-dark")}>
            <td>
              <span className="resp-text-0">Event-Segment</span>
            </td>
            <td>
              <span className="resp-text-0">Rank</span>
            </td>
            <td>
              <span className="resp-text-0">Bike</span>
            </td>
            <td>
              <span className="resp-text-0">SkinTeam</span>
            </td>
            <td>
              <span className="resp-text-0">Points</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {ar.map((qh, i) => {
            let h = { ...qh };
            let hdets = getv(fstdoc, `bdets.${h.hid}`) || {};
            h = { ...h, ...hdets };
            return (
              <tr
                key={`${skinteam}-${i}`}
                className={twMerge(
                  tablecn.tr,
                  h.qualified == true ? "bg-orange-400/20" : ""
                )}
              >
                <td className={tablecn.td}>
                  <div className="fc-ss">
                    <span className="resp-text-0">{h.event}</span>
                    <span className="resp-text-0">
                      {">>"}
                      {h.segment}
                    </span>
                  </div>
                </td>

                <td className={tablecn.td}>
                  <span className="resp-text-0">#{h.rank}</span>
                </td>
                <td className={tablecn.td}>
                  <div className="flex flex-col justify-start items-start">
                    <Link to={`/bike/${h.hid}`}>
                      <div className="fr-sc w-full resp-gap-1">
                        <span className="text-orange-400 resp-text--1">
                          {h.hid}
                        </span>
                        <span>-</span>
                        <span className="font-digi resp-text-1">{h.name}</span>
                      </div>
                    </Link>
                    <Link to={`/vault/${h.vault}`}>
                      <span className="text-orange-400 resp-text-0">
                        {h.vault_name}
                      </span>
                    </Link>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  <div className="fc-ss">
                    {nils(h.skinteam) ? (
                      <span className="text-slate-400">--</span>
                    ) : (
                      <>
                        <span className="text-orange-400 resp-text--1">
                          {h.skinteam}
                        </span>

                        {h.skinteam !== h.currskin && (
                          <div className="fr-sc resp-gap-2 text-red-300">
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span className="">{h.currskin ?? "--"}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  {!(h.points > 0) ? (
                    <span className="text-slate-400">--</span>
                  ) : (
                    <span
                      className={twMerge(
                        "resp-text-1",
                        nils(h.skinteam)
                          ? "text-slate-400 line-through	"
                          : "text-orange-400"
                      )}
                    >
                      {h.points}
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const SkinTeamBtn = ({ skinteam }) => {
  const { fstdoc, selskinteam, set_selskinteam } =
    useHalloweenSkinTeamContext();

  return (
    <div
      onClick={() => {
        if (skinteam == selskinteam) set_selskinteam(undefined);
        set_selskinteam(skinteam);
      }}
      className={twMerge(
        "w-full rounded-md overflow-hidden cursor-pointer",
        selskinteam == skinteam ? "shadow-md shadow-orange-400" : ""
      )}
    >
      <SkinCard {...{ skin: skinteam, rarity: "", viewdets: true }} />
      <div className="fr-sc bg-dark p-2 ">
        <span className="resp-text--1 flex-1">TeamPoints</span>
        <Tag>
          <span className="font-digi resp-text-1 text-orange-400">
            {dec(getv(fstdoc, `${"team_totals"}.${skinteam}`), 2)}
          </span>
        </Tag>
      </div>
    </div>
  );
};

export const HalloweenSkinTeamsPage = () => {
  const { fstid } = useParams();
  const appcon = useAppContext();
  const { upd_psearch, psearch } = appcon;

  const [qfstdoc] = useQueries([q_skinteams_doc({ fstid })]);
  const fstdoc = useMemo(() => {
    if (qissuccesss(qfstdoc)) return getv(qfstdoc, "data.result");
    else return {};
  }, [qfstdoc.dataUpdatedAt]);

  const pagetitle = useMemo(() => {
    return `${fstdoc?.name ?? fstid} | FBike`;
  }, [fstid, fstdoc.name]);

  const [viewtab, set_viewtab] = useState(psearch.viewtab ?? "info");
  const [event, set_event] = useState(psearch.event);
  const [segment, set_segment] = useState(psearch.segment);
  const [fid, set_fid] = useState(psearch.fid);
  const [my, set_my] = useState(psearch.my == "true");
  const [selskinteam, set_selskinteam] = useState(psearch.selskinteam);

  const [q_f] = useQueries([q_fqual_info({ fid }, { enabled: !nils(fid) })]);
  const [q_fl] = useQueries([q_fqual_leader({ fid }, { enabled: !nils(fid) })]);
  const f = useMemo(() => {
    if (qissuccesss(q_f)) return getv(q_f, "data.result");
    else return {};
  }, [fid, q_f.dataUpdatedAt]);
  const fl = useMemo(() => {
    if (qissuccesss(q_fl)) return getv(q_fl, "data.result");
    else return {};
  }, [fid, q_fl.dataUpdatedAt]);

  const rem = { event, segment, fid, selskinteam, viewtab };
  useEffect(() => {
    upd_psearch(rem);
  }, [jstr(rem)]);

  const valid_skinteam = (s) => {
    if (nils(s)) return false;
    return fstdoc.skinteams.includes(s);
  };

  const scon = {
    fstdoc,
    event,
    set_event,
    segment,
    set_segment,
    fid,
    set_fid,
    my,
    set_my,
    selskinteam,
    set_selskinteam,
    f,
    fl,
    valid_skinteam,
  };
  return (
    <HalloweenSkinTeamContext.Provider value={scon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <Backdrop backdropimg={haloweenbackdrop}>
        <div>
          <div className="max-w-[98vw] w-[70rem] mx-auto">
            <div className="h-[5rem]"></div>
            {qfstdoc.isLoading ? (
              <Loader01c />
            ) : qiserr(qfstdoc) ? (
              <p className="text-center text-red-400">{qiserr(qfstdoc)}</p>
            ) : (
              <>
                <p className="text-center text-orange-400 resp-text-1 font-digi my-4 resp-text-2">
                  {fstdoc.name}
                </p>

                <div className="fr-cc my-2">
                  {["info", "events", "teams-totals"].map((e) => {
                    return (
                      <Tag
                        key={e}
                        onClick={() => {
                          set_viewtab(e);
                        }}
                        className={twMerge(
                          "font-digi resp-px-4 resp-text-1 transition duration-200 text-orange-400",
                          "border-b-2",
                          viewtab == e
                            ? "italic border-orange-400"
                            : "border-transparent"
                        )}
                      >
                        {_.chain(e)
                          .split("-")
                          .map(_.upperFirst)
                          .join(" ")
                          .value()}
                      </Tag>
                    );
                  })}
                </div>

                {viewtab == "info" && (
                  <>
                    <div className="grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-2">
                      {fstdoc.fquals.map((e) => {
                        let [name, segments, evinfo] = e;
                        return (
                          <Card className={"my-4 w-full bg-dark/40"}>
                            <p className="my-2 text-orange-300 font-digi text-center resp-text-1">
                              {name}
                            </p>
                            <table className={"mx-auto w-max"}>
                              <tbody>
                                {[
                                  ["Entry Fee", evinfo.entry_fee],
                                  ["Min Races", evinfo.min_races_n],
                                  ["Scoring", evinfo.scoring],
                                  // ["Run Time", evinfo.run_time],
                                ].map((a) => (
                                  <tr key={a[0]} className="thintdrow">
                                    <td>
                                      <span className="resp-text--1">
                                        {a[0]}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="resp-text--1 font-digi text-orange-200">
                                        {a[1]}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="h-[1rem] resp-text-1"></div>
                            <p className="text-orange-400 my-1 resp-text-1">
                              Segments:
                            </p>
                            <ul className="list-disc list-inside">
                              {segments.map((s) => {
                                return (
                                  <li key={s[0]}>
                                    <span className="text-white resp-text-0 p-1">
                                      {s[1]}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </Card>
                        );
                      })}
                    </div>

                    <div className="grid grid-cols-2 resp-gap-2">
                      <Card className="bg-dark/40 w-full">
                        <p className="my-2 text-orange-300 font-digi text-center resp-text-1">
                          {"Points Chart"}
                        </p>
                        <table className="w-max mx-auto">
                          {getv(fstdoc, "pointschart").map((e) => {
                            return (
                              <tr className="thintdrow">
                                <td>
                                  <span className="font-digi text-white resp-text-0 p-1">
                                    {_.isNumber(e[0])
                                      ? `${pos_txt(e[0])}`
                                      : _.isArray(e[0])
                                      ? `${e[0]
                                          .map((a) => pos_txt(a))
                                          .join(" - ")}`
                                      : ""}
                                  </span>
                                </td>
                                <td>
                                  <span className="font-digi text-center text-orange-300 resp-text-0 p-1">
                                    {dec(e[1], 2)}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </Card>
                      <Card className="bg-dark/40 w-full">
                        <p className="my-2 text-orange-300 font-digi text-center resp-text-1">
                          {"Points Information"}
                        </p>
                        <ul className="list-disc list-inside">
                          {getv(fstdoc, "pointsinfo").map((s, i) => {
                            return (
                              <li key={i}>
                                <span className="text-white resp-text-0 p-1">
                                  {s}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </Card>
                    </div>

                    <Card className={"my-4 w-full bg-dark/40"}>
                      <p className="my-2 text-orange-300 font-digi text-center resp-text-1">
                        {"Rules"}
                      </p>
                      <ul className="list-disc list-inside">
                        {getv(fstdoc, "rules").map((s, i) => {
                          return (
                            <li key={i}>
                              <span className="text-white resp-text-0 p-1">
                                {s}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </>
                )}

                {viewtab == "teams-totals" && (
                  <>
                    <div className="grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-2">
                      {fstdoc.skinteams.map((s) => {
                        return <SkinTeamBtn skinteam={s} />;
                      })}
                    </div>
                    {selskinteam && (
                      <SkinTeamBreakdown skinteam={selskinteam} />
                    )}
                  </>
                )}

                {viewtab == "events" && (
                  <>
                    {/* <div className="h-[0.2rem] rounded-md bg-orange-400 my-4"></div> */}
                    <div className="w-full fr-cc resp-gap-2 resp-my-2 flex-wrap">
                      {fstdoc.fquals.map((e) => {
                        return (
                          <Tag
                            onClick={() => {
                              set_event(e[0]);
                            }}
                            className={twMerge(
                              e[0] == event
                                ? "bg-orange-400 text-black -skew-x-12"
                                : "bg-transparent border border-orange-400 text-orange-400",
                              "resp-text-0 durtaion-200 font-digi"
                            )}
                          >
                            {e[0]}
                          </Tag>
                        );
                      })}
                    </div>

                    <div className="w-full fr-cc resp-gap-2 resp-my-2 flex-wrap">
                      {event &&
                        _.find(fstdoc.fquals, (e) => e[0] == event)[1].map(
                          (e) => {
                            return (
                              <Tag
                                onClick={() => {
                                  set_segment(e[1]);
                                  set_fid(e[0]);
                                }}
                                className={twMerge(
                                  e[1] == segment
                                    ? "text-orange-400 underline underline-offset-8  underline-orange-400 -skew-x-12"
                                    : "text-slate-400",
                                  // "text-orange-400",
                                  "resp-text-0 durtaion-200 font-digi"
                                )}
                              >
                                {e[1]}
                              </Tag>
                            );
                          }
                        )}
                    </div>

                    {nils(event) ? (
                      <p className="text-center resp-text-1">
                        Select an Event and Segment to view Leaderboard
                      </p>
                    ) : nils(segment) ? (
                      <p className="text-center resp-text-1">
                        Select a Segment for {event}
                      </p>
                    ) : (
                      <></>
                    )}

                    {(!nils(fid) && q_f.isLoading) || q_fl.isLoading ? (
                      <Loader01c />
                    ) : qissuccesss(q_f) && qissuccesss(q_fl) ? (
                      <FQualLeaderboard />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
            <div className="h-[6rem]"></div>
          </div>
        </div>
      </Backdrop>
    </HalloweenSkinTeamContext.Provider>
  );
};
