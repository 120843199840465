import React, { useRef } from "react";
import _ from "lodash";
import {
  cdelay,
  copy_clip,
  dec,
  emp,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
  tofeth,
  trim_n,
} from "./utils.js";
import {
  BImg,
  Card,
  Dropdown,
  HeadC2L,
  InpText,
  Tag,
  TokenIcon,
  ToolTip,
} from "../components/utilityComps.js";
import { useRacesContext } from "../views/Races.js";
import { twMerge } from "tailwind-merge";
import {
  cb_cn,
  cb_txt,
  cbs,
  class_cn,
  class_text,
  elementmap,
  gendermap,
  get_payout_cn,
  get_payout_txt,
  tablecn,
} from "./cn_map.js";
import { motion } from "framer-motion";
import { useQueries } from "react-query";
import {
  iserr,
  q_arcade_open_hs_in,
  q_arcade_race_hstats,
  q_hstats,
  q_logtxnqueue,
  q_open_enter,
  q_open_hs_in,
  q_open_vault_valid_hs,
  q_token_prices,
  qissuccesss,
  useStepQuery,
} from "../queries/queries.js";
import { useEffect, useMemo, useState } from "react";
import { Loader01c } from "../components/anims.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUpFromBracket,
  faBattery,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faCircle,
  faClose,
  faFlagCheckered,
  faLink,
  faMapMarkedAlt,
  faQuestion,
  faQuestionCircle,
  faSatelliteDish,
  faShare,
  faShareNodes,
  faStar,
  faTrophy,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { Mo, MoVariants } from "./motion_helper.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { Link, useNavigate } from "react-router-dom";
import { tokdecn, tokdecn2, useAppContext, useNowContext } from "../App.js";
import {
  faDiscord,
  faEthereum,
  faHackerNews,
} from "@fortawesome/free-brands-svg-icons";
import { Watch3d, WatchRow } from "../views/RacePage.js";
import RaceStake from "../contracts/RaceStake/RaceStakeContract.js";
import { ethers } from "ethers";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { contractABIs, contractAddress_list } from "../contracts/constants.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import RaceStakeV02 from "../contracts/RaceStakeV02/RaceStakeV02.js";
import RaceStakeV03_DEZ from "../contracts/RaceStakeV03_DEZ/RaceStakeV03_DEZ.js";
import {
  mm_asset_signer,
  que_con_fn_runner,
} from "../contracts/contract_funcs.js";
import moment from "moment";
import { ContactTag } from "../views/HomePage.js";
import {
  ElementTag,
  GenderTag,
  MiniElementTag,
  MiniGenderTag,
} from "../components/ShortComps.js";
import {
  RaceLinksTrainerInfo,
  trainer_market_txt,
} from "../views/ClaimTrainerPage.js";
import { R2_EventTag, SkewWrap2 } from "./raceutils2.js";
import { BikeImg } from "../components/BikeImg.js";

export const get_race_from_mini = ([
  rid,
  mini,
  format,
  format_inf,
  r_form,
  race_name,
  presetid,
  extra = null,
]) => {
  const regex =
    /^(\w+)-([\w]+)-([\w]+)-([\w]+)-([\w]+)-\[(.*?)\]-\{([\d.\w]+)\}-\{([\d.\w]+)\}$/i;

  const match = mini.match(regex);
  if (match) {
    const cb = parseInt(match[1]);
    const c = parseInt(match[2]);
    const feetag = match[3];
    const rgate = parseInt(match[4]);
    const payout = match[5];
    let eventtags = match[6];
    eventtags = nils(eventtags) ? [] : eventtags.split(",");
    const fee = parseFloat(match[7]);
    const prize = match[8] == "null" ? null : parseFloat(match[8]);
    const prize_map = gen_prize_map({
      rgate,
      prize,
      payout,
    });

    let r = {
      rid,
      cb: cb,
      class: c,
      feetag,
      rgate,
      payout,
      eventtags,
      fee,
      prize,
      prize_map,
      status: "open",
      mini,
      format,
      format_inf,
      r_form,
      race_name,
      presetid,
    };

    if (!nils(extra)) {
      r = { ...r, ...extra };
    }

    return r;
  } else return null;
};

const ExpandedRaceCard = ({
  rid,
  qo_rginf,
  active,
  race,
  rhids_ofv,
  def_enter = false,
}) => {
  const { auth } = useAuthContext();
  const { vault, balance, dezbalance } = useAccountContext();
  const [enter, set_enter] = useState(def_enter);

  const r = race;
  const cb = race.cb;
  const c = race.class;

  const openhsin = useMemo(() => {
    let d = getv(qo_rginf, "data.result") || {};
    // console.log(rid, "openhsin", d);
    return d;
  }, [qo_rginf.dataUpdatedAt]);
  const hids = openhsin.hids ?? [];
  const hs = openhsin.hs ?? {};

  const hsin = hids.length ?? 0;
  const qshs = useStepQuery({
    q_: race.is_arcade ? q_arcade_race_hstats : q_hstats,
    par_ar: (hids || []).map((hid) => [{ rid, hid, cb, class: c }]),
    lim: 3,
    options: { enabled: active },
  });

  const show_enter_race = useMemo(() => {
    if (r.admin_entry === true) return false;
    if (r.status !== "open") return false;
    if (hids.length >= race.rgate) return false;
    if (!auth) return "please login to enter race";
    // console.log(r.paytoken, r.fee, balance, dezbalance);
    let bal = r.paytoken == "DEZ" ? dezbalance : balance;
    if (!nils(bal) && bal < race.fee) return `insufficient balance to enter`;
    return true;
  }, [r.status, hids.length, auth, balance]);

  const [hstatstype, set_hstatstype] = useState("cb");

  return (
    <motion.div
      variants={MoVariants.show_hide}
      animate={active ? "visible" : "hidden"}
      className="resp-mx-2 resp-p-4 bg-dark rounded-md mt-[1rem]"
    >
      {race.is_arcade !== true && (
        <>
          <div className="fr-cc resp-gap-1 rsep-my-2">
            {/* <div className="flex-1"></div> */}
            <span className="resp-text--1">Stats View</span>
            {[
              ["ov", "Overall"],
              ["cb", `Race CB${race.cb}00`],
            ].map(([k, txt]) => {
              return (
                <Tag
                  onClick={() => {
                    set_hstatstype(k);
                  }}
                  className={twMerge(
                    hstatstype == k ? "bg-acc0 text-black " : "text-acc0",
                  )}
                >
                  {txt}
                </Tag>
              );
            })}
            <div className="flex-1"></div>
          </div>
        </>
      )}

      {hids.map((hid, idx) => {
        let q = qshs.qs[idx];
        if (!qissuccesss(q)) return <></>;
        let h = getv(q, "data.result");

        let is_vhhid = rhids_ofv.includes(hid);
        return (
          <RaceHRow
            {...{
              rid,
              hid,
              h,
              key: hid,
              is_vhhid,
              status: r.status,
              race,
              openh: hs[hid],
              hstatstype,
              race,
              showhstats: race.is_arcade !== true,
            }}
          />
        );
      })}
      {!nils(show_enter_race) && _.isString(show_enter_race) && (
        <p className="text-red-400 border border-red-400 rounded-md w-full text-center italic resp-p-2 resp-px-4">
          {show_enter_race}
        </p>
      )}
      {show_enter_race === true && (
        <div className="fr-sc mt-2">
          <div className="flex-1"></div>
          <Tag
            onClick={() => set_enter(true)}
            className="bg-acc0/40 font-digi resp-text--1 transform -skew-x-12"
          >
            Enter Race
          </Tag>
        </div>
      )}

      <OpenRaceEnter {...{ race, enter, set_enter, qo_rginf }} />
    </motion.div>
  );
};

export const RaceHRow = ({
  rid,
  hid,
  h,
  is_vhhid = false,
  status = "open",
  race = {},
  openh = {},
  hstatstype = "cb",
  showhstats = true,
}) => {
  const hstats =
    showhstats === false ? null : (
      <>
        <div className="fr-sc resp-gap-2 font-mon resp-text--2  text-yellow-500">
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] resp-gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>{dec(getv(h, `hstat.${hstatstype}.win_p`) * 100, 1)}%</span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] resp-gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${hstatstype}.races_n`), 0)}</span>
          </div>
          <ToolTip
            msg_cn="w-[10rem]"
            message={`${
              hstatstype == "cb" ? "ALL Races in CB" : "Overall Races"
            }`}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </ToolTip>
        </div>
        <div className="fr-sc resp-gap-2 font-mon resp-text--2 text-green-500">
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] resp-gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>
              {dec(getv(h, `hstat.${hstatstype}.paid_win_p`) * 100, 1)}%
            </span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] resp-gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${hstatstype}.paid_races_n`), 0)}</span>
          </div>
          <ToolTip
            msg_cn="w-[10rem]"
            message={`${
              hstatstype == "cb" ? "Paid Races in CB" : "Overall Paid Races"
            }`}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </ToolTip>
        </div>
      </>
    );
  const hdets = (
    <div className="fr-sc resp-gap-1 font-mon text-acc0 resp-text--2">
      <div className="fr-cc xs:w-[1rem] lg:w-[2rem] resp-gap-1">
        <span>F{h.fno}</span>
      </div>
      <span>/</span>
      <div className="fr-cc xs:w-[2rem] lg:w-[4rem] resp-gap-1">
        <span>{_.capitalize(h.type)}</span>
      </div>
      <div class="fr-cc ">
        <MiniGenderTag {...{ gender: h.gender }} />
      </div>
      <div class="fr-cc ">
        <MiniElementTag {...{ element: h.element }} />
      </div>
    </div>
  );

  const hname = (
    <div className="fr-sc resp-gap-1">
      <Link to={`/bike/${h.hid}`}>
        <p className="resp-text-1 font-digi">{h.name}</p>
      </Link>
      {!nils(race) && (
        <>
          {(race.bluestars || [])?.includes(hid) && (
            <FontAwesomeIcon icon={faStar} className="text-blue-400" />
          )}
          {(race.yellowstars || [])?.includes(hid) && (
            <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
          )}
        </>
      )}
    </div>
  );

  const hvault = (
    <Link to={`/vault/${h.vault}`}>
      <p className="resp-text--3 font-digit text-acc0">{h.vault_name}</p>
    </Link>
  );

  const himg = <BImg className={"w-[2rem] h-[2rem]"} hex_code={h.hex_code} />;

  const hhidrow = (
    <div className="fr-sc resp-gap-2">
      <span className="resp-text--3">{hid}</span>
    </div>
  );

  const hentrystatus =
    status == "open" && race.is_chain && !_.isEmpty(openh) ? (
      <>
        <div className="fr-cc resp-gap-1">
          <div className="flex-1"></div>

          <>
            {openh.confirmed == true && (
              <span className="resp-text--1 text-green-400">
                {"Entry Confirmed!!"}
              </span>
            )}
            {openh.confirmed == false && (
              <>
                <Loader01c className="bg-orange-400" size="s" />
                <span className="resp-text--1 text-orange-400">
                  {"Awaiting..."}
                </span>
              </>
            )}
          </>
        </div>
      </>
    ) : (
      <></>
    );

  const hlisting = nils(h.listing) ? null : (
    <>
      <Tag
        redirect={`https://market.dnaracing.run/asset/core/${hid}`}
        className={twMerge(
          "resp-text--1 text-acc0 fr-sc border border-acc0 resp-gap-1 bg-dark",
        )}
      >
        <span>{getv(h.listing, "price").toString()}</span>
        <span>{getv(h.listing, "token")}</span>
      </Tag>
    </>
  );

  // useEffect(() => {
  //   console.log(rid, hid, openh);
  // }, [openh]);

  const team = useMemo(() => {
    if (race.is_team !== true) return null;
    let t = _.find(race.teamsmap, (e) => e.hids.includes(hid));
    return t;
  }, [jstr(h), jstr(race)]);

  const team_cn = useMemo(() => {
    if (!team) return null;
    if (team.k == "A") return "bg-blue-600/40";
    else return "bg-red-600/40";
  }, [jstr(team)]);

  return (
    <Card
      className={twMerge(
        "resp-p-2 resp-gap-2 bg-reg w-full",
        is_vhhid ? "bg-lig shadow-inner shadow-acc0" : "",
        team_cn,
      )}
    >
      <div className="xs:hidden lg:block ">
        <div className="fr-sc w-full resp-gap-2">
          {himg}
          <div className="fc-ss">
            {hhidrow}
            {hname}
            {hvault}
          </div>
          <div className="flex-1 fr-cc">
            <div className="flex-1"></div>
            <div className="fc-cc">
              {hdets}
              {hlisting}
            </div>
          </div>
          <div className="flex flex-col justify-end items-end resp-gap-2">
            {hstats}
            {hentrystatus}
          </div>
        </div>
      </div>
      <div className="lg:hidden xs:block">
        <div className="fr-cc w-full">
          {hname}
          <div className="flex-1"></div>
          <div className="fc-cc">{hdets}</div>
        </div>
        <div className="fr-cc w-full">
          <div className="fc-ss">{hvault}</div>
          <div className="flex-1"></div>
          <div className="flex flex-col justify-end items-end resp-gap-1">
            {hstats}
            {hentrystatus}
          </div>
        </div>
      </div>
    </Card>
  );
};

export const RaceCardInner = ({
  race,
  active,
  def_enter,
  rtstatus = "open",
  show_baseinfo = true,
  select,
  showwatch = true,
}) => {
  const { auth } = useAuthContext();
  const { vault, balance } = useAccountContext();

  const r = race;
  const { rid } = r;
  const c = race.class;
  const cb = race.cb;
  // console.log(r.rid, r.is_arcade);
  const [qo_rginf] = useQueries([
    r.is_arcade ? q_arcade_open_hs_in({ rid }) : q_open_hs_in({ rid }),
  ]);
  const [rg_loading, rginf = {}] = useMemo(() => {
    if (qo_rginf.isLoading) return [true, {}];
    let d = getv(qo_rginf, "data.result");
    return [false, d];
  }, [qo_rginf.dataUpdatedAt]);

  const accon = useAccountContext();
  const { hids: vhids, bikesob } = accon;
  const rhids_ofv = useMemo(() => {
    if (_.isEmpty(rginf?.hids)) return [];
    let rhids_ofv = _.intersection(rginf?.hids, vhids) ?? [];
    return rhids_ofv;
  }, [rginf?.hids, vhids]);

  const [enter, set_enter] = useState(def_enter);
  const show_enter_race = useMemo(() => {
    if (r.status !== "open") return false;
    if (rginf?.hids?.length >= race.rgate) return false;
    if (!auth) return "please login to enter race";
    if (!nils(balance) && balance < race.fee)
      return `insufficient balance to enter`;
    return true;
  }, [r.status, rginf?.hids?.length, auth, balance]);

  const is_bulk_valid = useMemo(() => {
    if (!r) return false;
    return [50, 60, 70, 80].includes(r.class) && [2, 3].includes(r.version);
  }, [r?.class]);

  return (
    <>
      {show_baseinfo && (
        <>
          <div onClick={select} className="cursor-pointer">
            <RaceBaseInfoCard {...{ race: r, rtstatus, showwatch }} />
          </div>
          <div className="fr-sc">
            <div className="flex-1"></div>
            {rtstatus == "open" && (
              <>
                {is_bulk_valid && (
                  <Link
                    target="_self"
                    to={`/races-auto-filler?tab=enter&bulkid=${r.bulkid}`}
                  >
                    <Tag className="bg-orange-500/40 font-digi resp-text--1 transform -skew-x-12">
                      Enter Bulk Race
                    </Tag>
                  </Link>
                )}
                {r.admin_entry !== true ? (
                  <div className="mt-2">
                    <Tag
                      onClick={() => set_enter(true)}
                      className="bg-acc0/40 font-digi resp-text--1 transform -skew-x-12"
                    >
                      Enter Race
                    </Tag>
                    <OpenRaceEnter {...{ race, enter, set_enter, qo_rginf }} />
                  </div>
                ) : (
                  <>
                    <span className="text-red-300">
                      Entry is admin controlled
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      {/* {jstr(race.hids)} */}
      <ExpandedRaceCard
        {...{
          rid,
          qo_rginf: qo_rginf,
          active,
          race,
          rhids_ofv,
          def_enter,
        }}
      />
    </>
  );
};

export const RaceCard = ({ race, rtstatus, useContext, showwatch }) => {
  const rcon = useContext();
  const { selrace, set_selrace } = rcon;

  const { rid } = race;
  const r = race;

  const active = rid == selrace;
  const deselect_race = () => {
    set_selrace(null);
  };

  const c = race.class;

  const select = () => {
    set_selrace(rid);
  };
  return (
    <div
      className={twMerge(
        "relative",
        "w-[97%] resp-my-2 resp-py-2 resp-px-2 resp-mx-2 text-[0.75rem] transition duration-300",
        active
          ? "bg-reg rounded-md border border-acc0/40"
          : "border-b border-acc0/20",
      )}
    >
      <div
        // onClick={select}
        className="realtive"
      >
        <RaceCardInner
          {...{
            race,
            rtstatus,
            active,
            def_enter: false,
            select,
            showwatch,
          }}
        />
      </div>
      {active && (
        <div className="absolute right-0 top-0">
          <div onClick={deselect_race} className="resp-p-2 cursor-pointer">
            <FontAwesomeIcon
              className="text-red-400 resp-text-2"
              icon={faClose}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const OpenRaceEnter = ({ race, enter, set_enter, qo_rginf }) => {
  const acc = useAccountContext();
  const { vault } = acc;
  const closepopup = () => {
    set_enter(false);
  };

  const rid = race.rid;
  const cb = race.cb;
  const c = race.class;
  const payout = race.payout;

  const [qovalids] = useQueries([
    q_open_vault_valid_hs({ rid }, { enabled: enter }),
  ]);
  const validhs = useMemo(() => {
    let hs = getv(qovalids, "data.result") ?? [];
    hs = hs.map((h, i) => ({ hid: h[0], name: h[1], idx: i }));
    return hs;
  }, [qovalids.dataUpdatedAt]);

  const qvalidenter_err = useMemo(() => {
    let err = getv(qovalids, "data.err") ?? null;
    return err;
  }, [qovalids.dataUpdatedAt]);

  const qshs = useStepQuery({
    q_: race.is_arcade == true ? q_arcade_race_hstats : q_hstats,
    par_ar: (validhs || []).map((h) => [{ rid, hid: h.hid, cb, class: c }]),
    lim: 3,
  });

  const [enteringerr, set_enteringerr] = useState(null);
  const [entering, set_entering] = useState(null);
  const entertimer = useRef(null);
  const enterbike = async (hid) => {
    if (entertimer.current) clearTimeout(entertimer.current);

    // entertimer.current = setTimeout(() => {
    //   console.log("timeout exit");
    //   set_entering(null);
    //   set_enter(false);
    // }, 20 * 1e3);

    set_enteringerr(null);

    if (entering) return;
    set_entering(hid);

    const is_chain = parseFloat(race.fee) > 0 || parseFloat(race.prize) > 0;
    // console.log({ rid, is_chain });

    try {
      if (is_chain) {
        const racestake =
          race.version == 1
            ? await RaceStake.get_contract()
            : race.version == 2
              ? await RaceStakeV02.get_contract()
              : race.version == 3
                ? await RaceStakeV03_DEZ.get_contract()
                : null;
        if (!racestake) throw new Error("races version mismatch");

        const paytokenaddr =
          race.paytoken == "WETH"
            ? contractAddress_list.weth
            : race.paytoken == "DEZ"
              ? contractAddress_list.dez
              : null;
        if (nils(paytokenaddr)) throw new Error("paytoken mismatch");

        const paytokencon = await mm_asset_signer(null, 20, paytokenaddr);

        // const weth_tok = await WETH_MockToken.get_contract();
        await cdelay(1500);
        const allowance = await que_con_fn_runner(
          async () => paytokencon.allowance(vault, racestake.contractAddress),
          `allowance-${rid}`,
        );
        if (nils(allowance))
          throw new Error(
            "allowance issue, please set your allowance and try again",
          );
        // console.log(rid, race.fee, race.paytoken, race.version);
        if (parseFloat(tofeth(allowance)) < parseFloat(race.fee)) {
          const approval = await paytokencon.approve(
            racestake.contractAddress,
            ethers.parseEther(race.fee.toString()),
          );
          await approval.wait();
          await cdelay(1000);
        }
        if (race.version == 1) {
          let befconstresp = await q_logtxnqueue({
            data: {
              type: "before_cont",
              rid,
              hid,
              vault,
              time: iso(),
              hash: null,
            },
          }).queryFn();
          // console.log("befconstresp", befconstresp);
          if (befconstresp.status == "error") {
            set_enteringerr(getv(befconstresp, "err"));
            set_entering(null);
            return;
          }
        }

        if (race.version == 4) {
          let fbikecon = await mm_asset_signer("core");
          let approved = await fbikecon.isApprovedForAll(
            vault,
            racestake.contractAddress,
          );
          if (!approved) {
            let approval = await fbikecon.setApprovalForAll(
              racestake.contractAddress,
              true,
            );
            await approval.wait();
            await cdelay(1000);
          }
        }

        let tx = await racestake.enterRace(rid, hid);
        // console.log("entry txn", tx);
        // let tx = null;

        if (tx?.hash) {
          await q_logtxnqueue({
            data: {
              type: "after_cont",
              rid,
              hid,
              vault,
              time: iso(),
              hash: tx.hash,
            },
          }).queryFn();
        }
      }
    } catch (err) {
      // console.log(err);
      if (err.reason) {
        set_enteringerr(err.reason);
      } else if (err.message) {
        set_enteringerr(err.message);
      } else {
        set_enteringerr(`Opps!! encountered a weird issue`);
      }
      set_entering(null);
      return;
    }

    let resp = await q_open_enter({ hid, rid }).queryFn();
    let err = getv(resp, "err");
    // console.log(resp, err);
    let done = getv(resp, "result.done");
    // console.log("q_open_enter", { err, done });

    if (err) {
      set_enteringerr(err);
      set_entering(null);
    }
    if (done) {
      await cdelay(2000);
      await qo_rginf.refetch();
      await cdelay(2000);
      set_entering(null);
      set_enter(false);

      if (entertimer.current) clearTimeout(entertimer.current);
      /* setTimeout(() => {
        acc.qo_balance.refetch();
      }, 3000); */
    }
  };

  const entertxt = `Select Bike To Enter CB${cb}00 ${_.upperCase(payout)}`;

  const [hstatstype, set_hstatstype] = useState("cb");

  const [searchtxt, set_searchtxt] = useState(null);
  const filths = useMemo(() => {
    if (nils(searchtxt)) return validhs;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(validhs)
      .map((h) => {
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [h, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [h, 2];
        else if (hname.startsWith(sear)) return [h, 3];
        else if (hname.includes(sear)) return [h, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(validhs), searchtxt]);

  return (
    <>
      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        openstate={enter}
        overlayclose={false}
      >
        <Card className={"relative w-[20rem] max-w-[90vw] bg-r2lig"}>
          <PopupCloseBtn {...{ closepopup }} />

          <div className="">
            {qovalids.isLoading ? (
              <>
                <div className="fc-cc">
                  <p className="resp-my-2 text-acc0">
                    Finding Eligible Bikes in your Vault....
                  </p>
                  <Loader01c />
                </div>
              </>
            ) : (
              <>
                {!nils(qvalidenter_err) ? (
                  <p className="text-center text-red-400">{qvalidenter_err}</p>
                ) : _.isEmpty(validhs) ? (
                  <>
                    <p className="text-center text-red-400 resp-text-0">
                      No Eligible bikes for this race
                    </p>
                  </>
                ) : (
                  <>
                    <div className="fr-cc">
                      <p className="text-acc0 italic  resp-text-1 resp-my-1">
                        {entertxt}
                      </p>
                    </div>
                    <div className="fr-cc resp-gap-1">
                      <div className="flex-1"></div>
                      <span className="resp-text--1">Stats View</span>
                      {[
                        ["ov", "Overall"],
                        ["cb", `Race CB${race.cb}00`],
                      ].map(([k, txt]) => {
                        return (
                          <Tag
                            onClick={() => {
                              set_hstatstype(k);
                            }}
                            className={twMerge(
                              hstatstype == k
                                ? "bg-acc0 text-black "
                                : "text-acc0",
                            )}
                          >
                            {txt}
                          </Tag>
                        );
                      })}
                    </div>
                    {enteringerr && (
                      <p className="resp-my-2 resp-p-2 text-red-400 border border-red-400 rounded-md resp-text--1">
                        {enteringerr}
                      </p>
                    )}

                    <Card className={"resp-p-2 bg-r2dark/10 w-full"}>
                      <div className="fr-cc resp-gap-1 resp-px-1 resp-my-1">
                        <div className="flex-1">
                          <InpText
                            {...{
                              id: `entry-search-race-${rid}`,
                              placeholder: "Search Core",
                              inpprops: {
                                className:
                                  "resp-text-1 font-digi bg-reg text-acc0 w-full",
                              },
                              contprops: {
                                className:
                                  "resp-text-1 font-digi bg-reg text-acc0 w-full",
                              },
                              setter: (v) => {
                                console.log("setter", v);
                                if (nils(v)) v = null;
                                set_searchtxt(v);
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full xs:h-[60vh] lg:h-[30vh] overflow-auto resp-px-1">
                        {filths.map((h0, idx) => {
                          let hid = h0.hid;
                          let q = qshs.qs[h0.idx];
                          let succ = qissuccesss(q);
                          let h = getv(q, "data.result");

                          const entertbtn = (
                            <Tag
                              onClick={() => {
                                if (entering == null) enterbike(hid);
                              }}
                              className={twMerge(
                                "resp-px-4 fr-cc resp-gap-2",
                                nils(entering)
                                  ? "bg-acc0 text-black"
                                  : entering == hid
                                    ? "bg-dark text-acc0 shadow-lg shadow-acc0 cursor-wait"
                                    : "bg-reg text-lig cursor-not-allowed",
                              )}
                            >
                              {entering == hid && (
                                <>
                                  <Loader01c size="s" />
                                  <span>Entering...</span>
                                </>
                              )}
                              {(nils(entering) || entering !== hid) && (
                                <span>
                                  {`Enter ${_.capitalize(race?.rvmode ?? "bike")}`}
                                </span>
                              )}
                            </Tag>
                          );

                          const hrow = succ ? (
                            <RaceHRow
                              {...{ rid, hid, h, key: hid, hstatstype }}
                            />
                          ) : (
                            <div className="fr-sc w-full rep-gap-2 resp-p-2">
                              <Loader01c size="s" />
                              <div className="fc-ss w-full resp-gap-2">
                                <span className="font-digi resp-text--3">
                                  {hid}
                                </span>
                                <span className="font-digi resp-text-1">
                                  {h0?.name}
                                </span>
                              </div>
                            </div>
                          );

                          return (
                            <div
                              key={jstr(h0)}
                              className="bg-reg rounded-md resp-my-2"
                            >
                              <div className="xs:hidden lg:block">
                                <div className="fr-cc">
                                  <div className="flex-1">{hrow}</div>
                                  {entertbtn}
                                </div>
                              </div>
                              <div className="xs:block lg:hidden">
                                <div className="fc-cc w-full">
                                  <div className="w-full">{hrow}</div>
                                  <div className="fr-cc w-full">
                                    <div className="flex-1"></div>
                                    {entertbtn}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  </>
                )}
              </>
            )}
          </div>
        </Card>
      </PopUp>
    </>
  );
};

export const StableHRow = ({ hid, h }) => {
  const [expand, set_expand] = useState(false);
  const { acc_config, s_acc_config } = useAccountContext();

  const mode = acc_config?.mode;
  const hcpath = `vaulthconfs.${hid}`;
  const hconf = getv(acc_config, hcpath) || {};
  const s_hconf = (p, v) => s_acc_config(`${hcpath}.${p}`, v);

  const srem = getv(h, `stamina.stamina`);
  const slimit = getv(h, `stamina.max_stamina`);
  const sper = srem / slimit;

  return (
    <div
      className={twMerge(
        "rounded-md transition duration-300",
        expand ? `border border-acc4 bg-r2dark/30 my-1` : "",
      )}
    >
      <SkewWrap2 active={expand}>
        <div className="p-1 rounded-lg ">
          <div
            onClick={() => {
              set_expand(!expand);
            }}
            className={twMerge("fr-sc resp-p-1 resp-gap-2 cursor-pointer")}
          >
            {h.auto_rem > 0 && (
              <div className="absolute top-[-0.5rem] left-[-0.5rem] fr-sc font-bold font-digi">
                <Tag className={class_cn("resp-text--2 fc-cc", "text-acc4")}>
                  <span className="resp-px-1 resp-text--1">{h.auto_rem}</span>
                  <FontAwesomeIcon icon={faCircle} />
                </Tag>
              </div>
            )}
            <div class="absolute left-[-5rem]">
              <motion.div
                {...Mo.slide({ x: [-50, 0, 200] })}
                animate={expand ? "visible" : "hidden"}
                transition={{ delay: expand ? 0.5 : 0.1 }}
              >
                <BikeImg hex_code={h.hex_code} />
              </motion.div>
            </div>
            <div class="fc-ss flex-1">
              <div
                className={twMerge(
                  "flex-1 font-digi fr-sc w-full",
                  `py-[0.5rem] border-b-2 border-${h.color}`,
                  h.name.length > 10 ? "resp-text--1" : "resp-text-0",
                )}
              >
                {trim_n(h.name, 15)}
              </div>
              <div class="fr-sc resp-gap-1 resp-text--1">
                <FontAwesomeIcon
                  icon={elementmap[h.element]?.icon}
                  className={elementmap[h.element]?.text}
                />
                <span>{_.capitalize(h.type)}</span>
              </div>
            </div>

            <div className="fc-ss">
              <div className="fr-sc resp-gap-1">
                <div className="resp-text--2 font-digi fr-sc">F{h.fno}</div>
                <div className="resp-text--2 font-digi fr-sc">
                  {_.upperCase(h.type?.slice(0, 3))}
                </div>
              </div>

              <div className="fr-sc font-bold font-digi">
                <Tag
                  className={class_cn(
                    "resp-text--2 fc-cc",
                    sper <= 0
                      ? "text-red-400"
                      : sper == 1
                        ? "text-green-400"
                        : sper >= 0.5
                          ? "text-green-300"
                          : "text-red-300",
                  )}
                >
                  <span className="resp-px-1 resp-text--1">{srem}</span>
                  <FontAwesomeIcon
                    icon={
                      sper <= 0
                        ? faBatteryEmpty
                        : sper < 0.3
                          ? faBatteryQuarter
                          : sper == 1
                            ? faBatteryFull
                            : faBatteryHalf
                    }
                  />
                </Tag>
              </div>
            </div>
          </div>
        </div>
      </SkewWrap2>

      <motion.div
        initial="hidden"
        variants={MoVariants.show_hide}
        animate={expand ? "visible" : "hidden"}
      >
        <div className="fr-sc resp-gap-1 resp-my-1 resp-px-2">
          <p className="text-[10px]">Only Show on CB</p>
          <div className="flex-1"></div>

          <Tag
            onClick={() => {
              let hide = hconf?.hide === true;
              s_hconf("hide", !hide);
            }}
            className={twMerge(
              "transition duration-200 resp-px-2 resp-py-1 text-[10px] text-center",
              hconf?.hide === true
                ? "text-dark bg-red-400"
                : "bg-r2reg text-red-400",
            )}
          >
            HIDE
          </Tag>
        </div>
        <div className="grid w-full grid-cols-3 resp-p-2">
          {[9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(
            (cb) => {
              let acts = hconf.cbmap || [];
              let active = acts.includes(cb);
              return (
                <Tag
                  onClick={() => {
                    let acts2 = [];
                    if (acts.includes(cb)) {
                      acts2 = _.chain([...acts])
                        .filter((e) => e != cb)
                        .sort()
                        .uniq()
                        .value();
                    } else {
                      acts2 = _.chain([...acts, cb])
                        .sort()
                        .uniq()
                        .value();
                    }
                    s_hconf("cbmap", acts2);
                  }}
                  className={twMerge(
                    "transition duration-200 resp-px-2 resp-py-1 text-[10px] text-center",
                    active ? "text-dark bg-r2lig" : "bg-r2reg text-white",
                  )}
                >
                  {cb_txt(cb)}
                </Tag>
              );
            },
          )}
        </div>
      </motion.div>
    </div>
  );
};

const QuestActionRequired = ({ r }) => {
  const history = useNavigate();
  const basesummlink = `/race/${getv(r, "baserace.rid")}`;
  const [checked, set_checked] = useState(false);
  const id = `quest_action_checked:${r.rid}`;
  const refetch = () => {
    const val = localStorage.getItem(id);
    set_checked(val === "true");
  };
  useEffect(() => {
    refetch();
  }, []);
  const upd = (val) => {
    val = val == true ? "true" : "false";
    localStorage.setItem(id, val);
    set_checked(val === "true");
  };
  useEffect(() => {
    console.log("upd", r.rid, checked);
  }, [r.rid, checked]);

  if (getv(r, "baserace.quest_type") !== "progressive") return;
  if (!nils(getv(r, "baserace.user_cashout_option"))) return;
  if (checked) return;

  return (
    <>
      <Tag
        onClick={(e) => {
          e.preventDefault();
          upd(true);
          // window.open(basesummlink, "_self");
          history(basesummlink);
        }}
        to={basesummlink}
        target="_self"
      >
        <span className="text-acc0 resp-text--2 pulse-anim resp-px-2 rounded-md resp-mx-2">
          Check Progression
        </span>
      </Tag>
    </>
  );
};

export const RaceRow_side = ({ race }) => {
  const { tokmap } = useAppContext();
  const { vhids, bikesob } = useAccountContext();
  const r = race;
  const tagcn = "text-[12px] m-0 text-center";

  const { now } = useNowContext();
  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    let e = get_race_rtstatus(r, now);
    return e;
  }, [now, jstr(r)]);

  const bluestars = race.bluestars || [];
  const yellowstars = race.yellowstars || [];

  const [issub, basesummlink] = useMemo(() => {
    if (nils(r)) return [false, null];
    if (
      !["sit_n_go_subround", "sub_rounds", "sub_roundsp", "sub_quest"].includes(
        race.format,
      )
    )
      return [false, null];
    let baserid = r.rid.split("-")[0];
    return [true, `/race/${baserid}`];
  }, [r]);

  const [qo_rginf] = useQueries([
    q_open_hs_in({ rid: r.rid }, { enabled: rtstatus == "open" }),
  ]);
  const [rg_loading, rginf = {}] = useMemo(() => {
    if (qo_rginf.isLoading) return [true, {}];
    let d = getv(qo_rginf, "data.result");
    return [false, d];
  }, [qo_rginf.dataUpdatedAt]);
  return (
    <div className="resp-mx-2 resp-my-3 rounded-md bg-r2lig/20 border border-acc4">
      <Link to={`/race/${r.rid}`} target="_self">
        <div className="w-full">
          <div className="xs:resp-p-2 md:resp-px-1 fr-sc flex-grow xs:flex-wrap md:flex-nowrap w-full">
            <Tag
              className={twMerge(
                tagcn,
                "w-[3.4rem] font-digi italic text-acc0",
              )}
            >
              {cb_txt(r.cb, r.rvmode)}
            </Tag>
            <Tag
              className={twMerge(
                tagcn,
                "w-[4rem] font-digi",
                "bg-acc1",
                // class_cn(r.class),
              )}
            >
              {class_text(r.class, "t")}
            </Tag>
            <div className="flex-1">
              <span className="pl-2 font-digi">{r.race_name}</span>
            </div>
            {r.fee !== 0 ? (
              <div className="resp-text--1 text-green-400">
                ${dec(r.fee_usd, 2)}
              </div>
            ) : (
              <div className="resp-text--1 text-acc0 resp-p-1 rounded-md">
                FREE
              </div>
            )}
            {rtstatus == "open" && rginf && (
              <div className="fr-cc font-digi resp-gap-1 resp-px-2">
                <span>{rginf?.hids?.length ?? "-"}</span>
                <span>/</span>
                <span>{r.rgate}</span>
              </div>
            )}
          </div>
          <div className="xs:resp-p-2 md:resp-px-1 w-full resp-gap-1 flex flex-row">
            <div className="flex-1"></div>
            {rtstatus == "open" && (
              <>
                <span></span>
              </>
            )}
            {rtstatus == "scheduled" && (
              <>
                <span>{"Starting in "}</span>
                <span>{to_time_mini(r.start_time, true)}</span>
              </>
            )}
            {rtstatus == "live" && (
              <Tag className={twMerge(tagcn, "bg-yellow-400 text-black")}>
                LIVE
              </Tag>
            )}
            {rtstatus == "ending" && (
              <Tag className={twMerge(tagcn, "text-purple-400")}>Ending...</Tag>
            )}
            {rtstatus == "finished" && (
              <>
                <div>
                  <span className={twMerge("text-red-400")}>{"Finished "}</span>
                  {from_time_mini(r.start_time)}
                  {" ago"}
                </div>
              </>
            )}
          </div>
        </div>

        <div class="fr-sc resp-px-2 resp-gap-1 resp-text--3 flex-wrap">
          {r.format == "normal" ? (
            <span className={twMerge("text-acc0 uppercase")}>{r.format}</span>
          ) : r.format == "spin_n_go" ? null : r.format == "rounds" ? (
            <span>{getv(r, "r_form.rounds_n")} Rounds</span>
          ) : null}
          {r.payout == "spin_n_go" ? (
            <span className={twMerge("text-red-400")}>
              {get_payout_txt(r.payout)}
            </span>
          ) : (
            <span className={twMerge("text-white")}>
              {get_payout_txt(r.payout)}
            </span>
          )}
          {_.isEmpty(r.eventtags) ? null : (
            <>
              {r.eventtags.map((t) => {
                return (
                  <R2_EventTag
                    tag={t}
                    efil={getv(r, "r_form.entry_filt")}
                    race={r}
                  />
                );
              })}
            </>
          )}
        </div>
      </Link>
      {issub ? (
        <div className="fr-sc w-full gap-0.5 resp-my-1 flex-wrap">
          {r.format == "sub_quest" &&
            rtstatus == "finished" &&
            getv(r, "baserace.quest_type") == "progressive" && (
              <QuestActionRequired r={r} />
            )}
          <div className="flex-1"></div>
          <Link to={basesummlink} target="_self">
            <span className="text-acc0 resp-text--2 border border-acc0 resp-px-2 rounded-md resp-mx-2">
              Summary
            </span>
          </Link>
        </div>
      ) : (
        <div className="fr-cc">
          <div className="flex-1"></div>
          <span className="text-acc0 italic resp-text---2">
            {_.upperCase(r.format)}
          </span>
        </div>
      )}
      <hr className="border border-acc0/20" />
      <div className="grid resp-gap-1 resp-px-1 grid-cols-1 lg:grid-cols-2">
        {r.vrhids.map((hid) => {
          let h = bikesob[hid];
          let star =
            (bluestars.includes(hid) ? 2 : 0) +
            (yellowstars.includes(hid) ? 3 : 0);
          if (nils(h)) return <></>;
          return (
            <div className="fr-sc gap-0.5 resp-my-1 w-full flex-wrap resp-p-1">
              <div className="">
                {nils(h) ? (
                  <span>Bike-{hid}</span>
                ) : (
                  <span className="flex">
                    <BImg hex_code={h.hex_code} className={"w-2 h-2"} />
                    <span>{h.name}</span>
                  </span>
                )}
              </div>
              {star > 0 && <BY_Star star={star} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RaceRow_Rounds_side = ({ race }) => {
  const { tokmap } = useAppContext();
  const { vhids, bikesob } = useAccountContext();
  const r = race;
  const tagcn = "text-[12px] m-0 text-center";

  const { now } = useNowContext();

  return (
    <div className="resp-mx-2 resp-my-3 rounded-md card-basic2-bg border border-acc4">
      <Link to={`/race/${r.rid}`} target="_self">
        <div className="w-full">
          <div className="xs:resp-p-2 md:resp-px-1 fr-sc flex-grow xs:flex-wrap md:flex-nowrap w-full">
            <Tag
              className={twMerge(
                tagcn,
                "w-[3.4rem] font-digi italic text-acc0",
              )}
            >
              {cb_txt(r.cb, r.rvmode)}
            </Tag>
            <Tag
              className={twMerge(
                tagcn,
                "w-[2.8rem] font-digi",
                class_cn(r.class),
              )}
            >
              {class_text(r.class, "t")}
            </Tag>
            <div className="flex-1">
              <span className="pl-2 font-digi">{r.race_name}</span>
            </div>
          </div>
        </div>
      </Link>

      <div className="fr-sc w-full gap-0.5 resp-my-1 flex-wrap">
        <div className="flex-1"></div>
        <Link to={`/race/${r.baserid}`} target="_self">
          <span className="text-acc0 resp-text--2 border border-acc0 resp-px-2 rounded-md resp-mx-2">
            Summary
          </span>
        </Link>
      </div>

      <hr className="border border-acc0/20" />
      <div className="grid resp-gap-1 resp-px-1 grid-cols-1 lg:grid-cols-2">
        {(r.vrhids || []).map((hid) => {
          let h = bikesob[hid];

          if (nils(h)) return <></>;
          return (
            <div className="fr-sc gap-0.5 resp-my-1 w-full flex-wrap resp-p-1">
              <div className="">
                {nils(h) ? (
                  <span>Bike-{hid}</span>
                ) : (
                  <span className="flex">
                    <BImg hex_code={h.hex_code} className={"w-2 h-2"} />
                    <span>{h.name}</span>
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RoundsInfo = ({ race, popupclassName, tagcn }) => {
  const i = getv(race, "r_form");
  const rc = getv(race, "r_form.rounds_conf");
  // useMemo(() => {
  //   console.log(i);
  // }, []);
  const [show, set_show] = useState(false);
  return (
    <div
      onMouseLeave={() => {
        set_show(false);
      }}
      className=""
    >
      <Tag
        onMouseEnter={() => {
          set_show(true);
        }}
        className={twMerge(
          "font-digi italic text-acc0 w-full text-center resp-gap-2 fr-sc",
          tagcn,
        )}
      >
        {i.rounds_n} {_.upperFirst(race?.format)}
      </Tag>
      {show && (
        <Card
          className={twMerge(
            "absolute z-[20] cursor-pointer",
            "xs:top-[2rem] xs:left-[0%]",
            "md:top-[3rem] md:left-[5%]",
            "bg-r2dark/80 backdrop-blur-lg w-max max-w-[35rem] resp-p-1 resp-text--2",
            popupclassName,
          )}
        >
          <table className={tablecn.table}>
            <tbody>
              <tr className="thintdrow text-acc0">
                <td>CB</td>
                <td>#Rd</td>
                <td>#Subraces</td>
                <td>Advancing</td>
                {race.format == "roundsp" && (
                  <>
                    <td>Subpay Type</td>
                    <td>Subpay Value</td>
                  </>
                )}
              </tr>
              {_.entries(rc).map(([rno, r]) => {
                const poss = _.keys(
                  gen_prize_map({ payout: r.payout, prize: 1, rgate: r.in }),
                ).join(", ");
                const posmsg = `Positions: ${poss}`;
                return (
                  <tr className="thintdrow">
                    <td>
                      <span>{r.cb ?? race.cb}</span>
                    </td>
                    <td>
                      <span className="text-acc0 italic font-bold">
                        Rnd-{rno}
                      </span>
                    </td>
                    <td>
                      <div className="fr-sc resp-gap-1">
                        <span className="">{`${r.n} Races x `}</span>
                        <span className="text-acc0 italic">{`${r.in} Gates`}</span>
                        <span className="">{`= ${r.n * r.in} Bikes`}</span>
                      </div>
                    </td>
                    <td>
                      <ToolTip message={posmsg}>
                        <div class="fr-sc resp-gap-1">
                          <Tag
                            className={twMerge(
                              get_payout_cn(r.payout),
                              "resp-text--3",
                            )}
                          >
                            {get_payout_txt(r.payout)}
                          </Tag>
                          {r.adv_points > 0 && (
                            <span class="resp-text--1 text-acc4">
                              +{dec(r.adv_points, 2)}pts
                            </span>
                          )}
                        </div>
                      </ToolTip>
                    </td>

                    {race.format == "roundsp" ? (
                      <>
                        <td className="">
                          <span className="">{r.subpay_type}</span>
                        </td>

                        <td className="">
                          <span className="">{r.subpay_val}</span>
                        </td>
                      </>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      )}
    </div>
  );
};

export const RaceBaseInfoCard = ({
  race,
  rtstatus = "open",
  showwatch = true,
}) => {
  const appcon = useAppContext();

  const { tok_to_usd_val, usd_to_to_val } = appcon;

  const r = race;
  const { rid } = r;
  const c = race.class;
  const cb = race.cb;

  const [qo_rginf] = useQueries([
    r.is_arcade ? q_arcade_open_hs_in({ rid }) : q_open_hs_in({ rid }),
  ]);
  const [rg_loading, rginf] = useMemo(() => {
    if (qo_rginf.isLoading) return [true, {}];
    let d = getv(qo_rginf, "data.result") || {};
    return [false, d];
  }, [qo_rginf.dataUpdatedAt]);

  const accon = useAccountContext();
  const { hids: vhids, bikesob } = accon;
  const rhids_ofv = useMemo(() => {
    if (_.isEmpty(rginf?.hids)) return [];
    let rhids_ofv = _.intersection(rginf.hids, vhids) ?? [];
    return rhids_ofv;
  }, [rginf?.hids, vhids]);

  const rsat_advhidsv = useMemo(() => {
    if (r.is_satellite !== true) return [];
    let advs = getv(r, "satellite.advances_hids") || [];
    let advs_ofv = _.intersection(advs, vhids) ?? [];
    return advs_ofv;
  }, [vhids, jstr(r.satellite)]);

  const [qo_tokpri] = useQueries([q_token_prices()]);

  const { path } = useAppContext();
  const isracepage = path.startsWith("/race/");

  const paytoken = r.paytoken ?? "WETH";

  const rgatessection = (
    <div className="fr-sc xs:w-[2rem] lg:w-[6rem] resp-gap-1.5">
      {rtstatus == "open" ? (
        <>
          {rg_loading ? (
            <Loader01c size="s" />
          ) : (
            <span className="resp-text-2">{rginf?.hs_in}</span>
          )}
          <span>/</span>
          <span>{r.rgate}</span>
        </>
      ) : (
        <>
          <span>{r.rgate} Gates</span>
        </>
      )}
    </div>
  );

  // useEffect(() => {
  //   console.log("r", r);
  // }, [jstr(r)]);

  const rfeesection = (
    <>
      <div className="flex flex-col justify-center items-end">
        <div>
          {r.fee == 0 ? (
            <div className="fr-cc resp-text-1 font-digi resp-gap-2">
              <Tag className="bg-acc0 text-black font-digi transform -skew-x-12 resp-text--2">
                FREE
              </Tag>
              {rgatessection}
            </div>
          ) : (
            <>
              <div className="fr-cc resp-text-1 font-digi resp-gap-2">
                <div
                  className={twMerge(
                    "fr-sc resp-gap-2",
                    "border-b-2",
                    paytoken == "DEZ" ? "border-acc0" : "border-pink-400",
                  )}
                >
                  <div
                    className={twMerge(
                      "flex flex-row justify-end items-center w-[6rem] text-yellow-200 resp-gap-1",
                    )}
                  >
                    <FontAwesomeIcon icon={faUsd} />
                    <span>{dec(r.fee_usd)}</span>
                  </div>
                  <div className="bg-white w-1 h-6 rounded-md bg-opacity-40"></div>
                  {rgatessection}
                </div>
              </div>
              <p
                className={twMerge(
                  "my-1 resp-text--2 w-full text-right",
                  paytoken == "DEZ" ? "text-acc0" : "text-pink-400",
                )}
              >
                Pay with {paytoken == "DEZ" ? "$DEZ" : "WETH"}
              </p>
            </>
          )}
        </div>

        {(r.format == "spin_n_go" ||
          ["spin_n_go", "pityspin", "lowspin"].includes(r.payout)) && (
          <>
            <Dropdown
              wrapcn=""
              trigger={
                <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                  <span>Prize Pool</span>
                  <Tag>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Tag>
                </div>
              }
              dropcn={"w-max top-[2rem] right-0"}
            >
              <Card className={"bg-reg border border-green-400 w-full"}>
                {(rtstatus == "open" && (
                  <>
                    <p className="text-yellow-300 resp-text--1">
                      Prize Pool is revealed when the race is scheduled
                    </p>
                  </>
                )) || (
                  <div className="grid grid-cols-3 resp-gap-2 resp-text--2">
                    {_.entries(r.prize_map).map((e) => {
                      return (
                        <>
                          <PosTag pos={e[0]} className="m-0 resp-py-1" />
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <TokenIcon token={r.paytoken} size="xs" />
                            <span className="">
                              {dec(e[1], tokdecn(paytoken))}
                            </span>
                          </div>
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <FontAwesomeIcon className="" icon={faUsd} />
                            <span className="">
                              {dec(
                                tok_to_usd_val(e[1], paytoken),
                                tokdecn("USD"),
                              )}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </Card>
            </Dropdown>
          </>
        )}

        {r.format == "sit_n_go" && (
          <>
            <Dropdown
              wrapcn=""
              trigger={
                <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                  <span>Prize Pool</span>
                  <span>
                    <FontAwesomeIcon icon={faUsd} />
                  </span>
                  <span>
                    {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
                  </span>
                  <span>**</span>
                  <Tag>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Tag>
                </div>
              }
              dropcn={"w-max top-[2rem] right-0"}
            >
              <Card
                className={
                  "bg-reg border border-green-400 w-full max-w-[20rem]"
                }
              >
                <p className="text-center text-yellow-500 resp-my-2">
                  {getv(r, "format_inf.prizepool_msg")}
                </p>
                <div className="grid grid-cols-3 resp-gap-2">
                  {_.entries(r.prize_map).map((e) => {
                    return (
                      <>
                        <PosTag
                          pos={e[0]}
                          className="resp-text--2 m-0 resp-py-1"
                        />
                        <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                          <TokenIcon token={r.paytoken} size="xs" />
                          <span className="">
                            {dec(e[1], tokdecn(paytoken))}
                          </span>
                        </div>
                        <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                          <FontAwesomeIcon icon={faUsd} />
                          <span className="">
                            {dec(tok_to_usd_val(e[1]), tokdecn("USD"))}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Card>
            </Dropdown>
          </>
        )}

        {r.format == "rounds" && r.prize > 0 && (
          <>
            <Dropdown
              wrapcn=""
              trigger={
                <div className="fc-ss">
                  <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                    <span>Full Capacity Prize Pool</span>
                    <span>
                      <FontAwesomeIcon icon={faUsd} />
                    </span>
                    <span>
                      {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
                    </span>
                    <span>**</span>
                    <Tag>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Tag>
                  </div>
                  {r.class !== 72 && (
                    <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                      <span>Guranteed[30%]</span>
                      <span>
                        <FontAwesomeIcon icon={faUsd} />
                      </span>
                      <span>
                        {dec(
                          tok_to_usd_val(
                            getv(r, "r_form.thresh_prize"),
                            r.paytoken,
                          ),
                          tokdecn("USD"),
                        )}
                      </span>
                      <span>**</span>
                      <Tag>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Tag>
                    </div>
                  )}
                </div>
              }
              dropcn={"w-max top-[2rem] right-0"}
            >
              <Card
                className={
                  "bg-reg border border-green-400 w-full max-w-[20rem]"
                }
              >
                <p className="text-center text-yellow-500 resp-my-2">
                  {getv(r, "format_inf.prizepool_msg")}
                </p>
                <div className="grid grid-cols-3 resp-gap-2">
                  {_.entries(r.prize_map).map((e) => {
                    return (
                      <>
                        <PosTag
                          pos={e[0]}
                          className="resp-text--2 m-0 resp-py-1"
                        />
                        <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                          <TokenIcon token={r.paytoken} size="xs" />
                          <span>{dec(e[1], tokdecn(paytoken))}</span>
                        </div>
                        <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                          <FontAwesomeIcon icon={faUsd} />
                          <span>
                            {dec(
                              tok_to_usd_val(e[1], r.paytoken),
                              tokdecn("USD"),
                            )}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Card>
            </Dropdown>
          </>
        )}

        {(r.format == "normal" ||
          r.format == "reduced" ||
          (r.format == "teams" &&
            !["spin_n_go", "pityspin", "lowspin"].includes(r.payout))) && (
          <>
            {(r.prize === 0 && <></>) || (
              <Dropdown
                wrapcn=""
                trigger={
                  <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                    <span>Prize Pool</span>
                    <span>
                      <FontAwesomeIcon icon={faUsd} />
                    </span>
                    <span>
                      {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
                    </span>
                    <Tag>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Tag>
                  </div>
                }
                dropcn={"w-max top-[2rem] right-0"}
              >
                <Card className={"bg-reg border border-green-400 w-full"}>
                  <div className="grid grid-cols-3 resp-gap-2 resp-text--2">
                    {_.entries(r.prize_map).map((e) => {
                      return (
                        <>
                          <PosTag
                            pos={e[0]}
                            className="resp-text--2 m-0 resp-py-1"
                          />
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <TokenIcon token={r.paytoken} size="xs" />
                            <span>{dec(e[1], tokdecn(paytoken))}</span>
                          </div>
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <FontAwesomeIcon icon={faUsd} />
                            <span>
                              {dec(
                                tok_to_usd_val(e[1], r.paytoken),
                                tokdecn("USD"),
                              )}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Card>
              </Dropdown>
            )}
          </>
        )}
      </div>
    </>
  );

  const rdetstags = (
    <>
      <div className="fr-sc">
        <Tag
          className={twMerge(
            "font-digi mx-0 resp-px-2 text-center text-acc0 bg-transparent",
          )}
        >
          CB{r.cb}00
        </Tag>
        <Tag
          className={twMerge(
            "font-digi mx-0 resp-px-2 text-center transform -skew-x-12",
            class_cn(r.class),
          )}
        >
          {class_text(r.class, "t")}
        </Tag>
        <div className="w-[1rem] resp-text-3 text-center text-slate-500">|</div>
        <Tag
          className={twMerge(
            "font-mon text-center w-max",
            get_payout_cn(r.payout),
          )}
        >
          {get_payout_txt(r.payout)}
        </Tag>
        {r.format == "rounds" ? (
          <RoundsInfo race={r} />
        ) : (
          <Tag
            className={twMerge(
              "font-digi italic text-acc0 border-2 border-acc0 w-full text-center resp-gap-2 fr-sc",
            )}
          >
            <span>{_.upperCase(r.format)}</span>
          </Tag>
        )}
      </div>
    </>
  );

  const is_trainer = useMemo(() => {
    if (_.isEmpty(race)) return false;
    return race.eventtags.includes("Trainer");
  }, [jstr(race)]);

  const redexp = getv(r, "r_form.reduction_expected");

  const rnamerow = (
    <>
      <div className="fc-ss">
        {
          <div className="fr-sc resp-gap-2 w-full resp-p-2">
            <div class="fc-ss">
              <div class="fr-sc">
                <span className="text-red-400 italic font-mon resp-text--1">
                  v{r.version}::
                </span>
                <span className="text-acc0 italic font-mon resp-text--1">
                  {r.presetid}
                </span>
              </div>
              <div class="d">
                <span className="text-orange-400 italic font-mon resp-text--1">
                  {r.bulkid}
                </span>
              </div>
            </div>
            <div className="flex-1"></div>
            {!nils(redexp) &&
              (redexp > iso() ? (
                <span className="text-yellow-400 resp-text--2">
                  Reduction in {from_time_mini(redexp)}
                </span>
              ) : (
                <span className="text-yellow-400">
                  {getv(r, "r_form.exp_schedule_msg")}
                </span>
              ))}
          </div>
        }
        <div className="fr-sc resp-gap-2 w-full">
          <div className="resp-px-4 font-digi resp-text-1 italic resp-py-2">
            {r.race_name}
          </div>

          {isracepage == false && (
            <>
              <Link target="_self" to={`/race/${rid}`}>
                <Tag className="text-white">
                  <FontAwesomeIcon className="resp-text-1" icon={faLink} />
                </Tag>
              </Link>
            </>
          )}
          <>
            <Tag
              onClick={() =>
                copy_clip(`https://fbike.dnaracing.run/race/${rid}`)
              }
              className="text-white"
            >
              <FontAwesomeIcon className="resp-text-1" icon={faShareNodes} />
            </Tag>
          </>

          {is_trainer && (
            <>
              <div class="flex-1"></div>
              <RaceLinksTrainerInfo />
            </>
          )}
        </div>
      </div>
    </>
  );

  const r_vauhsin = (
    <>
      <div className="w-max mx-auto grid xs:grid-cols-1 lg:grid-cols-3 resp-gap-1">
        {rhids_ofv.map((hid) => {
          let h = bikesob[hid];
          if (nils(h)) return <></>;
          return (
            <div key={hid} className="resp-px-2 resp-py-1 fr-sc">
              <BImg hex_code={h.hex_code} className={"w-3 h-3"} />
              <span className="resp-px-2 resp-text-0">{h.name}</span>
            </div>
          );
        })}
      </div>
    </>
  );

  const efil = getv(r, "r_form.entry_filt");

  const r_entry = nils(efil) ? (
    <></>
  ) : (
    <>
      <div class="fr-sc resp-gap-0">
        <span className="resp-px-2 resp-text--1 font-digi italic">
          Criteria
        </span>
        {r.eventtags.map((tag) => {
          return <EventTag tag={tag} efil={efil} race={r} />;
        })}
      </div>
      {/*
      <div className="fr-sc">
        <span className="resp-px-2 resp-text--1 font-digi italic">
          Criteria
        </span>
        {!_.isEmpty(efil.type) &&
          efil.type.map((e) => (
            <Tag className="bg-slate-700 transform -skew-x-12 fr-sc resp-gap-1 text-white">
              <span>{_.capitalize(e)}</span>
            </Tag>
          ))}

        {!_.isEmpty(efil.element) &&
          efil.element.map((e) => (
            <Tag
              className={twMerge(
                "bg-white transform -skew-x-12 fr-sc resp-gap-1",
                elementmap[e]?.bg,
              )}
            >
              <FontAwesomeIcon icon={elementmap[e]?.icon} />
              <span>{_.capitalize(e)}</span>
            </Tag>
          ))}

        {!_.isEmpty(efil.gender) &&
          efil.gender.map((e) => (
            <Tag
              className={twMerge(
                "transform -skew-x-12 fr-sc resp-gap-1",
                gendermap[e]?.bg,
              )}
            >
              <FontAwesomeIcon icon={gendermap[e]?.icon} />
              <span>{_.capitalize(e)}</span>
            </Tag>
          ))}

        {!_.isEmpty(efil.fno) && (
          <Tag className="transform -skew-x-12 fr-sc resp-gap-2 bg-transparent text-acc0">
            {!_.isEmpty(efil.fno) && _.isObject(efil.fno) && (
              <span className="resp-text-1">
                {!nils(efil.fno.mi) && !nils(efil.fno.mx)
                  ? `F${efil.fno.mi}-${efil.fno.mx}`
                  : !nils(efil.fno.mi) && nils(efil.fno.mx)
                    ? `F${efil.fno.mi}+`
                    : !nils(efil.fno.mx) && nils(efil.fno.mi)
                      ? `F${efil.fno.mx}-`
                      : ""}
              </span>
            )}
            {!_.isEmpty(efil.fno) &&
              _.isArray(efil.fno) &&
              efil.fno.map((f) => (
                <span className="resp-text-1">{`F${f}`}</span>
              ))}
          </Tag>
        )}

        {!nils(efil.skin) && (
          <>
            {efil.skin.by == "any" && (
              <Tag className="bg-orange-500 text-white">{"SKIN: Any"}</Tag>
            )}
            {["rarity", "name", "pack"].includes(efil.skin?.by) && (
              <>
                <Tag className="bg-orange-500 text-white">
                  {"SKIN:"}
                  {_.capitalize(efil.skin?.by)}
                </Tag>
                {efil.skin?.ar?.map((e) => {
                  return (
                    <span className="text-orange-500 underline underline-orange-500 underline-offset-2 italic px-1 resp-text--2">
                      {e}
                    </span>
                  );
                })}
              </>
            )}
          </>
        )}

        {!nils(efil.claim_limit) && (
          <>
            <Tag className="text-blue-300 resp-text--1 underline underline-blye-300 underline-offset-4">{`Claim Limit: ${parseFloat(
              efil.claim_limit,
            ).toFixed(4)}WETH`}</Tag>
          </>
        )}
        {!nils(efil.birthday) ? (
          <>
            {_.isString(efil.birthday) && (
              <>
                <Tag className="bg-yellow-300 uppercase text-black">
                  {`${efil.birthday}`}
                </Tag>
              </>
            )}
            {_.isObject(efil.bithday) && (
              <>
                <span>Birthday</span>
                <span>{efil.birthday.mi}</span>
                <span>{efil.birthday.mx ?? ""}</span>
              </>
            )}
          </>
        ) : (
          <></>
        )}

        <FiltTag
          {...{
            className: "bg-yellow-300 text-black",
            o: efil,
            k: "win_p",
            txt: "Win:",
            view: "per",
            r: race,
          }}
        />
        <FiltTag
          {...{
            className: "bg-yellow-300 text-black",
            o: efil,
            k: "races_n",
            txt: "Races:",
            view: "n",
            r: race,
          }}
        />

        <FiltTag
          {...{
            className: "bg-green-300 text-black",
            o: efil,
            k: "paid_win_p",
            txt: "Win:",
            view: "per",
            r: race,
          }}
        />
        <FiltTag
          {...{
            className: "bg-green-300 text-black",
            o: efil,
            k: "paid_races_n",
            txt: "Races",
            view: "n",
            r: race,
          }}
        />
      </div>

        */}
      {is_trainer && (
        <p class="text-left resp-p-2 resp-text--2">{trainer_market_txt}</p>
      )}
    </>
  );

  const [is_maiden, sat_redlink] = useMemo(() => {
    if (r.is_satellite !== true) return [null, null];
    let is_maiden = getv(r, "satellite.is_maiden") === true;
    if (is_maiden) return [is_maiden, `/maiden/${getv(r, "satellite.satid")}`];
    else return [is_maiden, `/satellitesv2/${getv(r, "satellite.satid")}`];
  }, [jstr(r.satellite)]);

  const r_satellite = r.is_satellite ? (
    <div>
      <div className="fr-sc w-full my-2">
        {r.version >= 2 ? (
          <>
            <Link to={sat_redlink}>
              {is_maiden ? (
                <Tag className="border border-acc0 text-acc0 -skew-x-12">
                  <FontAwesomeIcon icon={faHackerNews} />
                  <span>{" Maiden "}</span>
                </Tag>
              ) : (
                <Tag className="border border-acc0 text-acc0 -skew-x-12">
                  <FontAwesomeIcon icon={faSatelliteDish} />
                  <span>{" SATELLITE "}</span>
                  <span>{parseInt(getv(r, "satellite.sat_per") * 100)}%</span>
                </Tag>
              )}
            </Link>
            {getv(r, "satellite.filled") == true && (
              <Link to={`/race/${getv(r, "satellite.satid")}`}>
                <Tag className="border border-green-400 text-green-400 -skew-x-12">
                  <FontAwesomeIcon icon={faFlagCheckered} />
                </Tag>
              </Link>
            )}
          </>
        ) : (
          <Link to={`/satellite/${getv(r, "satellite.mega_rid")}`}>
            <Tag className="border border-acc0 text-acc0 -skew-x-12">
              <FontAwesomeIcon icon={faSatelliteDish} />
              <span>{" SATELLITE "}</span>

              <span>{parseInt(getv(r, "satellite.varsat") * 100)}%</span>
            </Tag>
          </Link>
        )}
        <div className="flex-1">
          {rtstatus == "open" || rtstatus == "scheduled" ? (
            <div className="w-full mx-auto grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-1">
              {rsat_advhidsv.map((hid) => {
                let h = bikesob[hid];
                if (nils(h)) return <></>;
                return (
                  <div key={hid} className="resp-px-2 resp-py-1 fr-sc">
                    <BImg hex_code={h.hex_code} className={"w-3 h-3"} />
                    <span className="resp-px-2 resp-text-0">{h.name}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  return (
    <>
      {race.is_arcade == true && (
        <div className="fr-sc w-full">
          <div className="flex-1"></div>
          <>
            <ContactTag
              link={"https://discord.gg/j4k3NdY6St"}
              icon={faDiscord}
              txt={<>Discord</>}
            />
            <ContactTag
              link={"https://market.dnaracing.run"}
              icon={faMapMarkedAlt}
              txt={
                <div className="fc-cc">
                  <span>DNA Market</span>
                  <span className="resp-text--2 text-acc0">Buy A Core Now</span>
                </div>
              }
            />
          </>
        </div>
      )}
      <div className="xs:block lg:hidden">
        {rnamerow}
        {rdetstags}
        <div className="fr-ss w-full">
          {r_vauhsin}
          {rfeesection}
        </div>
        {r_entry}
        {r_satellite}
      </div>

      <div className="xs:hidden lg:block">
        {rnamerow}
        <div className="fr-sc">
          {rdetstags}
          <div className="flex-1"></div>
          {rfeesection}
        </div>
        {r_entry}
        {r_satellite}

        <div className="fr-sc">
          <div className="flex-1 ">{r_vauhsin}</div>
        </div>
      </div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 resp-text--1">
        {rtstatus == "cancelled" && (
          <>
            <div className="fr-">
              <span>Status:</span>
              <Tag className="bg-red-400 text-black font-mon">Cancelled</Tag>
            </div>
          </>
        )}

        {rtstatus == "scheduled" && (
          <>
            <div className="fr-sc resp-gap-2">
              <span>Status:</span>
              <Tag className="bg-yellow-400 text-black font-mon">Scheduled</Tag>
            </div>
            {showwatch && <WatchRow />}

            <div className="fr-sc resp-gap-2">
              <span className="text-yellow-300 font-semibold">
                {"Starting in "}
              </span>
              <span className="text-yellow-300 font-semibold">
                {to_time_mini(r.start_time, true)}
              </span>
            </div>
          </>
        )}

        {rtstatus == "live" && (
          <>
            <div className="fr-sc resp-gap-2">
              <span>Status:</span>
              <Tag className="bg-yellow-400 text-black font-mon">LIVE</Tag>
            </div>
            {showwatch && <WatchRow />}
          </>
        )}

        {rtstatus == "finished" && (
          <>
            <div className="fc-cs resp-gap-2">
              <div className="fr-cc">
                <span>Status:</span>
                <Tag className="bg-green-400 text-black font-mon">Finished</Tag>
              </div>
              <div className="text-green-400">
                {`Race ran started at ${iso_format(r.start_time)}`}
              </div>
              <div className="text-green-400">
                {`Race ran ended at ${iso_format(r.end_time)}`}
              </div>
              <div className="text-green-400">
                {`Took ${moment(r.end_time).diff(
                  r.start_time,
                  "seconds",
                )} seconds`}
              </div>
            </div>
            {showwatch && <WatchRow />}
          </>
        )}
      </div>
    </>
  );
};

export const postxt = (pos) => {
  if (_.isString(pos) && pos.startsWith("rand-")) return "Rand";
  if (nils(parseInt(pos))) return _.upperCase(pos);

  return (
    (pos > 3 && `${pos}th`) ||
    (pos == 1 && `1st`) ||
    (pos == 2 && `2nd`) ||
    (pos == 3 && `3rd`) ||
    ""
  );
};

export const PosTag = ({ pos, className }) => {
  return (
    <Tag
      className={twMerge(
        className,
        pos <= 3 ? `bg-pos${pos} text-black` : "bg-slate-600 text-white",
      )}
    >
      {postxt(pos)}
    </Tag>
  );
};

export const FiltTag = ({ o, k, r, txt, className, view }) => {
  let mi_o = getv(o, `${k}_mi`);
  let mx_o = getv(o, `${k}_mx`);
  if (nils(mi_o) && nils(mx_o)) return <></>;

  let mi = mi_o && mi_o.v;
  let mx = mx_o && mx_o.v;
  let u = mi_o?.u || mx_o?.u || "ov";

  // console.log(mi, mx);

  let ispaid = k.includes("paid");
  let message =
    u == "ov"
      ? `considering overall ${ispaid ? "PAID" : ""} races`
      : u == "cb"
        ? `considering only CB${r.cb}00 ${ispaid ? "PAID" : ""} races`
        : "";

  let viewfn = (v) => (view == "per" ? `${dec(v * 100, 1)}%` : `${v}`);
  return (
    <ToolTip message={message} msg_cn="w-[8rem] resp-text--3 leading-4">
      <Tag
        className={twMerge("fr-cc resp-gap-1 transform -skew-x-12", className)}
      >
        <span>{txt}</span>
        {!nils(mi) && (
          <span>
            {">"}
            {viewfn(mi)}
          </span>
        )}
        {!nils(mx) && (
          <span>
            {"<"}
            {viewfn(mx)}
          </span>
        )}
      </Tag>
    </ToolTip>
  );
};

export const bez_get_controlpoints = (bez) => {
  let reg = /^cubic-bezier\(([\.\d]+),([\.\d]+),([\.\d]+),([\.\d]+)\)$/i;
  let regmat = reg.exec(bez);
  // console.log(regmat);
  let controlpoints = [
    { x: 0, y: 0 },
    { x: parseFloat(regmat[1]), y: parseFloat(regmat[2]) },
    { x: parseFloat(regmat[3]), y: parseFloat(regmat[4]) },
    { x: 1, y: 1 },
  ];
  return controlpoints;
};

export const bez_y_from_x = (x, ps, xs, ys) => {
  let [x1, x2] = xs;
  let [y1, y2] = ys;
  let de_x = x2 - x1;
  let de_y = y2 - y1;
  let t = (x - x1) / de_x;

  const [P0, P1, P2, P3] = ps;
  const term1 = Math.pow(1 - t, 3) * P0.x;
  const term2 = 3 * Math.pow(1 - t, 2) * t * P1.x;
  const term3 = 3 * (1 - t) * Math.pow(t, 2) * P2.x;
  const term4 = Math.pow(t, 3) * P3.x;

  let y = term1 + term2 + term3 + term4;
  y = y1 + y * de_y;
  return y;
};

export const BY_Star = ({ star, className }) => {
  const [bluestar, yellowstar] = useMemo(() => {
    if (star == 5) return [1, 1];
    else if (star == 3) return [0, 1];
    else if (star == 2) return [1, 0];
    else return [0, 0];
  }, [star]);

  return (
    <div className="flex flex-row justify-center items-center resp-gap-1 resp-px-2 resp-my-1">
      {bluestar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-blue-400", className)}
        />
      )}
      {yellowstar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-yellow-400", className)}
        />
      )}
    </div>
  );
};

const payouts_ob = {
  "1v1": [[1, 1]],
  wta: [[1, 1]],
  even2: [
    [1, 0.5],
    [2, 0.5],
  ],
  top2: [
    [1, 0.7],
    [2, 0.3],
  ],
  top3: [
    [1, 0.65],
    [2, 0.25],
    [3, 0.1],
  ],
  top4: [
    [1, 0.25],
    [2, 0.25],
    [3, 0.25],
    [4, 0.25],
  ],
  hybrid: [
    [1, 0.474],
    [3, 0.263],
    [5, 0.263],
  ],
  heavy: [
    [1, 0.6],
    [2, 0.2],
    [3, 0.2],
  ],
  pro: [
    [1, 0.466],
    [2, 0.2],
    [3, 0.167],
    [4, 0.167],
  ],
  variance: [
    [1, 0.5],
    [2, 0.25],
    [6, 0.25],
  ],
  revvar: [
    [6, 0.5],
    [5, 0.25],
    [1, 0.25],
  ],
  left: [
    [1, 0.4],
    [2, 0.4],
    [3, 0.2],
  ],
  rng: [
    [1, 0.7],
    ["rand-1", 0.3],
  ],
};

const gen_prize_map = ({ payout, prize, rgate }) => {
  // console.log("gen_prize_map", { payout, prize, rgate });
  let o = {};

  if (payout == "na" || nils(payout)) return {};

  let regmat = null;

  let pmap = [];
  if (payout == "dblup") {
    let till = parseInt(rgate / 2);
    let ea = parseInt((1 / till) * 1e4) / 1e4;
    pmap = [];
    for (let i = 0; i < till; i++) {
      pmap.push([i + 1, ea]);
    }
  } else if (payout == "revdu") {
    let from = Math.ceil(rgate / 2) + 1;
    let n = rgate - from + 1;
    let ea = parseInt((1 / n) * 1e4) / 1e4;
    pmap = [];
    for (let i = from; i <= rgate; i++) {
      pmap.push([i, ea]);
    }
  } else if (payout == "pity") {
    pmap = [
      [3, 0.6],
      [2, 0.2],
      [4, 0.2],
    ];
  } else if (payout == "pitylow") {
    pmap = [
      [5, 0.6],
      [4, 0.2],
      [6, 0.2],
    ];
  } else if (payout == "last") {
    let winp = rgate;
    pmap = [[winp, 1]];
  } else if ((regmat = payout.match(/pos-(\d+)/))) {
    let winp = parseInt(regmat[1]);
    pmap = [[winp, 1]];
  } else if ((regmat = payout.match(/pos-range-\[(\d+),(\d+)\]/))) {
    let [a, b] = [parseInt(regmat[1]), parseInt(regmat[2])];
    pmap = [];
    let each = 1 / (b - a + 1);
    // console.log({ each });
    for (let p = a; p <= b; p++) {
      pmap.push([p, each]);
    }
  } else if ((regmat = payout.match(/pos-many-\[(.*)\]/))) {
    let ar = _.chain(regmat[1].split(","))
      .map((e) => Number(e))
      .value();
    pmap = [];
    let each = 1 / ar.length;
    // console.log({ each });
    ar.map((p) => {
      pmap.push([p, each]);
    });
  } else if (payout == "even") {
    let [a, b] = [1, rgate];
    pmap = [];
    let each = 1 / (b - a + 1);
    for (let p = a; p <= b; p++) {
      pmap.push([p, each]);
    }
  } else if (payout == "revwta") {
    pmap = [[3, 1]];
  } else {
    pmap = payouts_ob[payout];
  }

  if (nils(pmap)) return null;

  for (let [pos, frac] of pmap) {
    o[pos] = parseFloat(dec(frac * prize, 6));
  }
  return o;
};

export const get_race_rtstatus = (r, now) => {
  if (nils(r)) return [null, null, null];
  let { start_time, end_time, status } = r;
  let st = nano(start_time);
  let ed = nils(end_time) ? st + 200 * 1e3 : nano(end_time);

  if (status && status == "cancelled") return ["cancelled", null, null];
  if (status && status == "open") return ["open", null, null];

  if (now > ed) return ["finished", null, null];

  let rem_st = parseInt((st - now) / 1000);
  let rem_ed = parseInt((ed - now) / 1000);
  if (_.inRange(now, st, ed) || rem_st < 10) return ["live", rem_st, rem_ed];

  if (now < st) return ["scheduled", rem_st, rem_ed];

  return [null, null, null];
};

export const ColorCircle = ({ className, color }) => {
  return (
    <span
      className={twMerge(
        "w-[2rem] h-[2rem] rounded-full",
        className,
        `bg-${color}`,
      )}
    ></span>
  );
};

const EventTag = ({ tag = null, efil = {} }) => {
  let jsx = useMemo(() => {
    if (/((F[\d]+-[\d]+)|(F[\d]+\+))/i.exec(tag) && !_.isEmpty(efil?.fno)) {
      return (
        <Tag className="transform -skew-x-12 fr-sc resp-gap-2 bg-transparent text-acc0">
          {!_.isEmpty(efil.fno) && _.isObject(efil.fno) && (
            <span className="resp-text-1">
              {!nils(efil.fno.mi) && !nils(efil.fno.mx)
                ? `F${efil.fno.mi}-${efil.fno.mx}`
                : !nils(efil.fno.mi) && nils(efil.fno.mx)
                  ? `F${efil.fno.mi}+`
                  : !nils(efil.fno.mx) && nils(efil.fno.mi)
                    ? `F${efil.fno.mx}-`
                    : ""}
            </span>
          )}
          {!_.isEmpty(efil.fno) &&
            _.isArray(efil.fno) &&
            efil.fno.map((f) => <span className="resp-text-1">{`F${f}`}</span>)}
        </Tag>
      );
    } else if (["male", "female"].includes(tag.toLowerCase())) {
      let el = tag.toLowerCase();
      return (
        <Tag
          className={twMerge(
            "transform -skew-x-12 fr-sc resp-gap-1",
            gendermap[el]?.bg,
          )}
        >
          <FontAwesomeIcon icon={gendermap[el]?.icon} />
          <span>{_.capitalize(tag)}</span>
        </Tag>
      );
    } else if (
      ["earth", "water", "fire", "metal"].includes(tag.toLowerCase())
    ) {
      let el = tag.toLowerCase();
      return (
        <Tag
          className={twMerge(
            "transform -skew-x-12 fr-sc resp-gap-1",
            elementmap[el]?.bg,
          )}
        >
          <FontAwesomeIcon icon={elementmap[el]?.icon} />
          <span>{_.capitalize(tag)}</span>
        </Tag>
      );
    } else if (
      ["genesis", "morphed", "freak", "xclass"].includes(tag.toLowerCase())
    ) {
      let ty = tag.toLowerCase();
      return (
        <Tag
          className={twMerge(
            "transform -skew-x-12 fr-sc resp-gap-1",
            "bg-slate-700",
          )}
        >
          <span>{_.capitalize(ty)}</span>
        </Tag>
      );
    } else if (tag == "Birthday") {
      return (
        <>
          <span>Birthday</span>
          <span>{efil.birthday.mi}</span>
          <span>{efil.birthday.mx ?? ""}</span>
        </>
      );
    } else if (tag.startsWith("Skin") && !nils(efil.skin)) {
      return (
        <>
          {efil.skin.by == "any" && (
            <Tag className="bg-orange-500 text-white">{"SKIN: Any"}</Tag>
          )}
          {["rarity", "name", "pack"].includes(efil.skin?.by) && (
            <>
              <Tag className="bg-orange-500 text-white">
                {"SKIN:"}
                {_.capitalize(efil.skin?.by)}
              </Tag>
              {efil.skin?.ar?.map((e) => {
                return (
                  <span className="text-orange-500 underline underline-orange-500 underline-offset-2 italic px-1 resp-text--2">
                    {e}
                  </span>
                );
              })}
            </>
          )}
        </>
      );
    } else if (tag.startsWith("CLM")) {
      return <Tag className="fr-sc bg-acc0/20 -skew-x-12">{tag}</Tag>;
      return (
        <>
          <Tag className="text-blue-300 resp-text--1 underline underline-blye-300 underline-offset-4">{`Claim Limit: ${parseFloat(
            efil.claim_limit,
          ).toFixed(4)}WETH`}</Tag>
        </>
      );
    } else {
      return <Tag className="fr-sc bg-acc0/20 -skew-x-12">{tag}</Tag>;
    }
  }, [tag, jstr(efil)]);
  return <div className="fr-sc resp-gap-0">{jsx}</div>;
};

export const LB_Skew_Card = (props) => {
  return (
    <div className="lbskew-card border border-acc4 m-1 ">
      <div className="inner">{props.children}</div>
    </div>
  );
};
