import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useVault3DContext } from "./Vault3D.js";
import {
  Center,
  OrbitControls,
  PerspectiveCamera,
  useHelper,
} from "@react-three/drei";
import { useModelStoreCt, useModelsStore } from "../components/ModelsStore.js";
import { Effects } from "../components/Effects.js";
import useGLTF_cloned from "../components/useGLTF_cloned.js";
import { useFrame } from "@react-three/fiber";
import CameraControls, {
  useCameraControls,
} from "../components/CameraControls.js";
import { DirectionalLightHelper } from "three";
import { bikes_skinned_mapob } from "../components/bikes_skinned.js";
import TronBike from "../components/TronBike.js";
import TronBikeSkinned from "../components/TronBikeSkinned.js";
import Car from "../components/Car.js";
import Horse from "../components/Horse.js";

export const Vault3DPlayer = (props) => {
  const vcon = useVault3DContext();
  const { selhid, selskin, seltrail, bikesob, skinsmapob, rvmode, set_rvmode } =
    vcon;

  const h = bikesob[selhid] || null;
  const overrideskin = skinsmapob[selskin];
  const modelstore = useModelsStore();
  const loaded = modelstore.loaded && selhid && h;

  const trackpiece = modelstore.get_model("trackpiece");
  const cl_tr = useGLTF_cloned(trackpiece);

  const camct = useCameraControls();
  useEffect(() => {
    if (camct.loaded !== true) return;
    let cam = camct.orbit.object;
    cam.position.set(1.99, 0.48, 1.66);
  }, [camct.loaded]);

  useEffect(() => {
    console.log({ selskin, overrideskin, seltrail });
  }, [overrideskin, seltrail]);

  const lde = {
    dirl_position: { x: 4.0, y: 5.0, z: 0 },
  };

  const spskin = bikes_skinned_mapob[selskin];

  return (
    <>
      <directionalLight
        position={[
          lde.dirl_position.x,
          lde.dirl_position.y,
          lde.dirl_position.z,
        ]}
        intensity={0.5}
      />
      <ambientLight intensity={1.5} />
      <color attach={"background"} args={["#000000"]} />

      <Effects />
      <CameraControls />

      {cl_tr && (
        <group position-x={-40} position-y={-0.42}>
          <primitive object={cl_tr.scene} />
        </group>
      )}

      <>
        {loaded ? (
          <>
            <>
              {rvmode == "car" ? (
                <>
                  <group position={[1, -0.27, 0]}>
                    <group scale={0.7}>
                      <Car
                        position-x={-0.4}
                        bike={h}
                        overrideskin={overrideskin}
                        overridetrail={seltrail}
                        key={`${h?.hid}-${spskin?.skin}-${seltrail}`}
                      />
                    </group>
                  </group>
                </>
              ) : rvmode == "bike" ? (
                <>
                  <group position={[1, -0.27, 0]}>
                    <group scale={1.5}>
                      {!_.isEmpty(spskin) ? (
                        <TronBikeSkinned
                          position-x={-0.4}
                          bike={h}
                          overrideskin={overrideskin}
                          overridetrail={seltrail}
                          special_skin={spskin}
                          key={`${h?.hid}-${spskin?.skin}-${seltrail}`}
                        />
                      ) : (
                        <TronBike
                          position-x={-0.4}
                          bike={h}
                          overrideskin={overrideskin}
                          overridetrail={seltrail}
                          key={`${h?.hid}-${spskin?.skin}-${seltrail}`}
                        />
                      )}
                    </group>
                  </group>
                </>
              ) : rvmode == "horse" ? (
                <>
                  <group position={[1, -0.27, 0]}>
                    <group scale={1}>
                      <Horse
                        position-x={-0.4}
                        bike={h}
                        overrideskin={overrideskin}
                        overridetrail={seltrail}
                        key={`${h?.hid}-${spskin?.skin}-${seltrail}`}
                      />
                    </group>
                  </group>
                </>
              ) : null}
            </>
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
};
