import React, { useMemo } from "react";
import { useState } from "react";
import { dec, getv, iso_format, jstr, nils, trim_n } from "../utils/utils.js";
import { twMerge } from "tailwind-merge";
import { elementmap, gendermap, tablecn } from "../utils/cn_map.js";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tokdecn, useAppContext } from "../App.js";
import { useQueries } from "react-query";
import { useParams } from "react-router";
import {
  polytxnidlink,
  polytxnlink,
  q_splicing_activity,
  q_vaultinfo,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Img,
  Tag,
  TokenIcon,
  ToolTip,
} from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { Link } from "react-router-dom";
import { polychainimg, prizebox_img } from "../utils/links";
import { Helmet } from "react-helmet-async";
import { useAuthContext } from "../wrappers/AuthWrapper.js";

const SpActivityContext = React.createContext();
const useSpActivity = () => React.useContext(SpActivityContext);

const SpliceActivityTable = ({ data, v_vault = null }) => {
  const {
    splices = [],
    vaumap = {},
    hmap = {},
  } = useMemo(() => {
    if (nils(data)) return {};
    // console.log("spdata", data);
    data.vaumap["0x792a3a3539a314f4fac63f6fcc546c3ef9ffc8ce"] =
      "Splice Contract";
    data.vaumap["0x0000000000000000000000000000000000000000"] = "ZERO";
    return data;
  }, [jstr(data)]);

  return (
    <table className={twMerge(tablecn.table, "w-full resp-text--2")}>
      <thead>
        <tr className="thintdrowp4">
          <td></td>
        </tr>
      </thead>
      <tbody>
        {splices.map((s, i) => {
          let { hid, transfers: ts } = s;
          let h = hmap[hid];

          return (
            <>
              <tr className="thintdrowp4">
                <td colSpan={10}>
                  <div className="fr-sc resp-gap-4">
                    <div className="fr-sc resp-gap-1">
                      <Link to={polytxnidlink(getv(s, "mint.tx_hash"))}>
                        <div className="xs:w-[1rem] lg:w-[2rem] ">
                          <Img img={polychainimg} />
                        </div>
                      </Link>

                      {!nils(getv(s, "pwin_txid")) && (
                        <>
                          <Tag
                            redirect={polytxnlink(getv(s, "pwin_txid"))}
                            className="xs:w-[1rem] lg:w-[3rem]"
                          >
                            <Img img={prizebox_img} />
                          </Tag>
                        </>
                      )}
                    </div>

                    <Link target="_blank" to={`/bike/${hid}`}>
                      <div className="fc-ss resp-gap-2">
                        <span className="resp-text--1 font-digi">
                          {s.hid}-{h.name}
                        </span>
                        <div className="fr-sc resp-text--2 text-acc0 resp-gap-2">
                          <span>{_.capitalize(h.type)}</span>
                          <span>F{h.fno}</span>
                          <FontAwesomeIcon
                            className={twMerge(
                              elementmap[h.element].text,
                              "resp-text-1",
                            )}
                            icon={elementmap[h.element].icon}
                          />
                          <FontAwesomeIcon
                            className={twMerge(
                              gendermap[h.gender].text,
                              "resp-text-1",
                            )}
                            icon={gendermap[h.gender].icon}
                          />
                        </div>
                      </div>
                    </Link>

                    <div className="flex-1"></div>

                    <div className="fc-ss resp-gap-2">
                      <span className="resp-text--2 text-acc0">
                        Minted on {iso_format(getv(s, "mint.date"))}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr colSpan={10}>
                <table
                  className={twMerge(
                    tablecn.table,
                    "w-full resp-text--2 bg-r2dark/80",
                    "border-b border-r2lig",
                  )}
                >
                  <tbody>
                    {ts.map((t) => {
                      let { from, to } = t;
                      from = from.toLowerCase();
                      to = to.toLowerCase();

                      let from_vault = vaumap[from];
                      let to_vault = vaumap[to];

                      return (
                        <tr>
                          <td className="resp-text--2">
                            <div className="fr-sc resp-gap-2">
                              <div
                                className={twMerge(
                                  "fc-cc w-[12rem]",
                                  v_vault == from ? "text-acc0" : "",
                                )}
                              >
                                <span>{trim_n(from_vault, 20)}</span>
                              </div>
                              <div className="w-[1rem]">
                                <span>
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                              </div>

                              <div
                                className={twMerge(
                                  "fc-cc w-[12rem]",
                                  v_vault == to ? "text-acc0" : "",
                                )}
                              >
                                <span>{trim_n(to_vault, 20)}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <>
                              {/* <TokenIcon
                                token={"WETH"}
                                size="xs"
                              /> */}
                              {!nils(t.token) ? (
                                <div
                                  className={twMerge(
                                    "fr-sc resp-gap-1 font-mon resp-text--1 my-1 pr-[2rem]",
                                    t.token == "BGC"
                                      ? "text-yellow-400"
                                      : t.token == "DEZ"
                                        ? "text-blue-400"
                                        : t.token == "WETH"
                                          ? "text-pink-400"
                                          : "text-white",
                                  )}
                                >
                                  <div class="flex-1"></div>
                                  <span>{dec(t.amt, tokdecn(t.token))}</span>
                                  <span className="xs:w-[3rem] lg:w-[4rem]">
                                    {t.token}
                                  </span>
                                </div>
                              ) : null}
                            </>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export const SpliceActivityInner = ({ vault }) => {
  const [page, set_page] = useState(0);
  const [limit, set_limit] = useState(20);

  const [qo_spdata] = useQueries([
    q_splicing_activity({ vault, page, limit }, {}),
  ]);
  const spdata = useMemo(() => {
    return getv(qo_spdata, "data.result");
  }, [qo_spdata.dataUpdatedAt]);

  const scon = {
    page,
    set_page,
    limit,
    set_limit,
  };

  const next = () => {
    set_page((p) => p + 1);
  };
  const prev = () => {
    set_page((p) => Math.max(p - 1, 0));
  };
  const p_btn_cn =
    "fc-cc xs:rounded-full lg:rounded-full bg-transparent xs:p-[0.3rem] lg:p-[0.6rem]";

  const aucon = useAuthContext();
  const is_myvault = !nils(vault) && vault == aucon?.vault;

  return (
    <SpActivityContext.Provider value={scon}>
      <div className="w-full">
        <div className="mx-auto w-[60rem] max-w-[95vw] ">
          <Card
            className={"w-full bg-r2lig/20 backdrop-blur-md border border-acc4"}
          >
            <div className="fr-sc card-dark-bg rounded-full">
              <Tag onClick={prev} className={p_btn_cn}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Tag>
              <div className="resp-px-2 flex-1 text-center font-digi resp-text--1 font-digi">
                Page-{page + 1}
              </div>
              <Tag onClick={next} className={p_btn_cn}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>

            <div className="h-[1rem]"></div>
            {qo_spdata.isLoading ? (
              <Loader01c />
            ) : qiserr(qo_spdata) ? (
              <p className="text-center text-red-300 resp-text--1">
                {qiserr(qo_spdata)}
              </p>
            ) : qissuccesss(qo_spdata) ? (
              <div className=" w-full overflow-auto">
                <SpliceActivityTable data={spdata} v_vault={vault} />
              </div>
            ) : null}
          </Card>
        </div>

        <div className="h-[5rem]"></div>
      </div>
    </SpActivityContext.Provider>
  );
};

export const SpliceActivity = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();

  const vault = useMemo(() => {
    let v = psearch?.vault;
    if (nils(v)) return null;
    return v.toLowerCase();
  }, [jstr(psearch)]);

  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
  ]);
  const v = useMemo(
    () => getv(qo_vaultinfo, "data.result"),
    [qo_vaultinfo.dataUpdatedAt],
  );

  const is_myvault = !nils(vault) && vault == aucon?.vault;

  return (
    <>
      <Helmet>
        <title>Splicing Activity</title>
      </Helmet>

      <div className="h-page">
        <div className="h-[3rem]"></div>

        <div className="fc-cc resp-gap-2">
          <p className="resp-text-1 font-digi">Splicing Activity</p>
          {!nils(vault) && (
            <>
              <p className="resp-text--3 font-digi text-acc0">{vault}</p>
              <p className="resp-text-1 font-digi text-acc0 ">
                {v?.vault_name}
              </p>
            </>
          )}
        </div>

        <div className="fr-cc">
          <div
            onClick={() => {
              if (is_myvault) {
                upd_psearch({ vault: null });
              } else {
                upd_psearch({ vault: aucon.vault });
              }
            }}
            className="my-2 fr-sc resp-gap-1 cursor-pointer"
          >
            <FontAwesomeIcon icon={is_myvault ? faToggleOn : faToggleOff} />
            <span>My Vault</span>
          </div>
        </div>

        <div className="h-=[1rem]"></div>
        <SpliceActivityInner vault={vault} />
      </div>
    </>
  );
};
