import React, { useMemo } from "react";
import { nils } from "../utils/utils.js";
import FireTrail from "./trails/FireTrail.js";
import GlassTrail from "./trails/GlassTrail.js";
import SmokeTrail from "./trails/SmokeTrail.js";
import CrystalTrail from "./trails/CrystalTrail.js";
import BoltsTrail from "./trails/BoltsTrail.js";
import ElectricTrail from "./trails/ElectricTrail.js";

const TrailsMap = {
  fire2: FireTrail,
  glass: GlassTrail,
  smoke: SmokeTrail,
  crystal: CrystalTrail,
  bolts: BoltsTrail,
  electric: ElectricTrail,
};

export const Trail = ({ trail: trail_full, show = true, ...props }) => {
  const [trail, preset] = useMemo(() => {
    if (nils(trail_full)) return [null, null];
    let ar = trail_full.split("_");
    // console.log(ar);
    return ar;
  }, [trail_full]);

  if (nils(trail)) return null;
  if (show == false) return null;

  const TrailComponent = TrailsMap[trail];
  if (TrailComponent) {
    return <TrailComponent {...{ preset, props }} />;
  }
  return null;
};
