import abi_racestake from "./RaceStake/abi.json";
import abi_racestakev02 from "./RaceStakeV02/abi.json";
import abimethods_racestakev02 from "./RaceStakeV02/abimethods.js";
import abi_racestakev03dez from "./RaceStakeV03_DEZ/abi.json";
import abimethods_racestakev03dez from "./RaceStakeV03_DEZ/abimethods.js";
import abi_racestakev04bgc from "./RaceStakeV04_BGC/abi.json";
import abimethods_racestakev04bgc from "./RaceStakeV04_BGC/abimethods.js";
import abi_raceautofiller from "./RaceAutoFiller/abi.json";
import abimethods_raceautofiller from "./RaceAutoFiller/abimethods.js";
import abi_splicing from "./Splicing/abi.json";
import abi_splicingv02 from "./Splicing_V02/abi.json";
import abimethods_splicingv02 from "./Splicing_V02/abimethods.js";
import abimethods_splicing from "./Splicing/abimethods.js";
import abi_fbikeskin from "./FBikeSkin/abi.json";
import abimethods_fbikeskin from "./FBikeSkin/abimethods.js";

const erc_abis = {
  721: [
    "function transferFrom(address from, address to, uint256 tokenId) external",
    "function approve(address to, uint256 tokenId) public",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  1155: [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  20: [
    "function balanceOf(address account) external view returns (uint256)",
    "function transfer(address to, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
  ],
};

const erc_methods_abi = {
  20: {
    balanceOf:
      "function balanceOf(address account) external view returns (uint256)",
    transfer:
      "function transfer(address to, uint256 amount) external returns (bool)",
    allowance:
      "function allowance(address owner, address spender) external view returns (uint256)",
    approve:
      "function approve(address spender, uint256 amount) external returns (bool)",
  },
  721: {
    transferFrom:
      "function transferFrom(address from, address to, uint256 tokenId) external",
    approve: "function approve(address to, uint256 tokenId) public",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
  1155: {
    safeTransferFrom:
      "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
};

export const abimap = {
  dez: erc_abis[20],
  DEZ: erc_abis[20],
  weth: erc_abis[20],
  WETH: erc_abis[20],
  bgc: erc_abis[20],
  BGC: erc_abis[20],

  racestake: abi_racestake,
  racestakev02: abi_racestakev02,
  racestakev03dez: abi_racestakev03dez,
  racestakev04bgc: abi_racestakev04bgc,
  raceautofiller: abi_raceautofiller,

  core: erc_abis[721],
  splicing: abi_splicing,
  splicingv02: abi_splicingv02,
  fbikeskin: { ...erc_abis[721], ...abi_fbikeskin },
};

export const abimethodmap = {
  dez: erc_methods_abi[20],
  DEZ: erc_methods_abi[20],
  weth: erc_methods_abi[20],
  WETH: erc_methods_abi[20],
  BGC: erc_methods_abi[20],
  bgc: erc_methods_abi[20],

  racestakev02: abimethods_racestakev02,
  racestakev03dez: abimethods_racestakev03dez,
  racestakev04bgc: abimethods_racestakev04bgc,

  raceautofiller: abimethods_raceautofiller,

  core: erc_methods_abi[721],
  splicing: abimethods_splicing,
  splicingv02: abimethods_splicingv02,
  fbikeskin: { ...erc_methods_abi[721], ...abimethods_fbikeskin },
};

export default abimap;
