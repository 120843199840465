import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  q_satellitev2_doc,
  q_satellitesv2,
  qiserr,
  qissuccesss,
  q_satellitev2_open_options,
  q_token_prices,
  q_open_races,
  q_satellitev2_open_qualraces,
  useStepQuery,
  polytxnidlink,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { Card, Img, Tag } from "../components/utilityComps.js";
import {
  base64_to_json,
  copy_clip,
  dec,
  emp,
  getv,
  iso,
  json_to_base64,
  jstr,
  nils,
} from "../utils/utils.js";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import {
  CBTag,
  ClassTag,
  ElementTag,
  FNoTag,
  TypeTag,
} from "../components/ShortComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faFlag,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { PosTag, RaceCard, RoundsInfo } from "../utils/raceutils.js";
import { ErrorBoundary } from "../utils/errbou.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { tokdecn, useAppContext } from "../App.js";
import { racecn } from "./Races.js";
import {
  class_cn,
  class_text,
  get_payout_cn,
  get_payout_txt,
} from "../utils/cn_map.js";
import {
  filt_ar_using_filters,
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import { InpAutoWrap, set_state_ob } from "../components/input.js";
import { polychainimg } from "../utils/links.js";
import {
  FiltCard_forLegacy,
  RaceCardR2_full,
  RaceCardR2_mini,
} from "../utils/raceutils2.js";

const SatelliteListRowWrapper = ({ satid }) => {
  const [qo_satellite] = useQueries([q_satellitev2_doc({ satid })]);
  const satellite = useMemo(
    () => getv(qo_satellite, "data.result"),
    [qo_satellite.dataUpdatedAt],
  );
  if (qissuccesss(qo_satellite) && !_.isEmpty(satellite))
    return <SatelliteListRow satellite={satellite} expanded={false} />;

  return (
    <Card className="w-full my-1 rounded-md bg-reg h-[3rem]">
      {qo_satellite.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(qo_satellite) ? (
        <p className="text-center text-red-300">
          Error[satid={satid}] {qiserr(qo_satellite)}
        </p>
      ) : (
        <></>
      )}
    </Card>
  );
};

export const SatelliteListRow = ({
  satellite: s,
  expanded = false,
  onRaceClick,
}) => {
  const acccon = useAccountContext();

  const copy_bikeids = () => {
    let ar = _.chain(s.winners).values().uniq().value();
    let str = ar.join("\n");
    copy_clip(str);
  };

  const [advanceslist, advanceslisthids] = useMemo(() => {
    let ar = _.chain(s.rslist || [])
      .map((e) => {
        return e.advances.map((a) => ({ rid: e.rid, ...a }));
      })
      .flatten()
      .uniqBy((e) => e.hid)
      .map((e) => [`${e.rid}-${e.hid}`, e.hid])
      .slice(0, s.advances_n)
      .value();
    // console.log(ar);
    return [_.map(ar, 0), _.map(ar, 1)];
  }, [jstr(s)]);

  const myhids = acccon.hids || [];

  const mybikesqualified = useMemo(() => {
    let qualhids = _.uniq(advanceslisthids);
    // let myhids = [advanceslisthids[0]];
    let myqual = _.intersection(myhids, qualhids);
    return myqual;
  }, [jstr(advanceslist)]);

  const [n_rstatus, n_r_rid] = useMemo(() => {
    let a = _.find(s.rslist, (r) => r.status !== "finished");
    return [a?.status ?? null, a?.rid ?? null];
  }, [jstr(s)]);

  const extra_prize_usd = getv(s, "satpreset.race_conf.extra_prize_usd", 0);

  const redlink = useMemo(() => {
    let is_maiden = getv(s, "satpreset.is_maiden") === true;
    if (is_maiden) return `/maiden/${s.satid}`;
    else return `/satellitesv2/${s.satid}`;
  }, [jstr(s)]);

  /* const show = useMemo(() => {
    if (mybikes == true) return mybikesqualified.length > 0;
    return true;
  }, [jstr(s), mybikes, jstr(mybikesqualified)]);

  if (!show) return <></>; */

  return (
    <>
      <Card className="w-full my-1 rounded-md bg-r2lig/20 backdrop-blur-md border border-acc4">
        <Link to={redlink}>
          <div className="fr-sc resp-gap-2 resp-text--1">
            <Tag
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copy_clip(s.satid);
              }}
              className="border border-r2lig text-mon"
            >
              {s.satid}
            </Tag>
            <span className="resp-text-1 font-digi">
              {getv(s, "satpreset.satellite_name")} #{s.presetid}
            </span>
            <div className="flex-1"></div>

            <div className="fc-ss resp-text-1">
              <div className="fr-sc resp-gap-1 text-acc0">
                {s.filled || s.ended ? (
                  <>
                    <span>{s.rids.length}</span>
                    <span>{"Races"}</span>
                  </>
                ) : (
                  <>
                    <span>{s.rids.length}</span>
                    <span>/</span>
                    <span>{Math.max(s.est_maxraces_n, s.rids.length)}</span>
                    <span>{"Races"}</span>
                  </>
                )}
              </div>
              {s.ended ? (
                <span className="text-red-300">ENDED</span>
              ) : s.sat_cancelled ? (
                <span className="text-red-300">CANCELLED</span>
              ) : s.filled ? (
                <span className="text-green-300">
                  Filled {s.advances_n} Gates
                </span>
              ) : (
                <span className="text-yellow-300">
                  {s.advances_n ?? 0} / {getv(s, "satpreset.sat_gates")} Gates
                </span>
              )}
            </div>
          </div>
        </Link>

        {!nils(s.r0info) && (
          <>
            <div className="fr-sc resp-gap-2 resp-text--1 resp-my-1">
              <span className="w-[8rem] font-digi ">Qualifiers</span>
              <ClassTag c={s.r0info.class} />
              <CBTag cb={s.r0info.cb} />
              <span className="font-mon bold resp-text-0 text-yellow-400">
                {"Fee: "}${dec(s.r0info.init_feeusd, 2)}
              </span>

              {(n_rstatus == "open" && (
                <Tag
                  redirect={`/race/${n_r_rid}`}
                  className="text-blue-400 border border-blue-400 font-digi"
                >
                  OPEN
                </Tag>
              )) ||
                (n_rstatus == "scheduled" && (
                  <Tag
                    redirect={`/race/${n_r_rid}`}
                    className="text-yellow-400 border border-yellow-400 font-digi"
                  >
                    Scheduled
                  </Tag>
                )) || <></>}
              <div className="flex-1"></div>
              {s.r0info.eventtags?.map((e) => {
                return (
                  <Tag className={"bg-slate-600 text-white -skew-x-12"}>
                    {_.upperCase(e)}
                  </Tag>
                );
              })}
            </div>
            {getv(s, "satpreset.is_maiden") !== true && (
              <div className="fr-sc resp-gap-2 resp-text--1 resp-my-1 relative">
                <div className="fc-ss w-[8rem]">
                  <span className=" font-digi ">Finale</span>
                  <span className="text-acc0 italic respt-text--2">
                    v{s.version}::{getv(s, "satpreset.presetid")}
                  </span>
                </div>
                <ClassTag c={getv(s, "satpreset.race_conf.class")} />
                <CBTag
                  cb={getv(s, "satpreset.race_conf.cb")}
                  className={"bg-yellow-400/30"}
                />
                <span className="font-mon bold resp-text-0 text-green-400">
                  {"Prize: "}:${dec(s.r0info.sat_prizeusd, 2)}
                  <span className="bold">
                    {!nils(extra_prize_usd)
                      ? ` + $${dec(extra_prize_usd, 2)}`
                      : ""}
                  </span>
                </span>
                <div className="flex-1"></div>
                {/*  <span className="font-digi resp-text-0 italic bold resp-text-0 text-acc0 ">
                {getv(s, "satpreset.race_conf.r_form.rounds_n")}
                {" Rounds"}
              </span> */}
                <ErrorBoundary>
                  <RoundsInfo
                    race={s.satpreset.race_conf}
                    popupclassName={twMerge(
                      "absolute xs:top-0 xs:right-0 lg:top-0 lg:right-0",
                    )}
                  />
                </ErrorBoundary>
              </div>
            )}
          </>
        )}

        <div className="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-2 resp-p-2">
          {mybikesqualified.map((hid) => {
            let h = s.bikesmap[hid];
            if (nils(h)) return <></>;
            return (
              <div key={hid} className="fr-sc w-full resp-gap-2">
                <div
                  className={twMerge(
                    "xs:w-[0.7rem] lg:w-[1.5rem] rounded-full",
                    "xs:h-[0.7rem] lg:h-[1.5rem] rounded-full",
                    "aspect-[1/1]",
                    `bg-${h.color}`,
                  )}
                ></div>
                <span className="font-digi resp-text--3">#{hid}</span>
                <span className="font-digi resp-text--1">{h.name}</span>
              </div>
            );
          })}
        </div>
      </Card>
      {expanded && (
        <div>
          {s.sat_cancelled == true && (
            <div className="fc-cc">
              <p className="font-digi resp-text-1 text-red-300">
                Satellite Cancelled
              </p>
              <div className="fr-cc resp-gap-2 resp-text--1 text-yellow-300">
                {!nils(getv(s, "refund.hash")) && (
                  <Link
                    target="_blank"
                    to={polytxnidlink(getv(s, "refund.hash"))}
                  >
                    <div className="xs:w-[0.6rem] lg:w-[1.2rem]">
                      <Img img={polychainimg} />
                    </div>
                  </Link>
                )}
                <span className="">Refunded</span>
                <span>
                  {dec(
                    getv(s, "refund.eapay_tok"),
                    tokdecn(getv(s, "refund.paytoken")),
                  )}{" "}
                  {getv(s, "refund.paytoken")}
                </span>
                <span>/</span>
                <span>
                  {dec(getv(s, "refund.eapay_usd"), 2)}
                  {" USD"}
                </span>
                <span>{`each to ${getv(s, "refund.n")} qualifiers`}</span>
              </div>
            </div>
          )}

          <div className="fr-cc">
            {s.filled && (
              <Link to={`/race/${s.satid}`}>
                <Tag className="fr-cc resp-gap-1 bg-green-400/30 border border-green-400 shadow-lg shadow-green-400 -skew-x-12 resp-text--1">
                  <FontAwesomeIcon icon={faFlag} />
                  <span className="font-digi">Satellite Finale Race </span>
                </Tag>
              </Link>
            )}
          </div>

          {(s.rslist || []).map((row) => {
            const { rid, advances } = row;
            const r = s.racesmap[rid];
            return (
              <Card
                onClick={() => {
                  if (onRaceClick) onRaceClick();
                }}
                className="resp-my-1 bg-transparent w-full"
                key={rid}
              >
                <Link to={`/race/${rid}`}>
                  <div className="fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faFlag} />
                    <span>{r.rid}</span>
                    <span className="font-digi resp-text-1">{r.race_name}</span>
                    <div className="flex-1"></div>
                    <span
                      className={twMerge(
                        (r.status == "finished" && "text-green-400") ||
                          (r.status == "scheduled" && "text-yellow-400") ||
                          (r.status == "open" && "text-blue-400") ||
                          (r.status == "cancelled" && "text-red-400") ||
                          "text-white",
                      )}
                    >
                      {_.upperCase(r.status)}
                    </span>
                  </div>
                </Link>
                {(advances || []).map((a) => {
                  let { hid, pos } = a;
                  let h = s.bikesmap[hid];

                  let select = advanceslist.includes(`${rid}-${hid}`);

                  return (
                    <>
                      <Link to={`/bike/${h.hid}`}>
                        <div
                          className={twMerge(
                            "fr-sc resp-gap-2 ",
                            select
                              ? "text-green-300"
                              : "text-gray-300 opacity-20",
                          )}
                        >
                          <PosTag pos={pos} />
                          <span className="font-digi resp-text-0">
                            {h.name}
                          </span>
                          <span className="font-digi resp-text--1">
                            #{h.hid}
                          </span>
                          <div className="flex-1"></div>

                          <FNoTag
                            className="resp-text--3 text-white"
                            fno={h.fno}
                          />
                          <TypeTag
                            className="resp-text--3 text-white"
                            type={h.type}
                          />
                          <ElementTag
                            className="resp-text--3 text-white"
                            element={h.element}
                          />
                        </div>
                      </Link>
                    </>
                  );
                })}
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};

const SatV2MainContext = createContext();
export const useSatV2MainContext = () => useContext(SatV2MainContext);

const FeeFilters = () => {
  const bcon = useSatV2MainContext();
  const { filt, set_filt, hid } = bcon;
  const inpargs = {
    fkey: `bike-races-${hid}`,
    filters: filt,
    set_filters: set_filt,
  };
  const fee_mode = getv(filt, "fee_mode.vals");

  return (
    <>
      <div className="fr-cc w-max bg-r2dark/40 mx-auto rounded-md  mb-2">
        {[
          ["ALL", "bg-acc0/40"],
          ["Free", "bg-yellow-400/70"],
          ["Paid", "bg-green-400/70"],
        ].map(([txt, cn]) => {
          return (
            <Tag
              onClick={() => set_state_ob(filt, set_filt, "fee_mode.vals", txt)}
              className={twMerge(
                "rounded-md resp-px-4 resp-py-2 m-0",
                fee_mode == txt ? cn : "",
              )}
            >
              {txt}
            </Tag>
          );
        })}
      </div>
      {fee_mode == "Paid" && (
        <>
          <div className="mt-4 fr-cs w-max mx-auto resp-gap-2">
            <div className="w-max mx-auto">
              <InpAutoWrap {...{ ...inpargs, idd: "fee_usd" }} />
            </div>
            <Tag
              onClick={() => {
                set_state_ob(filt, set_filt, "fee_usd.vals", {
                  mi: null,
                  mx: null,
                });
              }}
              className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
            >
              clear
            </Tag>
          </div>
        </>
      )}
    </>
  );
};

export const SatellitesV2 = () => {
  const appcon = useAppContext();
  const accon = useAccountContext();
  const { psearch, upd_psearch, tok_to_usd_val } = appcon;

  const { s_acc_config, g_acc_config } = useAccountContext();

  const [tab, set_tab] = useState(psearch?.tab ?? "satellites");

  const [qo_satellites] = useQueries([
    q_satellitesv2({}, { enabled: tab == "satellites" }),
  ]);
  const satids = useMemo(
    () => getv(qo_satellites, "data.result") || [],
    [qo_satellites.dataUpdatedAt],
  );

  const qost_satedocs = useStepQuery({
    q_: q_satellitev2_doc,
    lim: 5,
    par_ar: (satids || []).map((s) => [{ satid: s }]),
  });

  const satellites = useMemo(() => {
    let ar = [];
    for (let q of qost_satedocs.qs) {
      if (qissuccesss(q)) ar.push(getv(q, "data.result"));
    }
    return ar;
  }, [jstr(_.map(qost_satedocs.qs, "dataUpdatedAt"))]);

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("satv2_qual_races.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);

  const [selrace, set_selrace] = useState(psearch.rid);
  const [mybikes, set_mybikes] = useState(psearch.mybikes == "true" ?? false);

  const [filt, set_filt] = useState({
    class: {
      type: "options-only-ar",
      path: "class",
      cfilter: true,
      options: [1, 2, 3, 4, 70],
      vals: !nils(parseInt(basefilt?.class)) ? [parseInt(basefilt?.class)] : [],
      txt_fn: (o) => {
        if (o == "my_races") return `My Races`;
        if (o == "_1v1") return `1v1`;
        return class_text(o, "T");
      },
      label: "Class",
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return class_cn(o, "font-digi bg-opacity-80");
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-${class_cn(op)?.replace("bg-", "")}`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    cb: {
      type: "options-only-ar",
      path: "cb",
      cfilter: true,
      options: [10, 12, 14, 16, 18, 20, 22],
      filter_exception: [],
      vals: !nils(parseInt(basefilt?.cb)) ? [parseInt(basefilt?.cb)] : [],
      txt_fn: (o) => {
        return `CB${o}00`;
      },
      label: "Distance",
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    payout: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.payout.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Payout",
      show_label: false,
      options: [
        "na",
        "wta",
        "top2",
        "top3",
        "top4",
        "dblup",
        "pity",
        "pitylow",
        "hybrid",
      ],
      filter_exception: [],
      vals: basefilt?.payout,
      txt_fn: (o) => {
        return get_payout_txt(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return get_payout_cn(o);
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    eventtags: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        for (let e of f) {
          if (r.eventtags.includes(e)) return true;
        }
        return false;
      },
      cfilter: true,
      label: "eventtags",
      show_label: false,
      options: [],
      filter_exception: [],
      vals: basefilt?.eventtags,
      txt_fn: (o) => {
        return o;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-white text-white";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-md shadow-white text-black`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    format: {
      type: "options-only-ar",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.format.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Format",
      show_label: false,
      options: ["normal", "spin_n_go", "sit_n_go", "reduced", "rounds"],
      filter_exception: [],
      vals: _.isEmpty(basefilt?.format) ? [] : basefilt?.format,
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    rgate: {
      type: "options",
      path: "rgate",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: [2, 3, 4, 6, 7, 8, 10],
      filter_exception: [],
      vals: basefilt?.rgate?.map((e) => parseInt(e)),
      txt_fn: (o) => {
        return `${o} Gates`;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    fee_mode: {
      label: "Fees",
      cfilter: false,
      type: "inptext",
      vals: basefilt?.fee_mode ?? "ALL",
      txt_fn: (o) => `${o}`,
    },
    fee_usd: {
      type: "range",
      path: (r, f, filters) => {
        let fm = getv(filters, "fee_mode.vals") ?? "ALL";
        if (fm == "ALL") return true;
        else if (fm == "Free") {
          let isfree = r.feetag == "F";
          return isfree;
        } else if (fm == "Paid") {
          let inran = _.inRange(r.fee_usd, f?.mi ?? 0.001, f?.mx ?? 1e7);
          return inran;
        }
        return false;
      },
      cfilter: true,
      label: "Fee [in USD]",
      show_label: false,
      options: [2, 6, 12],
      filter_exception: [],
      vals: emp(basefilt?.fee_usd)
        ? { mi: undefined, mx: undefined }
        : basefilt?.fee_usd,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem] font-mon`,
      color_fn: (o) => {
        return "text-white";
      },
      active_cn: (active, op) => {
        if (!active) return "";
        return `text-green-400`;
      },
    },
  });
  const [q_ops] = useQueries([q_satellitev2_open_options({})]);
  const ops = useMemo(() => {
    let ops = getv(q_ops, "data.result") || {};
    if (!qissuccesss(q_ops)) return;
    set_filt({
      ...filt,
      cb: { ...filt.cb, options: ops.cb },
      payout: { ...filt.payout, options: ops.payout },
      format: { ...filt.format, options: ops.format },
      rgate: { ...filt.rgate, options: ops.rgate },
      eventtags: { ...filt.eventtags, options: ops.eventtags },
    });
  }, [q_ops.dataUpdatedAt]);

  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    s_acc_config("open_races.basefilt", valfilt);
    return valfilt;
  }, [filt]);
  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    rem.tab = tab;
    rem.rid = selrace;
    rem.mybikes = mybikes == true ? "true" : "";
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), tab, selrace, mybikes]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const [qo_tokpri, qo_open_races] = useQueries([
    q_token_prices(),
    q_satellitev2_open_qualraces(
      {
        cb: valfilt?.cb,
        // ...valfilt,
      },
      { enabled: tab == "qualraces" },
    ),
  ]);

  const [races, races_n] = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    let rs = getv(qo_open_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;

      race.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
      race.prize_usd = tok_to_usd_val(r.prize, r.paytoken);

      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_open_races.dataUpdatedAt, jstr(valfilt), qo_tokpri.dataUpdatedAt]);

  const [filtraces, filtraces_n] = useMemo(() => {
    let rs = filt_ar_using_filters({ ar: races, filters: filt });
    return [rs, rs.length];
  }, [jstr(races), jstr(valfilt)]);

  const inpargs = {
    fkey: "satv2_qualraces",
    filters: filt,
    set_filters: set_filt,
  };

  const fee_mode = getv(filt, "fee_mode.vals");

  const vauhids = accon.hids || [];
  const filtsatellites = useMemo(() => {
    if (!mybikes) return satellites;
    let sats = satellites || [];
    sats = _.filter(satellites, (s) => {
      let advances_hids = s.advances_hids;
      let c = _.intersection(advances_hids, vauhids);
      console.log(s.satid, advances_hids, c);
      return c.length > 0;
    });

    return sats;
  }, [jstr(satellites), mybikes, jstr(vauhids)]);

  const satv2maincon = {
    filt,
    set_filt,
    valfilt,
    races,
    races_n,
    selrace,
    set_selrace,
    mybikes,
    set_mybikes,
  };

  return (
    <SatV2MainContext.Provider value={satv2maincon}>
      <Helmet>
        <title>{"Satellites | FBike DNA"}</title>
      </Helmet>

      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          <div className="h-[2rem]"></div>
          <div className="fr-cc resp-gap-2">
            {[
              ["satellites", "Satellites"],
              ["qualraces", "Qual Races"],
            ].map(([_tab, txt]) => {
              const active = tab == _tab;
              return (
                <Tag
                  onClick={() => set_tab(_tab)}
                  className={twMerge(
                    "font-digi transition-all duration-300",
                    active
                      ? "bg-r2lig/60 text-white -skew-x-12"
                      : "bg-transparent text-white",
                  )}
                >
                  {txt}
                </Tag>
              );
            })}
          </div>
          <div className="h-[1rem]"></div>

          {tab == "satellites" && (
            <>
              <div className="fr-sc">
                <div
                  onClick={() => set_mybikes(!mybikes)}
                  className={twMerge(
                    "fr-sc resp-gap-2 cursor-pointer resp-text-1",
                    mybikes ? "text-acc0" : "",
                  )}
                >
                  <FontAwesomeIcon icon={mybikes ? faToggleOn : faToggleOff} />
                  <span>MyBikes</span>
                </div>
                <div className="flex-1"></div>
                <div className="fr-cc resp-gap-1 text-acc0 ">
                  {mybikes && (
                    <>
                      <span>Qualfied in</span>
                      <span>{filtsatellites.length}</span>
                      <span>/</span>
                    </>
                  )}
                  {qost_satedocs.done < satids.length ? (
                    <>
                      <Loader01c size="s" />
                      <span>{qost_satedocs.done}</span>
                      <span>/</span>
                      <span>{satids.length}</span>
                      <span>Satellites</span>
                    </>
                  ) : (
                    <>
                      <span>{satids.length}</span>
                      <span>Satellites</span>
                    </>
                  )}
                  <span>{}</span>
                </div>
              </div>
              <div className="h-[1rem]"></div>
              {qo_satellites.isLoading ? (
                <Loader01c />
              ) : qiserr(qo_satellites) ? (
                <p className="text-center text-red-300">
                  Error {qiserr(qo_satellites)}
                </p>
              ) : qissuccesss(qo_satellites) && _.isEmpty(satellites) ? (
                <p className="text-center text-yellow-300">
                  No Recent Satellites
                </p>
              ) : qissuccesss(qo_satellites) && _.isEmpty(filtsatellites) ? (
                <p className="text-center text-yellow-300">
                  No Recent Satellites you Qualified for
                </p>
              ) : qissuccesss(qo_satellites) && !_.isEmpty(filtsatellites) ? (
                <div>
                  {filtsatellites.map((s) => {
                    return <SatelliteListRow key={s} satellite={s} />;
                  })}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {tab == "qualraces" && (
            <>
              <div className="w-reg w-[98%] mx-auto max-w-[65rem] mb-[1rem]">
                <FiltCard_forLegacy
                  {...{
                    ...inpargs,
                    order: "cb,rgate,payout,format,fee_mode".split(","),
                    bottom_jsx: <></>,
                    custom_jsx: {
                      fee_mode: {
                        selection_jsx: <> </>,
                        options_jsx: (
                          <>
                            <FeeFilters />
                          </>
                        ),
                      },
                    },
                  }}
                />
              </div>
              {/*

              <Card className={"w-reg w-[98%] resp-px-4"}>
                <div className="w-max mx-auto fr-cc wrap max-w-full">
                  <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
                </div>
                {
                  <>
                    <div className="grid xs:grid-cols-2 md:grid-cols-4">
                      <div className="col-span-2">
                        <p className="text-center font-digi resp-text--1 text-slate-500">
                          Gates
                        </p>
                        <InpAutoWrap {...{ ...inpargs, idd: "rgate" }} />
                      </div>
                      <div className="col-span-2">
                        <p className="text-center font-digi resp-text--1 text-slate-500">
                          Payout
                        </p>
                        <InpAutoWrap {...{ ...inpargs, idd: "payout" }} />
                      </div>
                      <div className="col-span-2">
                        <p className="text-center font-digi resp-text--1 text-slate-500">
                          Formats
                        </p>
                        <InpAutoWrap {...{ ...inpargs, idd: "format" }} />
                      </div>
                      <div className="col-span-2">
                        <p className="text-center font-digi resp-text--1 text-slate-500">
                          Race Fee
                        </p>
                        <>
                          <div className="fr-cc w-max bg-dark mx-auto rounded-md  mb-2">
                            {[
                              ["ALL", "bg-acc0/40"],
                              ["Free", "bg-yellow-400/70"],
                              ["Paid", "bg-green-400/70"],
                            ].map(([txt, cn]) => {
                              return (
                                <Tag
                                  onClick={() =>
                                    set_state_ob(
                                      filt,
                                      set_filt,
                                      "fee_mode.vals",
                                      txt,
                                    )
                                  }
                                  className={twMerge(
                                    "rounded-md resp-px-4 resp-py-2 m-0",
                                    fee_mode == txt ? cn : "",
                                  )}
                                >
                                  {txt}
                                </Tag>
                              );
                            })}
                          </div>
                          {fee_mode == "Paid" && (
                            <>
                              <div className="mt-4 fr-cs w-max mx-auto resp-gap-2">
                                <div className="w-max mx-auto">
                                  <InpAutoWrap
                                    {...{ ...inpargs, idd: "fee_usd" }}
                                  />
                                </div>
                                <Tag
                                  onClick={() => {
                                    set_state_ob(
                                      filt,
                                      set_filt,
                                      "fee_usd.vals",
                                      {
                                        mi: null,
                                        mx: null,
                                      },
                                    );
                                  }}
                                  className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
                                >
                                  clear
                                </Tag>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                      <div className="col-span-2">
                        <p className="text-center font-digi resp-text--1 text-slate-500">
                          Entry Criterias
                        </p>
                        <InpAutoWrap {...{ ...inpargs, idd: "eventtags" }} />
                      </div>
                    </div>
                  </>
                }
                <div className="fr-sc">
                  <Tag
                    onClick={clear_filt}
                    className="resp-text--3 text-red-400 border border-red-400"
                  >
                    {"Clear Filters"}
                  </Tag>
                  <div className="flex-1"></div>
                </div>
              </Card>
              */}
              <div className="h-[2rem]"></div>
              {qo_open_races.isLoading ? (
                <Loader01c />
              ) : qiserr(qo_open_races) ? (
                <p className="text-center text-red-400">
                  {qiserr(qo_open_races)}
                </p>
              ) : qissuccesss(qo_open_races) && _.isEmpty(filtraces) ? (
                <p className="text-center text-yellow-400">
                  {"No Races Found [try adjusting filters]"}
                </p>
              ) : (
                <></>
              )}
              {(filtraces || []).map((race) => {
                race.fetch_time = iso(qo_satellites.dataUpdatedAt);
                if (race.rid == selrace)
                  return <RaceCardR2_full {...{ race }} />;
                else
                  return (
                    <div class="w-full cursor-pointer">
                      <RaceCardR2_mini {...{ race, selrace, set_selrace }} />
                    </div>
                  );
              })}
            </>
          )}
        </div>
        <div className="h-[5rem]"></div>
      </div>
    </SatV2MainContext.Provider>
  );
};

const SatelliteContext = createContext({});
export const useSatelliteContext = () => useContext(SatelliteContext);
export const SatelliteV2PageInner = ({ satid, onRaceClick = () => {} }) => {
  const [qo_satellite] = useQueries([q_satellitev2_doc({ satid })]);
  const satellite = useMemo(
    () => getv(qo_satellite, "data.result"),
    [qo_satellite.dataUpdatedAt],
  );

  const pagetitle = useMemo(() => {
    if (satellite?.presetid)
      return `Satellite #${satellite.presetid} | FBike DNA`;
    else return `Satellite #${"..."} | FBike DNA`;
  }, [satid, satellite?.presetid]);

  return (
    <>
      {/*
        <Helmet>
          <title>{pagetitle}</title>
        </Helmet>
         */}

      {qo_satellite.isLoading ? (
        <Loader01c />
      ) : qiserr(qo_satellite) ? (
        <p className="text-center text-red-300">Error {qiserr(qo_satellite)}</p>
      ) : qissuccesss(qo_satellite) && _.isEmpty(satellite) ? (
        <p className="text-center text-yellow-300">Not Found</p>
      ) : qissuccesss(qo_satellite) && !_.isEmpty(satellite) ? (
        <div className="resp-text--2">
          <div className="h-[1rem]"></div>
          <SatelliteListRow
            satellite={satellite}
            expanded={true}
            {...{ onRaceClick }}
          />
          <div className="h-[5rem]"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const SatelliteV2Page = () => {
  const { satid } = useParams();
  return (
    <div className="h-page">
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[2rem]"></div>
        <div className="fr-sc">
          <Link to="/satellitesv2">
            <Tag className="border border-r2lig text-acc0 text-mon fr-sc resp-gap-2">
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>All Satellites</span>
            </Tag>
          </Link>
        </div>

        {<SatelliteV2PageInner {...{ satid }} />}
      </div>
    </div>
  );
};
