import Cropper from "react-easy-crop";
import { useState, useCallback } from "react";
import { PopUp } from "../components/popup";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tag } from "../components/utilityComps";
import { twMerge } from "tailwind-merge";

export const getCroppedImg = (imageSrc, pixelCrop) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      // No need to set a fillStyle, the canvas default is transparent

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject("Canvas is empty");
            return;
          }
          resolve(blob);
        },
        "image/png", // Use PNG to preserve transparency
        0.95,
      );
    };
    image.onerror = () => reject("Image failed to load");
  });
};

export const downsizeImage = async (blob, sizemb = 1) => {
  let fileSize = blob.size;

  // Downsize image if necessary
  while (fileSize > sizemb * 1024 * 1024) {
    // 1MB limit
    const newBlob = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const scaleFactor = 0.9; // Reduce image size by 10% each iteration
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            (resizedBlob) => resolve(resizedBlob),
            "image/png",
            0.9,
          );
        };
      };
      reader.readAsDataURL(blob);
    });
    blob = newBlob;
    fileSize = blob.size;
  }
  console.log("downsizeImage:blob", blob);
  return blob;
};

export const ImageCropper = ({
  id,
  src,
  set_src,
  on_discard,
  on_save,
  aspect = 1,
  sizemb = 1,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropped, setIsCropped] = useState(false);

  const [edit, set_edit] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [loading, set_loading] = useState(false);
  const handleCrop = async () => {
    try {
      if (loading) return;
      set_loading(true);
      const croppedImage = await getCroppedImg(src, croppedAreaPixels);
      const downsizedImage = await downsizeImage(croppedImage, sizemb);

      set_src(URL.createObjectURL(downsizedImage));
      setIsCropped(true);
      set_edit(false);
      await on_save(downsizedImage);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
    set_loading(false);
  };

  const grab_asset = (id, key) => {
    let el = document.getElementById(id);
    if (!el) return;
    let file = el.files[0];
    if (!file) return;
    return file;
  };

  const discard = () => {
    on_discard();
    set_edit(false);
  };

  return (
    <div class="">
      <div class="fc-cc aspect-[1/1] xs:w-[1.5rem] lg:w-[3rem] rounded-full bg-acc0/40 opacity-20 hover:opacity-100 text-white">
        <input
          onChange={() => {
            let file = grab_asset(id);
            if (!file) return;
            console.log("uploaded:file", id, file);
            set_src(URL.createObjectURL(file));
            set_edit(true);
          }}
          type="file"
          id={id}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          accept="image/*" // Modify this to accept different file types
        />
        <FontAwesomeIcon className="resp-text-2" icon={faPencilAlt} />
      </div>
      <PopUp
        {...{
          openstate: edit,
          overlayclose: false,
          wrapcn: "top-[6rem]",
          innercn: "translate-y-[0%]",
          onClose: discard,
        }}
      >
        <Card
          className={twMerge(
            "bg-r2dark/40 border border-acc0",
            "xs:w-[95vw] lg:w-[50rem] max-w-[95vw] xs:h-[60vh] lg:h-[45rem] relative",
          )}
        >
          <div class="w-full xs:h-[50vh] lg:h-[40rem] relative">
            <Cropper
              image={src}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div class="fr-sc  h-[3rem] my-2">
            <div class="flex-1"></div>

            <Tag
              onClick={() => {
                discard();
              }}
              className="text-center bg-red-400 -skew-x-12"
            >
              <span class="font-digi text-white resp-text-1">Discard</span>
            </Tag>
            <Tag
              onClick={() => {
                handleCrop();
              }}
              className="text-center bg-acc4 -skew-x-12"
            >
              <span class="font-digi text-white resp-text-1">Save</span>
            </Tag>
          </div>
        </Card>
      </PopUp>
    </div>
  );
};
