import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, Img, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { useRaceContext } from "./RacePage.js";
import {
  dec,
  from_time_mini,
  getv,
  iso,
  jstr,
  nano,
  nils,
  to_time_mini,
} from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faLink,
  faLock,
  faTimes,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import {
  q_quest_set_user_cashout_autoupgrade,
  q_quest_set_user_cashout_option,
  q_race,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import _ from "lodash";
import { get_race_rtstatus, RoundsInfo } from "../utils/raceutils.js";
import { useNowContext } from "../App.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { class_text } from "../utils/cn_map.js";
import { useAppContext, tokdecn } from "../App.js";
import { EventTag } from "../utils/raceutils2.js";
import { Loader01c } from "../components/anims.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { RVImg } from "../components/BikeImg.js";
import { PopUp } from "../components/popup.js";
import { baseimgmap } from "../components/baseimgs.js";
export const RoundRacesContext = createContext();
export const useRoundRacesContext = () => useContext(RoundRacesContext);

export const QuestRaces = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;

  const rcon = useRaceContext();
  const { race: r0, hsob, ethusd } = rcon;
  const [qorace] = useQueries([
    q_race(
      { rid: r0.rid },
      {
        staleTime: 1e3 * 60,
        refetchInterval: 1e3 * 60,
      },
    ),
  ]);
  const race = useMemo(() => {
    let r = getv(qorace, "data.result");
    if (nils(r)) return r0;
    r.fee_usd = r.fee * ethusd;
    r.prize_usd = r.prize * ethusd;
    return r;
  }, [qorace.dataUpdatedAt, ethusd]);
  const r = race;

  const rounds_n = getv(r, "format_inf.rounds_n");
  const round_curr = getv(r, "format_inf.round_curr");
  const u_hid = getv(r, "r_form.u_hid");
  const u_h = hsob[u_hid];

  const [u_vault, u_vaultname] = useMemo(() => {
    if (nils(u_hid)) return ["", ""];
    let vault = getv(race, `racing_vaults.${u_hid}`);
    let vault_name = getv(race, `vaults_names.${vault}`);
    // console.log("u_vaultname", u_hid, vault, vault_name);
    return [vault, vault_name];
  }, [u_hid, jstr(race)]);

  const wonlastround = useMemo(() => {
    let now = iso();
    if (nils(r)) return null;
    let isprogressive = getv(r, "quest_type") == "progressive";
    // console.log({ isprogressive });
    let rno = null;
    if (isprogressive) {
      rno = getv(r, "format_inf.rounds_n");
    } else {
      rno = 3;
    }
    let rou = getv(r, `format_inf.rounds.r${rno}.A`) || {};
    // console.log("rou=", rou);
    if (!rou) return null;
    if (nils(rou.end_time) || nano(rou.end_time) > nano(now)) return null;

    let hidsout = rou.hids_out || [];
    let won = hidsout.includes(u_hid);
    // console.log({ hidsout, u_hid });
    // console.log("wonlastround", won);
    return won;
  }, [jstr(r), u_hid]);
  const [winpop, set_winpop] = useState(false);

  const roucon = {
    race,
    qorace,
    hsob,
    round_curr,
    rounds_n,
    u_hid,
    u_h,
    u_vault,
    u_vaultname,
  };
  // useEffect(() => { console.log("useEffect", roucon); }, [jstr(roucon)]);

  return (
    <RoundRacesContext.Provider value={roucon}>
      <div className="max-w-[98vw] w-[50rem] mx-auto relative">
        {
          <PopUp
            wrapcn={"top-[6rem]"}
            innercn={"translate-y-[0%]"}
            openstate={winpop}
            overlayclose={true}
            onClose={() => {}}
          >
            <div
              class="h-[30rem] cursor-pointer"
              onClick={() => {
                set_winpop(false);
              }}
            >
              <Img className img={baseimgmap[wonlastround ? "win" : "lose"]} />
            </div>
          </PopUp>
        }

        <div class="fr-sc my-2">
          <Link to={`/trainer-leaderboard?tab=races`}>
            <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
              <div class="fr-sc resp-gap-2 resp-text-0">
                <FontAwesomeIcon icon={faArrowLeft} />
                <span class="">Back to Trainer Races</span>
              </div>
            </Tag>
          </Link>
        </div>
        <Card className={twMerge("bg-r2lig/20 border border-acc4 w-full")}>
          {/*           <div className="font-digi fr-cc resp-gap-2 text-white resp-text-1">
            <span>{race.race_name}</span>
            <span className="resp-text--2 text-white">
              {` ${_.upperCase(race.format)} Races`}
            </span>
          </div> */}
          <div class="font-pixel resp-text-4 w-full text-center">QUEST</div>

          <div className="font-digi fr-cc resp-gap-2 text-white resp-text--2">
            <span>{race.race_name}</span>
          </div>
          <div className="fr-sc resp-gap-2">
            <Tag className="font-digi resp-text-0 text-white">
              CB{race.cb}00
            </Tag>
            <Tag
              className={twMerge(
                "font-digi resp-text-0 transform -skew-x-12",
                "bg-acc1",
              )}
            >
              {class_text(race.class, "T")}
            </Tag>
            <div className="flex-1"></div>

            <div className="fc-cc">
              {race?.format == "rounds" ? (
                <span className="text-r2lig">Finals PrizePool</span>
              ) : race.format == "roundsp" ? (
                <span className="text-r2lig">Rounds+ PrizePool</span>
              ) : race.format == "bounty" ? (
                <span className="text-yellow-300">Bounty PrizePool</span>
              ) : race.format == "quest" ? (
                <span className="text-r2lig">Quest Prize</span>
              ) : null}
              <div className="text-white font-digi resp-text-1 fr-sc resp-gap-1">
                <FontAwesomeIcon icon={faUsd} />
                <span>{dec(tok_to_usd_val(race.prize, race.paytoken), 2)}</span>
              </div>
            </div>
          </div>
          <div className="fr-sc relative">
            <div className="flex-1">
              {r.eventtags.map((e) => {
                return (
                  <EventTag
                    tag={e}
                    efil={getv(r, "r_form.entry_filt")}
                    race={r}
                  />
                );
              })}
            </div>
            <div className="my-2">
              <RoundsInfo
                race={r}
                popupclassName="xs:top-[0rem] xs:right-[0%] md:top-[0rem] md:right-[5%] border border-acc4"
                tagcn="bg-r2dark/80 border border-acc4 rounded-lg text-white"
              />
            </div>
          </div>
        </Card>

        <Card className={twMerge("bg-r2lig/20 border border-acc4 w-full")}>
          <div class="fr-sc w-full resp-gap-2 resp-text-1">
            <span className="italic text-acc4 font-digi">{u_vaultname}</span>
            <span>{" is racing "}</span>
            {!_.isEmpty(u_h) && (
              <>
                <RVImg
                  className={"xs:w-[3rem] lg:w-[5rem]"}
                  rvmode={getv(r, "rvmode")}
                />
                <div
                  style={
                    {
                      // borderBottom: `2px solid #${u_h.hexcode}`,
                    }
                  }
                  className={`text-acc4 font-digi border-b-2 border-${u_h.color}`}
                >{`#${u_hid} - ${u_h?.name}`}</div>
              </>
            )}
          </div>
        </Card>
        <ProgressiveUser_AutoUpgrade />
        {[...Array(rounds_n)].map((e, i) => {
          let round_i = i + 1;
          let round = getv(r, `format_inf.rounds.r${round_i}`);
          return <Round {...{ key: round_i, round_i, round }} />;
        })}
      </div>
    </RoundRacesContext.Provider>
  );
};

const mx_progressive_rounds = 5;
const progressive_prize_ratio = {
  1: 1.43,
  2: 2.3881,
  3: 5.110534,
  4: 10.221068,
  5: 20.442136,
};

const ProgressiveUser_CashoutOption = () => {
  const { race: r, round_curr } = useRoundRacesContext();
  const [sel, set_sel] = useState(r.user_cashout_option ?? null);

  const sel_txt = (sel) => {
    if (sel == "cashout") return `Cashout ${dec(r.use_prizeusd, 2)} USD Now`;
    else if (sel == "nextround") return "Progress to Next Round";
    else return "";
  };

  const [resp, set_resp] = useState({});

  const can_set_option = nils(sel) && resp.status != "loading";
  const submit_cashout_option = async (sel) => {
    try {
      set_resp({ status: "loading", msg: "saving your option..." });
      let data = {
        rid: r.rid,
        user_cashout_option: sel,
      };
      console.log("submit_cashout_option", data);
      let rres = await q_quest_set_user_cashout_option(data).queryFn();
      console.log("submit_cashout_option", rres);

      if (getv(rres, "err")) throw new Error(rres.err);

      let done = getv(rres, "result.done");
      if (done) {
        set_resp({ status: "success", msg: "saved your option" });
        set_sel(sel);
      } else {
        throw new Error("failed to save your option");
      }
    } catch (err) {
      console.error(err);
      set_resp({
        status: "error",
        msg: err.message,
      });
      setTimeout(() => {
        set_resp({});
      }, 3 * 1e3);
    }
  };

  if (round_curr >= mx_progressive_rounds) return null;

  if (!nils(sel)) {
    return (
      <p class="text-acc4 my-2 text-center">
        {"You have selected to "}
        <span class="text-acc4 underline">{sel_txt(sel)}</span>
      </p>
    );
  }

  const ratio = progressive_prize_ratio[round_curr + 1];
  const prize = parseFloat(r.init_feeusd * ratio);

  return (
    <div class="">
      <div class="fr-cc my-2">
        {["cashout", "nextround"].map((op, idx) => {
          return (
            <>
              <Tag
                onClick={(e) => {
                  e.preventDefault();
                  if (can_set_option) submit_cashout_option(op);
                }}
                className={twMerge(
                  "border border-acc4 text-acc4 bg-r2dark/40",
                  can_set_option ? "" : "grayscale opacity-50",
                )}
              >
                {sel_txt(op)}
              </Tag>
              {idx == 0 && <span className="resp-text--1 px-4">OR</span>}
            </>
          );
        })}
      </div>
      <div class="fc-ss text-left resp-text--1 text-white">
        <p>If you Select "Progress to Next Round"</p>
        <p>You Risk the currnt prize but increase winings for next round.</p>
        <p class="">
          Next Round Prize Pool: <span class="text-acc4">${dec(prize, 2)}</span>
        </p>
      </div>
      {!_.isEmpty(resp) && (
        <div
          class={twMerge(
            "p-1 fr-sc resp-gap-1 resp-text--1 border rounded-lg bg-r2dark/40",
            resp.status == "success"
              ? "text-green-400 border-green-400"
              : resp.status == "error"
                ? "text-red-400 border-red-400"
                : "text-blue-400 border-blue-400",
          )}
        >
          {resp.status == "loading" ? <Loader01c size="s" /> : null}
          <p>{resp.msg}</p>
        </div>
      )}
    </div>
  );
};

const ProgressiveUser_AutoUpgrade = () => {
  const { race: r, round_curr, qorace } = useRoundRacesContext();
  const [sel, set_sel] = useState(r.user_cashout_option ?? null);

  const autoupgrade = r.user_cashout_autoupgrade ?? false;

  const [resp, set_resp] = useState({});

  const can_set_option = nils(sel) && resp.status != "loading";
  const submit = async (sel) => {
    try {
      set_resp({ status: "loading", msg: "saving your autoupgrade..." });
      let data = {
        rid: r.rid,
        user_cashout_autoupgrade: sel,
      };
      console.log("submit_cashout_option", data);
      let rres = await q_quest_set_user_cashout_autoupgrade(data).queryFn();
      console.log("submit_cashout_option", rres);

      if (getv(rres, "err")) throw new Error(rres.err);

      let done = getv(rres, "result.done");
      if (done) {
        set_resp({ status: "success", msg: "saved your option" });
        set_sel(sel);
        setTimeout(() => {
          qorace.refetch();
        }, 1 * 1e3);
      } else {
        throw new Error("failed to save your option");
      }
    } catch (err) {
      console.error(err);
      set_resp({
        status: "error",
        msg: err.message,
      });
      setTimeout(() => {
        set_resp({});
      }, 3 * 1e3);
    }
  };

  const ratio = progressive_prize_ratio[round_curr + 1];
  const prize = parseFloat(r.init_feeusd * ratio);

  return (
    <div class="">
      <div class="fr-sc my-2">
        <div class="flex-1"></div>
        <Tag
          onClick={(e) => {
            e.preventDefault();
            if (can_set_option) submit(!autoupgrade);
          }}
          className={twMerge("resp-text-1 font-digi text-white")}
        >
          {` Auto Upgrade: `}
          <span class={twMerge(autoupgrade ? "text-green-400" : "text-white")}>
            {autoupgrade ? "ON" : "OFF"}
          </span>
        </Tag>
      </div>
      <div class="fr-sc">
        <div class="flex-1"></div>
        {!_.isEmpty(resp) && (
          <div
            class={twMerge(
              "p-1 fr-sc resp-gap-1 resp-text--1 border rounded-lg bg-r2dark/40",
              resp.status == "success"
                ? "text-green-400 border-green-400"
                : resp.status == "error"
                  ? "text-red-400 border-red-400"
                  : "text-blue-400 border-blue-400",
            )}
          >
            <div class="flex-1"></div>
            {resp.status == "loading" ? <Loader01c size="s" /> : null}
            <p>{resp.msg}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const Round = ({ round_i, round }) => {
  const roucon = useRoundRacesContext();
  const { race, rounds_n, round_curr } = roucon;
  const subrounds_n = _.keys(round).length;

  const round_name = useMemo(() => {
    return (
      // (rounds_n - round_i == 0 && "FINALE") ||
      // (rounds_n - round_i == 1 && "Semi-Finals") ||
      `Round-${round_i}`
    );
  }, [rounds_n, round_i]);

  const rid = useMemo(() => {
    return `${race?.rid}-${round_i}-A`;
  }, [jstr(round), jstr(race)]);

  if (_.isEmpty(round)) {
    return (
      <>
        <Card className={twMerge("bg-r2lig/20 border border-acc4 w-full")}>
          <div className="fr-sc text-slate-500">
            <Tag className={twMerge("")}>
              <FontAwesomeIcon icon={faLock} />
            </Tag>

            <p className="font-digi resp-text--1">{round_name}</p>
            <div className="flex-1"></div>
          </div>
        </Card>
      </>
    );
  }

  return (
    <Link to={`/race/${rid}`} target="_self">
      <Card className={twMerge("bg-r2lig/20 border border-acc4 w-full")}>
        <div className="fr-sc cursor-pointer gap-2">
          <p className="font-digi resp-text-0">{round_name}</p>
          <div class="flex-1">
            <p class="text-center text-yellow-400 anim-pulse resp-text--1 font-digi pulse-anim">
              Click here to watch the race or check results
            </p>
          </div>
          <div class=""></div>
        </div>

        <motion.div
          initial={"hide"}
          animate={"show"}
          variants={MoVariants.show_hide}
        >
          <>
            {_.entries(round).map(([ri_alp, sr]) => {
              return <SubRound {...{ round_i, key: ri_alp, ri_alp, sr }} />;
            })}
          </>
        </motion.div>
      </Card>
    </Link>
  );
};

const SubRound = ({ round_i, ri_alp, sr }) => {
  const roucon = useRoundRacesContext();
  const { now } = useNowContext();
  const { vault } = useAccountContext();
  const { race: r, rounds_n, u_h, u_hid, u_vault } = roucon;

  // useEffect(() => {
  //   console.log(round_i, ri_alp, sr);
  // }, [sr]);

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    return get_race_rtstatus(sr, now);
  }, [sr, now]);
  const is_lastround = round_i == rounds_n;

  // useMemo(() => { console.log(round_i, ri_alp, rtstatus); }, [rtstatus]);

  const tagcn = "text-[12px] m-0 text-center";
  const u_h_qualified = useMemo(() => {
    let hids_out = getv(sr, "hids_out") || [];
    if (hids_out.includes(u_hid)) return true;
    return false;
  }, [jstr(sr)]);

  const is_racing_vault = vault == u_vault;
  return (
    <Card className={"bg-transparent w-full"}>
      <div className="fr-cc gap-2 w-full">
        {rtstatus == "finished" && false ? (
          <>
            <div
              class={twMerge(
                "resp-text-0 fr-sc resp-gap-2 text-white",
                u_h_qualified ? "text-green-300" : "text-red-300",
              )}
            >
              {!_.isEmpty(u_h) && (
                <>
                  <span>{`#${u_hid} - ${u_h?.name}`}</span>
                </>
              )}
              {u_h_qualified ? (
                <>
                  {is_lastround ? (
                    <span>WON this Quest</span>
                  ) : (
                    <span>qualifed for next round</span>
                  )}
                </>
              ) : (
                <>
                  {is_lastround ? (
                    <span>lost in the final round</span>
                  ) : (
                    <span>did not qualify for next round</span>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="fc-sc resp-gap-1">
          {rtstatus == "scheduled" && (
            <>
              <p className="text-yellow-300">
                <span>{"Starting in "}</span>
                <span>{to_time_mini(sr.start_time, true)}</span>
              </p>
            </>
          )}
          {rtstatus == "live" && (
            <Tag className={twMerge(tagcn, "bg-yellow-400 text-black")}>
              LIVE
            </Tag>
          )}
          {rtstatus == "ending" && (
            <Tag className={twMerge(tagcn, "text-purple-400")}>Ending...</Tag>
          )}
          {rtstatus == "finished" && (
            <>
              <p
                class={twMerge(
                  "resp-text-2 font-pixel font-bold resp p-2 border-2 rounded-md",
                  u_h_qualified
                    ? "text-green-400 border-green-400"
                    : "text-red-400 border-red-400",
                )}
              >
                {u_h_qualified ? "YOU WIN" : "YOU LOSE"}
              </p>
            </>
          )}
        </div>
      </div>

      {rtstatus == "finished" && (
        <>
          {is_racing_vault &&
          is_lastround &&
          u_h_qualified &&
          r.quest_type == "progressive" ? (
            <>
              <ProgressiveUser_CashoutOption />
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};
